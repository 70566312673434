import { message } from "antd";
import { addMonitorGroup, delteMonitorGroup, updateEditGroup, delteMonitor, deleteArticle, updateMonitorUser, createMonitor, updateMonitor } from "../service"
import { lineParams, editLineParams, deleteArticleParams, MonitorUserParams, addMonitorParams } from "../ts/type"
import { addCollection, cancelCollection } from "@/pages/Material/service"

// ## 监测方案及监测路径操作API
/**
 * 新增监测路径
 * @param params 
 */
export const handleAddGroup = async (params: lineParams) => {
    const hide = message.loading('正在保存');
    try {
        let response = await addMonitorGroup(params)
        hide();
        if (response.data.code === 200) {
            message.success('保存成功');
            return true;

        } else {
            message.error(response.data.msg);
            return false;
        }
    } catch (error) {
        hide();
        message.error('保存失败请重试！');
        return false;
    }
}

/**
 * 删除监测路径
 * @param id 
 */
export const handleDeleteGroup = async (id: string | number) => {
    const hide = message.loading('正在删除');
    try {
        let response = await delteMonitorGroup(id)
        hide();
        if (response.data.code === 200) {
            message.success('删除成功');
            return true;

        } else {
            message.error(response.data.msg);
            return false;
        }
    } catch (error) {
        hide();
        message.error('删除失败请重试！');
        return false;
    }
}

/**
 * 保存编辑节点
 * @param 
 */
export const handleEditSaveGroup = async (params: editLineParams) => {
    const hide = message.loading('正在编辑');
    try {
        let response = await updateEditGroup(params)
        hide();
        if (response.data.code === 200) {
            message.success('编辑成功');
            return true;

        } else {
            message.error(response.data.msg);
            return false;
        }
    } catch (error) {
        hide();
        message.error('编辑失败请重试！');
        return false;
    }
}

/**
 * 删除监测方案
 * @param id 
 */
export const handleDeleteMonitor = async (id: string | number) => {
    const hide = message.loading('正在删除');
    try {
        let response = await delteMonitor(id)
        hide();
        if (response.data.code === 200) {
            message.success('删除成功');
            return true;

        } else {
            message.error(response.data.msg);
            return false;
        }
    } catch (error) {
        hide();
        message.error('删除失败请重试！');
        return false;
    }
}


/**
 * 创建监测方案
 * @param params 
 */
 export const handleAddMonitor = async (params: addMonitorParams) => {
    const hide = message.loading('正在保存');
    try {
        let response = await createMonitor(params)
        hide();
        if (response.data.code === 200) {
            message.success('保存成功');
            return true;

        } else {
            message.error(response.data.msg);
            return false;
        }
    } catch (error) {
        hide();
        message.error('保存失败请重试！');
        return false;
    }
}

/**
 * 更新监测方案
 * @param params 
 */
 export const handleEditMonitor = async (params: addMonitorParams) => {
    const hide = message.loading('正在保存');
    try {
        let response = await updateMonitor(params)
        hide();
        if (response.data.code === 200) {
            message.success('保存成功');
            return true;

        } else {
            message.error(response.data.msg);
            return false;
        }
    } catch (error) {
        hide();
        message.error('保存失败请重试！');
        return false;
    }
}

// ## 文章操作API
/**
 * 删除文章
 * @param param 
 * @returns 
 */
export const handleDeleteArticle = async (param: deleteArticleParams) => {
    const hide = message.loading('正在删除');
    try {
        let response = await deleteArticle(param)
        hide();
        if (response.data.code === 200) {
            message.success('删除成功');
            return true;

        } else {
            message.error(response.data.msg);
            return false;
        }
    } catch (error) {
        hide();
        message.error('删除失败请重试！');
        return false;
    }
}

/**
 * 更新监测方案目可共享用户
 * @param params 
 * @returns 
 */
export const handleUpdateMonitorUser = async (params: MonitorUserParams) => {
    const hide = message.loading('正在保存');
    try {
        let response = await updateMonitorUser(params)
        hide();
        if (response.data.code === 200) {
            message.success('保存成功');
            return true;
        } else {
            message.error(response.data.msg);
            return false;
        }
    } catch (error) {
        hide();
        message.error('保存失败请重试！');
        return false;
    }
}

/**
 * 添加收藏
 * @param params 
 * @returns 
 */
export const handleAddCollection = async (params: { collectId: string, collectType: string }) => {
    const hide = message.loading('正在收藏');
    try {
        let response = await addCollection(params)
        hide();
        if (response.data.code === 200) {
            message.success('收藏成功');
            return true;
        } else {
            message.error(response.data.msg);
            return false;
        }
    } catch (error) {
        hide();
        message.error('收藏失败请重试！');
        return false;
    }
}

/**
 * 取消收藏
 * @param params 
 * @returns 
 */
export const handleCancelCollection = async (params: { collectId: string, collectType: string }) => {
    const hide = message.loading('正在取消收藏');
    try {
        let response = await cancelCollection(params)
        hide();
        if (response.data.code === 200) {
            message.success('取消收藏成功');
            return true;
        } else {
            message.error(response.data.msg);
            return false;
        }
    } catch (error) {
        hide();
        message.error('取消失败请重试！');
        return false;
    }
}