import { useEffect, useState } from "react";
import { addCollection, selectIndividualizationMonitorList } from "@/pages/Workbench/service";
import style from "./index.module.less";
import { message, Space, Row, Col, Dropdown, Tag, Tooltip } from "antd";
import { handleCancelCollection } from "@/pages/Monitor/api";
import MyTable from "@/components/MyTable";
import Share from "@/components/Share";
import type { ColumnsType } from "antd/lib/table";
import png2 from "../../PNG/2.png";
import png3 from "../../PNG/3.png";
import png4 from "../../PNG/4.png";
import { GlobalOutlined, MoreOutlined, QuestionCircleFilled, SettingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import Nodata from "@/components/NodataSmall";
// 权限
import AccessWrap from "@/access/AccessWrap";

export default function App() {
	const [customData, setCustomData] = useState<PRT.CustomColumns>();
	const [IrHotData, setIrHotData] = useState<PRT.IrHotColumns>();
	const [GrHotData, setGrHotData] = useState<PRT.GrHotColumns>();
	const [loading, setLoading] = useState<boolean>(false);
	const [shareUrl, setShareUrl] = useState<any>();
	const [shareVisible, setShareVisible] = useState<boolean>(false);
	const [collectionValue, setCollectionValue] = useState<boolean>(false);
	const navigate = useNavigate();

	// 获取table数据
	const selectIndividualizationMonitorListHandler = async () => {
		const res: any = await selectIndividualizationMonitorList();
		const { code, msg, data } = res.data;
		if (code === 200) {
			setCustomData(data?.attentionMedia);
			setIrHotData(data?.supervisionMedia);
			setGrHotData(data?.governmentMedia);
		} else if (code === 500) {
			message.error(msg);
			setLoading(false);
		}
	};
	// 分享
	const operation = (record) => {
		setShareUrl(record?.articleUrl);
		setShareVisible(!shareVisible);
	};
	// 收藏
	const collection = async (record) => {
		const res = await addCollection({
			collectId: record?.articleId,
			collectType: "0",
		});
		const { code, msg } = res.data;
		if (code === 200) {
			message.success(msg);
			record.isCollect = "1";
			setCustomData({ ...customData });
			setIrHotData({ ...IrHotData });
			setGrHotData({ ...GrHotData });
		} else {
			message.error(msg);
		}
	};
	// 取消收藏
	const Nocollection = (record) => {
		const res = handleCancelCollection({
			collectId: record.articleId,
			collectType: "0",
		});
		if (res) {
			record.isCollect = "0";
			setCustomData({ ...customData });
			setIrHotData({ ...IrHotData });
			setGrHotData({ ...GrHotData });
		}
	};
	// 下载
	const download = (record) => {
		console.log(record, "download");
	};
	// 一件追溯
	const handleOnekeyToCreateMonitor = (record) => {
		const { title: desc } = record;
		navigate("/monitor/create", { state: { desc } });
	};

	// icon图标
	const iconbox = (record) => {
		return (
			<Space size="small">
				<div className={style.icon}>
					{/* <AccessWrap type="button" action=""> */}
					<div>
						<GlobalOutlined style={{ marginRight: 6 }} />
						<span onClick={() => handleOnekeyToCreateMonitor(record)}>一键追溯</span>
					</div>
					{/* </AccessWrap> */}
					{record?.isCollect === "0" ? (
						<div
							onClick={() => {
								collection(record);
							}}
						>
							<span className="iconfont icon-weishoucang" style={{ marginRight: 6 }}></span>
							<span>收藏</span>
						</div>
					) : (
						<div
							onClick={() => {
								Nocollection(record);
							}}
						>
							<span
								className="iconfont icon-yishoucang"
								style={{ color: "orange", marginRight: 6 }}
							></span>
							<span>收藏</span>
						</div>
					)}
					{/* <div
						onClick={() => {
							download(record);
						}}
					>
						<span className="iconfont icon-xiazai" style={{ marginRight: 6 }}></span>
						<span>下载</span>
					</div> */}
					<div
						onClick={() => {
							operation(record);
						}}
					>
						<span className="iconfont icon-fenxiang" style={{ marginRight: 6 }}></span>
						分享
					</div>
				</div>
			</Space>
		);
	};

	/**
	 * 格式化高亮string
	 * @param sourceStr
	 * @returns
	 */
	const formatStringResult = (sourceStr) => `<span style="background:#FFCF86">${sourceStr}</span>`;
	/**
	 *
	 * @param keyWords
	 * @returns
	 */
	const generateRegExp = (keyWords = []) => new RegExp(keyWords?.join("|"), "g");

	/**
	 * 处理高亮词
	 * @param sourceStr
	 * @param rules
	 */
	const handleHighlightKeyWords = (sourceStr, keyWords: string[], rule?: any) =>
		sourceStr.replace(rule ? rule : generateRegExp(keyWords), (s) => s.replace(s, formatStringResult(s)));

	// 个性化监测
	const monitorColumns: ColumnsType<PRT.CustomColumns> = [
		{
			title: "标题",
			dataIndex: "title",
			ellipsis: true,
			key: "title",
			width: "40%",
			render: (_, record) => (
				<span
					onClick={() => {
						window.open(record?.articleUrl);
					}}
					style={{ cursor: "pointer" }}
					dangerouslySetInnerHTML={{
						__html: handleHighlightKeyWords(record.title, customData.keyWords),
					}}
				></span>
			),
		},
		// {
		// 	title: "衍生关键词",
		// 	dataIndex: "derivedKeywords",
		// 	key: "derivedKeywords",
		// 	ellipsis: true,
		// 	align: "center",
		// 	width: "15%",
		// 	render: (_) => {
		// 		let arr = [];
		// 		_.map((item) => {
		// 			arr.push(item + "、");
		// 			return arr;
		// 		});
		// 		return (
		// 			<Tooltip placement="left" title={arr} mouseEnterDelay={0.5}>
		// 				{arr}
		// 			</Tooltip>
		// 		);
		// 	},
		// },
		{
			title: "发布媒体",
			dataIndex: "mediaName",
			key: "mediaName",
			ellipsis: true,
			align: "center",
			width: "14%",
			render: (_, record) => {
				if (record?.isAttentionMedia === "1") {
					return <span style={{ color: "#D95835" }}>{_}</span>;
				}
				if (record?.isAttentionMedia === "0") {
					return <span>{_}</span>;
				}
			},
		},
		{
			title: "态度",
			dataIndex: "spread",
			ellipsis: true,
			key: "spread",
			align: "center",
			width: "66px",
			render: (_, record) => {
				return (
					<>
						{record?.fullTextSentiment === "0" && (
							<span className={style.sentimentZx}>
								<Tag>中性</Tag>
							</span>
						)}
						{record?.fullTextSentiment === "1" && (
							<span className={style.sentimentZc}>
								<Tag>赞成</Tag>
							</span>
						)}
						{record?.fullTextSentiment === "-1" && (
							<span className={style.sentimentP}>
								<Tag>批评</Tag>
							</span>
						)}
					</>
				);
			},
		},
		{
			title: "发布时间",
			dataIndex: "pubDateTime",
			ellipsis: true,
			key: "pubDateTime",
			align: "center",
			width: "14%",
		},
		{
			width: "40px",
			title: <SettingOutlined />,
			key: "action",
			align: "center",
			render: (_, record) => (
				<Dropdown.Button
					className={style.dropDown}
					overlay={iconbox(record)}
					getPopupContainer={(triggerNode) => triggerNode.parentElement}
					icon={<MoreOutlined style={{ width: 14, height: 14 }} />}
				></Dropdown.Button>
			),
		},
	];
	// 行业与行政监管
	const IrHotColumns: ColumnsType<PRT.IrHotColumns> = [
		{
			title: "标题",
			dataIndex: "title",
			ellipsis: true,
			key: "title",
			width: "40%",
			render: (_, record) => (
				<span
					onClick={() => {
						window.open(record?.articleUrl);
					}}
					style={{ cursor: "pointer" }}
					dangerouslySetInnerHTML={{
						__html: handleHighlightKeyWords(record.title, IrHotData.keyWords),
					}}
				></span>
			),
		},
		// {
		// 	title: "衍生关键词",
		// 	dataIndex: "derivedKeywords",
		// 	key: "derivedKeywords",
		// 	ellipsis: true,
		// 	align: "center",
		// 	width: "15%",

		// 	render: (_) => {
		// 		let arr = [];
		// 		_.map((item) => {
		// 			arr.push(item + "、");
		// 			return arr;
		// 		});
		// 		return (
		// 			<Tooltip placement="left" title={arr} mouseEnterDelay={0.5}>
		// 				{arr}
		// 			</Tooltip>
		// 		);
		// 	},
		// },
		{
			title: "发布媒体",
			dataIndex: "mediaName",
			key: "mediaName",
			width: "15%",
			ellipsis: true,
			render: (_, record) => {
				if (record?.isAttentionMedia === "1") {
					return <span style={{ color: "#D95835" }}>{_}</span>;
				}
				if (record?.isAttentionMedia === "0") {
					return <span>{_}</span>;
				}
			},
		},
		{
			title: "态度",
			dataIndex: "fullTextSentiment",
			key: "fullTextSentiment",
			align: "center",
			width: "66px",
			ellipsis: true,
			render: (_, record) => {
				return (
					<>
						{record?.fullTextSentiment === "0" && (
							<span className={style.sentimentZx}>
								<Tag>中性</Tag>
							</span>
						)}
						{record?.fullTextSentiment === "1" && (
							<span className={style.sentimentZc}>
								<Tag>赞成</Tag>
							</span>
						)}
						{record?.fullTextSentiment === "-1" && (
							<span className={style.sentimentP}>
								<Tag>批评</Tag>
							</span>
						)}
					</>
				);
			},
		},
		{
			title: "发布时间",
			dataIndex: "pubDateTime",
			width: "14%",
			ellipsis: true,

			align: "center",
			key: "pubDateTime",
		},
		{
			width: "50px",
			title: <SettingOutlined />,
			key: "action",
			align: "center",
			render: (_, record) => (
				<Dropdown.Button
					className={style.dropDown}
					overlay={iconbox(record)}
					icon={<MoreOutlined />}
					getPopupContainer={(triggerNode) => triggerNode.parentElement}
				></Dropdown.Button>
			),
		},
	];
	// 政府与政策
	const GrHotColumns: ColumnsType<PRT.GrHotColumns> = [
		{
			title: "标题",
			dataIndex: "title",
			ellipsis: true,
			width: "40%",
			key: "title",
			render: (_, record) => (
				<span
					onClick={() => {
						window.open(record?.articleUrl);
					}}
					style={{ cursor: "pointer" }}
					dangerouslySetInnerHTML={{
						__html: handleHighlightKeyWords(record.title, GrHotData.keyWords),
					}}
				></span>
			),
		},
		// {
		// 	title: "衍生关键词",
		// 	dataIndex: "derivedKeywords",
		// 	key: "derivedKeywords",
		// 	ellipsis: true,
		// 	width: "15%",

		// 	align: "center",
		// 	render: (_) => {
		// 		let arr = [];
		// 		_.map((item) => {
		// 			arr.push(item + "、");
		// 			return arr;
		// 		});
		// 		return (
		// 			<Tooltip placement="left" title={arr} mouseEnterDelay={0.5}>
		// 				{arr}
		// 			</Tooltip>
		// 		);
		// 	},
		// },
		{
			title: "发布媒体",
			dataIndex: "mediaName",
			ellipsis: true,
			width: "15%",
			key: "mediaName",
			align: "center",
			render: (_, record) => {
				if (record?.isAttentionMedia === "1") {
					return <span style={{ color: "#D95835" }}>{_}</span>;
				}
				if (record?.isAttentionMedia === "0") {
					return <span>{_}</span>;
				}
			},
		},
		{
			title: "态度",
			dataIndex: "spread",
			ellipsis: true,
			width: "66px",
			key: "spread",
			align: "center",
			render: (_, record) => {
				return (
					<>
						{record?.fullTextSentiment === "0" && (
							<span className={style.sentimentZx}>
								<Tag>中性</Tag>
							</span>
						)}
						{record?.fullTextSentiment === "1" && (
							<span className={style.sentimentZc}>
								<Tag>赞成</Tag>
							</span>
						)}
						{record?.fullTextSentiment === "-1" && (
							<span className={style.sentimentP}>
								<Tag>批评</Tag>
							</span>
						)}
					</>
				);
			},
		},
		{
			title: "发布时间",
			dataIndex: "pubDateTime",
			ellipsis: true,
			width: "14%",
			key: "pubDateTime",
			align: "center",
		},
		{
			width: "50px",
			title: <SettingOutlined />,
			key: "action",
			align: "center",
			render: (_, record) => (
				<Dropdown.Button
					className={style.dropDown}
					overlay={iconbox(record)}
					icon={<MoreOutlined />}
					getPopupContainer={(triggerNode) => triggerNode.parentElement}
				></Dropdown.Button>
			),
		},
	];
	const jumpCreateMonitor = () => {
		navigate("/monitor/create");
	};
	useEffect(() => {
		selectIndividualizationMonitorListHandler();
	}, []);
	return (
		<div
			style={{
				padding: 20,
				margin: "10px 10px 0 10px",
				background: "#fff",
				borderRadius: "10px",
				boxShadow: "0 0 10px 2px #ccd3e0",
			}}
		>
			<Share visible={shareVisible} setVisible={setShareVisible} url={shareUrl || ""} setUrl={setShareUrl} />
			<div className={style.titleBox}>
				<img src={png2} style={{ width: 20, height: 20, marginRight: 10 }} />
				<Tooltip placement="top" title="自身、竞对及行业监测实时获取全网高价值资讯情报" mouseEnterDelay={0.5}>
					<div className={style.title}>个性化监测</div>
				</Tooltip>
			</div>
			{IrHotData === undefined ? (
				<Nodata
					description={
						<span>
							您尚未创建监测方案，您可以通过
							<span
								onClick={jumpCreateMonitor}
								style={{ color: "#DB5633", fontWeight: 700, cursor: "pointer" }}
							>
								{" "}
								创建监测{" "}
							</span>
							创建监测对象
						</span>
					}
				/>
			) : (
				<Row gutter={[10, 10]}>
					<Col span={12} style={{height:510}}>
						<MyTable
							title={
								<Tooltip placement="top" title="本列表展示关注媒体与KOL相关最新热点数据">
									关注媒体与KOL
								</Tooltip>
							}
							columns={monitorColumns}
							dataSource={customData?.articleList.slice(0, 10)}
							keyWords={customData?.keyWords}
						/>
					</Col>
					<Col
						span={12}
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
						}}
					>
						<div style={{ marginLeft: 10 ,height:245 }}>
							<MyTable
								title={
									<Tooltip placement="top" title="本列表展示行业与行政监管相关最新热点数据">
										行业与行政监管
									</Tooltip>
								}
								columns={IrHotColumns}
								dataSource={IrHotData?.articleList.slice(0, 3)}
								keyWords={IrHotData?.keyWords}
							/>
						</div>
						<div style={{ height: 20 }}></div>
						<div style={{ marginLeft: 10 ,height:245}}>
							<MyTable
								title={
									<Tooltip placement="top" title="本列表展示政府与政策相关最新热点数据">
										政府与政策
									</Tooltip>
								}
								columns={GrHotColumns}
								dataSource={GrHotData?.articleList.slice(0, 3)}
								keyWords={GrHotData?.keyWords}
							/>
						</div>
					</Col>
				</Row>
			)}
		</div>
	);
}
