import SpecialActivities from "./components/SpecialActivities";
import style from "./pr.module.less";
import HotMonitor from "./components/HotMonitor";
import TimeLine from "./components/TimeLine";
import EarlyWarning from "./components/EarlyWarning";
import { GlobalContext } from "@/GlobalContext";
import { useContext, useEffect, useState, createContext } from "react";
import { message } from "antd";
import Store from "./redux";
import WorkArticleList from "./components/WorkArticleList";
import { UPDATE_ARTICLE_PARAMS } from "./redux/instance";
import { selectEventMonitor } from "@/pages/Workbench/service";
// import WorkArticleList from "@/pages/Event/pages/EventAnalysis/components/ArticleList";
// 体验用户
export const WorkContext = createContext(null);

const PR: React.FC = () => {
	const {
		state: { user },
	} = useContext(GlobalContext);
	const isDisabled = user?.info?.permissionName === "EXPERIENCE" ? true : false;
	const [isShow, setIsShow] = useState(false);
	const [filterText, setFilterText] = useState();
	const [EventMonitorList, setEventMonitorList] = useState([]);
	const [state, dispatch] = Store();
	const [current, setCurrent] = useState<number>(0);
	// 获取事件列表
	const handleGetEventMonitorList = async () => {
		try {
			const response = await selectEventMonitor();
			const { msg, code, data } = response.data;
			if (code === 200) {
				setEventMonitorList(data);
				dispatch({
					type: UPDATE_ARTICLE_PARAMS,
					payload: { ...state.params, monitorInfoId: data[0].monitorInfoId },
				});
			} else message.error(msg);
		} catch (error) {}
	};

	useEffect(() => {
		handleGetEventMonitorList();
	}, []);
	return (
		<WorkContext.Provider value={{ state, dispatch }}>
			<section className={style.Work}>
				<div className={style.WorkContent}>
					<div className={style.charts}>
						{/* 个性化监测 GR热点 IR热点 */}
						<HotMonitor />
						{/* 活动专项监测 */}
						<SpecialActivities
							isShow={isShow}
							setIsShow={setIsShow}
							setFilterText={setFilterText}
							current={current}
							setEventMonitorList={setEventMonitorList}
						/>

						{/* 事件脉络分析 */}
						{!isDisabled && (
							<TimeLine
								isShow={isShow}
								setIsShow={setIsShow}
								setFilterText={setFilterText}
								current={current}
								setEventMonitorList={setEventMonitorList}
							/>
						)}
						{/* 个性化预警 */}
						{/*2023-12-20 隐藏个性化预警部分*/}
						{/*{!isDisabled && <EarlyWarning */}
						{/*	isShow={isShow}*/}
						{/*	setIsShow={setIsShow}*/}
						{/*	setFilterText={setFilterText}*/}
						{/*	current={current}*/}
						{/*	setEventMonitorList={setEventMonitorList}*/}
						{/*/>}*/}
					</div>

					{/* article */}
					{isShow && (
						<div className={style.list}>
							<WorkArticleList
								isShow={isShow}
								setIsShow={setIsShow}
								filterText={filterText}
								eventName={
									EventMonitorList.filter(
										(t) => t?.monitorInfoId === state?.params.monitorInfoId
									)?.[0]?.monitorName
								}
							/>
						</div>
					)}
				</div>
			</section>
		</WorkContext.Provider>
	);
};

export default PR;
