import * as echarts from "echarts/core";
import { GridComponent, GridComponentOption } from "echarts/components";
import { LineChart, LineSeriesOption } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { useEffect, useRef } from "react";

echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);

type EChartsOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption>;

type LineProps = {
	resize?: any;
	xaxis: any[];
	yaxis: any[];
	data: any[];
	legend?: any[];
	smooth?: boolean;
	myChart?: any
	onClick?: Function;
	colors?: any[]
};

const Line = (props: LineProps) => {

	const chartRef = useRef();
	let { xaxis = [], yaxis = [], data = [], resize, onClick, legend = [], smooth = false, myChart, colors = ["#b4b6b5", "#8cdca2", "#f08755"] } = props;
	useEffect(() => {
		if (myChart !== null && myChart !== "" && myChart !== undefined) {
			myChart.dispose();
		}

		const option: EChartsOption = {
			legend: {
				show: true,
				data: legend,
				bottom: 0,
			},
			tooltip: {
				trigger: "axis",
				textStyle: {
					fontSize: 12
				}
			},
			xAxis: {
				type: "category",
				boundaryGap: false,
				data: xaxis,
			},
			yAxis: {
				type: "value",
				data: yaxis,
				axisLabel: {
					formatter: function (value, index) {
						var value;
						if (value >= 1000 && value <= 9999) {
							value = value / 1000 + "k";
						} else if (value < 1000) {
							value = value;
						} else if (value >= 10000) {
							value = value / 10000 + "w";
						}
						return value;
					},
				},
			},
			grid: {
				top: 10,
				right: "12%",
				left: "12%",
			},
			series: data?.map((item, index) => {
				return {
					...item,
					type: "line",
					smooth,
					color: item?.color ? item?.color : colors[index]
				};
			}),
		};

		myChart = echarts.init(chartRef.current);
		const resizeChart = () => {
			myChart.resize();
		};
		
		myChart.off("click");
		myChart.on("click", (params) => onClick(params));
		myChart.setOption(option);
		window.addEventListener("resize", resizeChart);
		if (resize || !resize) resizeChart();
		return () => window.removeEventListener("resize", resizeChart);
	}, [props, resize]);

	return <div ref={chartRef} style={{ height: "100%" }}></div>;
};

export default Line;
