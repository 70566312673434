import { useState, useEffect, useContext } from "react";
import { message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import AnalysisArticleItem from "@/pages/Event/components/ArticleItem";

import InfiniteScroll from "react-awesome-infinite-scroll";
import { getArticleList } from "../../../service";
import Loading from "@/components/Loading";
import { UPDATE_ARTICLE_LIST, UPDATE_ARTICLE_PARAMS } from "../../redux/instance";
import { WorkContext } from "../..";
import style from "./index.module.less";
import Nodata from "@/components/Nodata";
const ArticleList = (props) => {
	const {
		state: { articleList, params },
		dispatch,
	} = useContext(WorkContext);
	const [hasMore, setHasMore] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [fitst, setFirst] = useState<boolean>(true);
	/**
	 * 后续获取文章列表
	 * @param params
	 */
	const fetchList = async (params) => {
		if (fitst) return;
		console.log("执行更新list >>> 加载后续文章列表");
		try {
			const response = await getArticleList(params);
			const { code, msg, data } = response.data;
			if (code === 200) {
				if (data.list?.length) {
					const next = articleList.concat(data.list);
					dispatch({ type: UPDATE_ARTICLE_LIST, payload: next });
					dispatch({ type: UPDATE_ARTICLE_PARAMS, payload: { ...params, pageNum: data.pageNum } });
					// setList(next)
					// setParams({ ...params, pageNum: data.pageNUm })
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setLoading(false);
			} else {
				setLoading(false);
				message.warning(msg);
			}
		} catch (error) {
			setLoading(false);
			message.warning(error);
		}
	};
	// 刷新获取
	const reload = async () => {
		console.log("执行更新list >>> reload");
		setLoading(true);
		try {
			const response = await getArticleList(params);
			const { code, msg, data } = response.data;
			if (code === 200) {
				dispatch({ type: UPDATE_ARTICLE_LIST, payload: data.list });
				setLoading(false);
			} else {
				setLoading(false);
			}
		} catch (error) {}
	};

	useEffect(() => {
		const {
			attitudeFeelType,
			attitudeAnalysisMediaType,
			mediaName,
			mediaId,
			mediaClassId,
			keyWord,
			analysisTime,
			volumeTime,
			monitorInfoId,
			selectType,
			multimodalLatitude
		} = params;
		if (monitorInfoId||selectType) {
			if (
				attitudeFeelType ||
				attitudeAnalysisMediaType ||
				mediaName ||
				mediaId ||
				mediaClassId ||
				keyWord ||
				analysisTime ||
				volumeTime||
				selectType ||
				multimodalLatitude
			) {
				dispatch({ type: UPDATE_ARTICLE_LIST, payload: [] });
				console.log("执行更新list >>> useEffect");
				setLoading(true);
				setFirst(true);
				getArticleList({ ...params }).then((resp) => {
					const { data, code, msg } = resp.data;
					if (code === 200) {
						setLoading(false);
						dispatch({ type: UPDATE_ARTICLE_LIST, payload: [...data?.list] });
						setHasMore(data.list?.length < data.total);
						setFirst(false);
					} else message.error(msg);
				});
			}
		}
	}, [
		params.analysisTime,
		params.monitorInfoId,
		params.attitudeFeelType,
		params.attitudeAnalysisMediaType,
		params.mediaName,
		params.mediaId,
		params.mediaClassId,
		params.keyWord,
		params.volumeTime,
		params.selectType,
		params.multimodalLatitude
	]);
	return (
		<div className={style.content_r}>
			<div className={style.content_r_header}>
				<span className={style.title}>{props.eventName}</span>
				<span>
					<span className={style.filter} style={{ marginRight: 10, color: "red" }}>
						{props.filterText}
					</span>
					<CloseOutlined
						onClick={() => {
							props.setIsShow(false);
							dispatch({ type: UPDATE_ARTICLE_PARAMS, payload: [] });
						}}
					/>
				</span>
			</div>
			<div className={style.content_r_list}>
				{
					<InfiniteScroll
						length={articleList?.length}
						next={() => fetchList({ ...params, pageNum: params.pageNum + 1 })}
						scrollableParent={document.querySelector(".content_r_list")}
						hasMore={hasMore}
						isRefreshing={false}
						pullDownToRefresh={false}
						refreshFunction={reload}
						pullDownComponent={<div style={{ height: "50px" }}>下拉</div>}
						releaseComponent={<div style={{ height: "50px" }}>释放</div>}
						refreshingComponent={<div style={{ height: "50px" }}>加载中</div>}
						refreshEndComponent={<div style={{ height: "50px" }}>加载完成</div>}
						loadingComponent={<Loading />}
						endComponent={
							articleList?.length ? (
								<div style={{ textAlign: "center", fontWeight: "normal", color: "#999" }}>
									<span>没有更多内容了</span>
								</div>
							) : null
						}
					>
						{loading ? (
							<Loading />
						) : articleList.length ? (
							articleList?.map((item, index) => (
								<AnalysisArticleItem
									{...item}
									isSetType={false}
									isDel={false}
									key={index}
									path={`/article/${item.doc_id}`}
								/>
							))
						) : (
							<Nodata />
						)}
					</InfiniteScroll>
				}
			</div>
		</div>
	);
};

export default ArticleList;
