import React, { useEffect, useRef, useState } from "react";
import { Button } from "antd";
import ReactDOM from "react-dom";

export default function ToPrint({
	id = "iframeId",
	printText = "打印",
	Template = <span>未传入打印数据</span>,
	width = 600,
	height = 600,
	printNodeHeight = 800,
}) {
	const ref: any = useRef();
	const [printNode, setPrintNode] = useState<any>();
	const styles = `position:absolute;width:${width}px;height:${height}px;left:-10000px;top:-10000px;`;
	const initPrintNode = () => {
		const iframe: any = document.createElement("IFRAME");
		iframe.setAttribute("id", id);
		iframe.setAttribute("style", styles);
		iframe.src=navigator.userAgent.match("Firefox") ? 'javascript:' : ''
		ref.current.appendChild(iframe);
		let doc = iframe.contentWindow.document;
		ReactDOM.render(Template, doc);
		console.log(doc);
		setPrintNode(iframe);
		doc.close();
	};

	const handlePrint = () => {
		printNode.contentWindow.focus();
		printNode.contentWindow.print();
		if (navigator.userAgent.indexOf("MSIE") > 0) {
			document.body.removeChild(printNode);
			setPrintNode(null);
		}
	};

	useEffect(() => {
		if (ref.current) initPrintNode();
	}, []);

	return (
		<div>
			<Button type="primary" onClick={handlePrint} size="small" style={{ marginRight: 6 }}>
				{printText}
			</Button>
			<div ref={ref} style={{ position: "relative" }}></div>
		</div>
	);
}
