import { message } from 'antd';
import moment from 'moment';
import request from "@/service/request"
// 获取今天
export const getTodayDate = () => {
    return [moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')]
}

// 获取过去一年
export const getYearAgo = (count: number) => {
    return [
        moment(getDateNow() - (count * 365 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD HH:mm:ss'),
        moment(getDateNow()).format('YYYY-MM-DD HH:mm:ss')
    ]
}

// 自定义时间按钮用
// 获取当前时间过去一周
export const getWeekAgoCustom = (startTime: number) => {
    return [
        moment(startTime - (7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD HH:mm:ss'),
        moment(startTime).format('YYYY-MM-DD HH:mm:ss')]
}
// 获取当前时间过去xx年
export const getYearAgoCustom = (num, startTime: number) => {
    return [
        moment(startTime - (num * 365 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD HH:mm:ss'),
        moment(startTime).format('YYYY-MM-DD HH:mm:ss')]
}
// 获取当前时间过去xx个月
export const getMonthAgoCustom = (num: number, startTime: number) => {
    return [
        moment(startTime - (num * 30 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD HH:mm:ss'),
        moment(startTime).format('YYYY-MM-DD HH:mm:ss')]
}
// 获取当前时间
export const getDateNow = () => Date.now()
// 获取当前时间一年后
export const getOneYearLater = (startTime: number) => {
    return startTime + (365 * 24 * 60 * 60 * 1000)
}
// 获取当前时间过去一周
export const getWeekAgo = (startTime: number) => {
    return startTime - (7 * 24 * 60 * 60 * 1000)
}
// 获取当前时间过去xx个月
export const getMonthAgo = (num: number, startTime: number) => {
    return startTime - (num * 30 * 24 * 60 * 60 * 1000)
}
// 获取指定时间毫秒
export const getDateValueOf = (time) => {
    return moment(time).valueOf()
}

/**
 * 防抖函数
 * @param delay 
 * @param callback 
 * @returns 
 */
export const debounce = (
    delay: number = 3000,
    callback: Function = (props) => { }
) => {
    let timer = null;
    return function (props) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            return callback(props)
        }, delay)
    }
}


export function downLoadXlsx(data:any, fileName: string) {
        const aLink = document.createElement('a');
        const blob:any = new Blob([data]);
        aLink.style.display = 'none';
        aLink.href = URL.createObjectURL(blob);
        aLink.setAttribute('download', fileName); // 设置下载文件名称
        document.body.appendChild(aLink);
        aLink.click();
        URL.revokeObjectURL(aLink.href); // 清除引用
        document.body.removeChild(aLink);
}

