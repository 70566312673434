import * as echarts from "echarts/core";
import { GridComponent, GridComponentOption } from "echarts/components";
import { LineChart, LineSeriesOption } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { useEffect, useRef } from "react";

echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);

type EChartsOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption>;

type LineChaProps = {
	data?: {
		xaxis?: string[];
		yaxis?: string[];
		reportFinanceMediaData: any;
	};
};

const MultipleLineChart = (props: LineChaProps) => {
	const chartsBox = useRef();
	let myChart;

	useEffect(() => {
		if (myChart !== null && myChart !== "" && myChart !== undefined) {
			myChart.dispose();
		}
		const resizeChart = () => {
			myChart.resize();
		};
		const option: EChartsOption = {
			// 图表图例
			legend: {
				// data: data?.map((item) => {
				//   return item?.title;
				// }),
				x: "center",
				y: "bottom",
				
				bottom: 0,
				textStyle: {
					fontSize: "14",
				},
			},
			tooltip:{
				trigger: "axis",
			},
			xAxis: {
				type: "category",
				data: props?.data?.xaxis || [],
			},
			yAxis: {
				type: "value",
				data: props?.data?.yaxis || [],
				axisLabel: {
					formatter: function (value, index) {
						var value;
						if (value >= 1000 && value <= 9999) {
							value = value / 1000 + "k";
						} else if (value < 1000) {
							value = value;
						} else if (value >= 10000) {
							value = value / 10000 + "w";
						}
						return value;
					},
				},
			},
			grid: {
				left: "12%",
				top: "8%",
				bottom: "35%",
				right: "5%",
			},
			series:
				props?.data?.reportFinanceMediaData?.map((item) => ({
					...item,
					type: "line",
					// stack: 'Total',
				})) || [],
		};
		myChart = echarts.init(chartsBox.current);
		myChart.setOption(option);
		window.addEventListener("resize", resizeChart);
		return () => window.removeEventListener("resize", resizeChart);
	}, [props.data]);

	return <div ref={chartsBox} style={{ height: "100%" }}></div>;
};

export default MultipleLineChart;
