import React, { useEffect, useState } from "react";
import style from "./index.module.less";
import { Form, Select } from "antd";
export default function App(props) {
	const { options, defaultValue, data, setData, newData, mode } = props;
	const [value, setValue] = useState(defaultValue);
	useEffect(() => {
		setValue(defaultValue);
	}, []);
	return (
		<div>
			<Select
				dropdownClassName={style.content_select}
				className={style.select}
				mode={mode}
				options={options}
				getPopupContainer={(triggerNode) => triggerNode.parentElement}
				onChange={(value) => {
					setData(value);
					setValue(value);
				}}
				value={value}
				style={{ width: 120 }}
			/>
		</div>
	);
}
