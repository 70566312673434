import * as echarts from 'echarts/core';
import { GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useEffect, useRef, useState } from 'react';
import 'echarts-wordcloud'

echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);
type Word = {
    name: string
    value: number
}

type WordcloudgramProps = {
    resize?: any
    data: any[]
    onClick?: Function
}

const colors = [
    '#257ed8',
    '#257ed8',
    '#257ed8',
    '#aecaf8',
    '#aecaf8',
    '#aecaf8',
    '#db5633',
    '#db5633',
    '#db5633',
    '#f4a874',
    '#f4a874',
    '#f4a874',
    '#656565',
    '#656565',
    '#656565',
    '#b4b6b5',
    '#b4b6b5',
    '#b4b6b5',
    '#257ed8',
    '#257ed8',
    '#257ed8',
    '#aecaf8',
    '#aecaf8',
    '#aecaf8',
    '#db5633',
    '#db5633',
    '#db5633',
    '#f4a874',
    '#f4a874',
    '#f4a874',
    '#656565',
    '#656565',
    '#656565',
    '#b4b6b5',
    '#b4b6b5',
    '#b4b6b5',
];

const Wordcloudgram = (props: WordcloudgramProps) => {
    const chartRef = useRef();
    const active = useRef({ seriesIndex: null, dataIndex: null });
    const c: any = useRef();

    const { resize, data = [], onClick } = props;

    const _click = (params) => {
        onClick(params)
        // const { dataIndex: newDataIndex, seriesIndex: newSeriesIndex } = params;
        // const { seriesIndex: oldSeriesIndex, dataIndex: oldDataIndex } = active.current;
        // if (oldSeriesIndex) {
        //     c.current.dispatchAction({ type: 'downplay', dataIndex: oldSeriesIndex, seriesIndex: oldDataIndex })
        // }
        // active.current.dataIndex = newDataIndex;
        // active.current.seriesIndex = newSeriesIndex
        // c.current.dispatchAction({ type: 'highlight', dataIndex: newDataIndex, seriesIndex: newSeriesIndex })
    }

    // const _mouseout = (params) => {
    //     c.current.dispatchAction({ type: 'highlight', dataIndex: active.current.dataIndex, seriesIndex: active.current.seriesIndex })
    // }
    const color16 = () => {
        var r = Math.floor(Math.random() * 256);
        var g = Math.floor(Math.random() * 256);
        var b = Math.floor(Math.random() * 256);
        var color = '#' + r.toString(16) + g.toString(16) + b.toString(16);
        return color;
    }
    useEffect(() => {
        if (c.current !== null && c.current !== "" && c.current !== undefined) {
            c.current.dispose();
        }
        const option = {
            grid: {

            },
            series: [
                {
                    type: "wordCloud",
                    //用来调整词之间的距离
                    gridSize: 20,
                    //用来调整字的大小范围
                    sizeRange: [14, 32],
                    rotationRange: [0, 0],
                    // maskImage: maskImage, //字体以图片形状分布，支持为 HTMLImageElement, HTMLCanvasElement，不支持路径字符串
                    shape: "pentagon",
                    // textStyle: {
                    //     color: function () {
                    //         // return (
                    //         //     "rgb(" +
                    //         //     Math.round(Math.random() * 90) +
                    //         //     ", " +
                    //         //     Math.round(Math.random() * 190) +
                    //         //     ", " +
                    //         //     Math.round(Math.random() * 190) +
                    //         //     ")"
                    //         // );
                    //         return color16()
                    //     }
                    // },
                    // emphasis: {
                    //     focus: 'adjacency',
                    //     textStyle: {
                    //         // color:'orange',
                    //         fontWeight:'700',
                    //     },
                    // },
                    //数据
                    data: data.map((item, index) => {
                        return { ...item, textStyle: { color: colors[index] } }
                    })
                }
            ]
        };
        c.current = echarts.init(chartRef.current);
        c.current.off('click');
        c.current.on('click', _click)
        // c.current.off('mouseout');
        // c.current.on('mouseout', _mouseout)

        const resizeChart = () => { c.current.resize(); }
        c.current.setOption(option);
        window.addEventListener('resize', resizeChart)
        if (resize || !resize) resizeChart()
        return () => window.removeEventListener('resize', resizeChart)
    }, [props, resize])

    return <div ref={chartRef} style={{ height: "100%" }}></div>
}

export default Wordcloudgram