import request from "@/service/request"
//获取大纲模版
export function getTemplate(params) {
    return request({
        url: '/base/creationCenter/template',
        method: 'get',
        params
    })
}
// 获取模版列表
export function getTemplateList(params: { themeId: string, pageNum?: number, pageSize?: number, templateName?: string }) {
    return request({
        url: '/base/creationCenter/queryTemplateList',
        method: 'get',
        params
    })
}
// 获取创作中心搜索list
export function getSearchList(params: {
    scope: string
    text: string
    pageNum: number
    pageSize: number
    templateId: any
}) {
    return request({
        url: '/base/creationCenter/search',
        method: 'get',
        params
    })
}
// 获取创作中心精品稿件
export function getArticleByTemplateId(params: {
    text: string
    pageNum: number
    pageSize: number
    templateId: any
}) {
    return request({
        url: '/base/resource/queryArticleByTemplateId',
        method: 'get',
        params
    })
}
// 获取创作中心精品稿件分段详情
export function recommendArticleSplit(params: {
    articleId: any
}) {
    return request({
        url: '/base/resource/articleSplit',
        method: 'get',
        params
    })
}
// 获取创作中心搜索list项的详情
export function getSearchItemDetail(params: {
    scope: string
    searchId: string
}) {
    return request({
        url: '/base/creationCenter/contentSplit',
        method: 'get',
        params
    })
}
// 获取一级主题列表
export function queryFirstLevelList() {
    return request({
        url: '/base/creationCenter/queryFirstLevelList',
        method: 'get',
    })
}
// 创作保存
export function createCreationArticle(
    params: {
        resourceId?: string
        accessCode?: string;
        articleContent: {
            content: string
            title: string
        };
        encryptFlag?: string;
        folderId: number;
        resourceName?: string;
        resourceTag?: string;
        shareUserList?: string;
        templateId?: string
    }
) {
    return request({
        url: '/base/resource/saveCreationArticle',
        method: 'post',
        data: params
    })
}
// 获取创作详情
export function getCreationContent(resourceId: number) {
    return request({
        url: '/base/resource/content',
        method: 'get',
        params: { resourceId }
    })
}
// 查询大纲列表
export function queryOutlineList(params: { pageNum: number, pageSize: number, outlineName?: string }) {
    return request({
        url: '/base/creationCenter/outline/queryList',
        method: 'post',
        data: params
    })
}
// 删除大纲
export function delOutline(params: { outlineId: number }) {
    return request({
        url: '/base/creationCenter/outline/delete',
        method: 'get',
        params
    })
}
// 查询我的文档列表
export function getResourceList(params: { folderId: number, pageNum: number, pageSize: number }) {
    return request({
        url: '/base/resource/getCreationResourceList',
        method: 'get',
        params: params
    })
}
// 新建文件夹
export function createFolder(params: { resourceName: string, folderId: number }) {
    return request({
        url: '/base/resource/newCreationFolder',
        method: 'post',
        data: params
    })
}
// 删除文件夹
export function deleteFolder(params: { idList: number[] }) {
    return request({
        url: '/base/resource/deleteResources',
        method: 'post',
        data: params
    })
}
// 编辑文件或者文件夹
export function editResource(params: { resourceId: number, resourceName: string }) {
    return request({
        url: '/base/resource/modifyResource',
        method: 'post',
        data: params
    })
}
// 编辑文件或者文件夹
export function editResourceTag(params: { resourceId: number, resourceName: string }) {
    return request({
        url: '/base/resource/modifyResourceTag',
        method: 'post',
        data: params
    })
}
// 搜索
export function searchResources(params: { pageNum: number, pageSize: number, resourceName: string }) {
    return request({
        url: '/base/resource/searchResources',
        method: 'post',
        data: params
    })
}
// 导入
export function uploadFile(params: { folderId: number, useType: string, encryptFlag: string, accessCode: string, resourceFile: File }) {
    return request({
        url: '/base/resource/uploadFile',
        method: 'post',
        data: params
    })
}
// 查询文件夹树
export function queryTree(params: { resourceSource: string }) {
    return request({
        url: `/base/resource/queryTree`,
        method: 'get',
        params
    })
}
// 获取文件路径
export function queryResourceIds(params) {
    return request({
        url: `/base/resource/queryResourceIds?resourceId=${params.resourceId}`,
        method: 'get',
    })
}
// 更新文件修改
export function updateResources(params: { resourceId: number, shareUserList: any }) {
    return request({
        url: '/base/resource/modifyShareUser',
        method: 'post',
        data: params
    })
}
// 素材库下载文件
export function downloadExportWord(params: { resourceId: any }) {
    return request({
        url: '/base/resource/download',
        method: 'get',
        params,
        responseType: 'arraybuffer',
    })
}
// 大纲新增编辑参数
type outlineParams = {
    /**
         * 组件列表
         */
    module: moduleParams[];
    /**
     * 大纲描述
     */
    outlineDesc?: string;
    /**
     * 大纲名称
     */
    outlineName?: string;
    /**
     * 大纲标签
     */
    outlineTag?: string;
    /**
     * 模板id
     */
    templateId: number;
    /**
    * 大纲id
    */
    outlineId?: number
}
type moduleParams = {
    /**
     * 要素id
     */
    elementId?: number;
    /**
     * 要素名称
     */
    elementName?: string;
    /**
     * 是否勾选 0否1是
     */
    isCheck?: string;
    /**
     * 重点信息
     */
    keypoint?: string;
    /**
     * 行id
     */
    lineId?: number;
    /**
     * 板块id
     */
    moduleId?: number;
    /**
     * 板块名称
     */
    moduleName?: string;
    /**
     * 板块标题
     */
    moduleTitle?: string;
    /**
     * 负责人
     */
    principal?: string;
    /**
     * 子版块id
     */
    submoduleId?: number;
    /**
     * 子版块名称
     */
    submoduleName?: string;
}
// 保存大纲
export function addOutline(params: outlineParams) {
    return request({
        url: `/base/creationCenter/outline/add`,
        method: 'post',
        data: params
    })
}
// 保存编辑大纲
export function updateOutline(params: outlineParams) {
    return request({
        url: `/base/creationCenter/outline/update`,
        method: 'post',
        data: params
    })
}
// 查询大纲
export function getOutline(params: { outlineId: number }) {
    return request({
        url: '/base/creationCenter/outline/query',
        method: 'get',
        params
    })
}
// 重命名大纲
export function updateName(params: { outlineId: number, outlineName: string }) {
    return request({
        url: '/base/creationCenter/outline/updateName',
        method: 'post',
        data: params,
    })
}
// 预览大纲 
export function getOutlinePreview(params: outlineParams) {
    return request({
        url: '/base/creationCenter/outline/preview',
        method: 'post',
        data: params
    })
}
// 敏感词检测
export function checkSensitiveWords(params: { content: string }) {
    return request({
        url: '/analyze/hadoop/checkSensitiveWords',
        method: 'post',
        data: params
    })
}
// 大纲列表进入编辑器时获取文本内容
export function getContent(params: { outlineId: string }) {
    return request({
        url: '/base/creationCenter/outline/getContent',
        method: 'get',
        params
    })
}
