import { useContext } from "react";
import { Tooltip } from "antd";
import { GlobalContext } from "@/GlobalContext";
import { UserPerm } from "./UserPerm";
import style from "./index.module.less";

function AccessWrap({
	children: AccessComponent,
	type = "button",
	tooltip = "",
	action,
	disabled = null,
	...props
}: {
	action?: string;
	type?: string;
	tooltip?: string;
	children: any;
	disabled?: boolean//直接禁用
}) {
	const { state: { user } } = useContext(GlobalContext);
	/**
	 * 根据操作类型返回最终是否禁用的样式类名
	 * @param actionType 操作
	 * @param nodeType 禁用node节点类型
	 * @returns
	 */
	const hasPerms = (actionType, nodeType = type) => {
		const currentUser = user?.info?.permissionName;
		if (currentUser) {
			if (nodeType === "button") {
				if (!actionType) {
					return false
				}
				if (disabled) return style.permissiond_isabled_btn
				return !UserPerm[currentUser][actionType] ? style.permissiond_isabled_btn : null;
			}
			if (nodeType === "icon") {
				if (!actionType) {
					return false
				}
				if (disabled) return style.permissiond_isabled_icon
				return !UserPerm[currentUser][actionType] ? style.permissiond_isabled_icon : null;
			}
		} else {
			return '';
		}
	};

	return (
		<span className={hasPerms(action)}>
			<Tooltip title={tooltip}>{AccessComponent}</Tooltip>
		</span>
	);
}

export default AccessWrap;

export function hasPermsAccess(userType, actionType) {
	if (userType && actionType) {
		return !UserPerm[userType][actionType];
	} else {
		return false;
	}
}
