import * as echarts from "echarts/core";
import { GridComponent, GridComponentOption } from "echarts/components";
import { LineChart, LineSeriesOption } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { useEffect, useRef } from "react";

echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);

type EChartsOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption>;

type LineChaProps = {
	data: {
		xaxis: string[];
		yaxis: string[];
		data: any[];
		legendData?: any;
	};
};

const LineCha = (props: LineChaProps) => {
	const chartsBox = useRef();
	useEffect(() => {
		
		const resizeChart = () => {
			myChart.resize();
		};
		const option: EChartsOption = {
			tooltip: {
				trigger: "axis",
			},
			legend: {
				data: props?.data?.legendData,
			},
			xAxis: {
				type: "category",
				boundaryGap: false,
				data: props?.data?.xaxis || [],
			},
			yAxis: {
				type: "value",
				data: props?.data?.yaxis || [],
				axisLabel: {
					formatter: function (value, index) {
						var value;
						if (value >= 1000 && value <= 9999) {
							value = value / 1000 + "k";
						} else if (value < 1000) {
							value = value;
						} else if (value >= 10000) {
							value = value / 10000 + "w";
						}
						return value;
					},
				},
			},
			grid: {
				left: "3%",
				right: "4%",
				bottom: "3%",
				top: "5%",
				containLabel: true,
			},
			series: [
				{
					data: props.data?.data || [],
					type: "line",
				},
			],
		};
		let myChart = echarts.init(chartsBox.current);
		myChart.setOption(option);
		window.addEventListener("resize", resizeChart);
		return () => window.removeEventListener("resize", resizeChart);
	}, [props.data]);

	return <div ref={chartsBox} style={{ height: "100%" }}></div>;
};

export default LineCha;
