export const UserPerm = {
    // 体验账号(功能受限)
    'EXPERIENCE': {
        // 监测
        'monitor.show': true,
        'monitor.create': false,
        'monitor.edit': false,
        'monitor.delete': false,
        // 事件
        'event.show': false,
        'event.create': false,
        'event.edit': false,
        'event.delete': false,
        // 预警
        'warn.show': true,
        'warn.create': false,
        'warn.edit': false,
        'warn.delete': false,
        'warn.Negative':false,
        // 用户信息管理
        'user.info.show': true,
        'user.info.create': false,
        'user.info.edit': false,
        'user.info.delete': false,
        // 媒体管理
        'user.media.edit': false,
        'user.media.show': true,
        // 组织管理
        'user.subject.edit': false,
        'user.subject.create': false,
        'user.subject.show': true,
        'user.subject.delete': false,
        // 业务反馈
        'user.work.edit': false,
        'user.work.create': false,
        'user.work.show': true,
        'user.work.delete': false,
        // 账号管理
        'user.account.edit': false,
        'user.account.create': false,
        'user.account.show': false,
        'user.account.delete': false,
        // 工作台
        "workbench.show": true,
        // 媒体中心
        "media.click": false,//媒体中心访问权限
        "reporterList.click": false,//记者列表访问权限
        "mediaList.click": false,//核心媒体访问权限
        // 素材库
        "material.click": false,
        "material.resources.edit": false,
        "material.resources.create": false,
        "material.resources.show": true,
        "material.resources.delete": false,
        "material.collection.show": true,
        // 创作中心
        "creation.show": false,
        // 简报
        "report.edit": false,
        "report.create": false,
        "report.show": true,
        "report.delete": false,
        // 简报模版
        "report.template.create": false,
        "report.template.delete": false,
        "report.template.edit": false,
        // 简报任务
        "report.taskManage.create": false,
        "report.taskManage.delete": false,
        "report.taskManage.edit": false,
        // 问人网入口
        "wenren.show":false
    },
    // 试用账号(功能受限)
    'ON_TRIAL': {
        // 监测
        'monitor.show': true,
        'monitor.create': true,
        'monitor.edit': true,
        'monitor.delete': true,
        // 事件
        'event.show': true,
        'event.create': true,
        'event.edit': true,
        'event.delete': true,
        // 预警
        'warn.show': true,
        'warn.create': true,
        'warn.edit': true,
        'warn.delete': true,
        'warn.Negative':false,
        // 用户信息管理
        'user.info.show': true,
        'user.info.create': true,
        'user.info.edit': true,
        'user.info.delete': true,
        // 媒体管理
        'user.media.edit': false,
        'user.media.show': true,
        // 组织管理
        'user.subject.edit': false,
        'user.subject.create': false,
        'user.subject.show': true,
        'user.subject.delete': false,
        // 业务反馈
        'user.work.edit': false,
        'user.work.create': false,
        'user.work.show': true,
        'user.work.delete': false,
        // 账号管理
        'user.account.edit': false,
        'user.account.create': false,
        'user.account.show': false,
        'user.account.delete': false,
        // 工作台
        "workbench.show": true,
        // 媒体中心
        "media.click": true,
        "reporterList.click": false,
        "mediaList.click": false,
        // 素材库
        "material.click": true,
        "material.resources.edit": false,
        "material.resources.create": false,
        "material.resources.show": true,
        "material.resources.delete": false,
        "material.collection.show": true,
        // 创作中心
        "creation.show": false,
        // 简报
        "report.edit": true,
        "report.create": true,
        "report.show": true,
        "report.delete": true,
        // 简报模版
        "report.template.create": true,
        "report.template.delete": true,
        "report.template.edit": true,
        // 简报任务
        "report.taskManage.create": true,
        "report.taskManage.delete": true,
        "report.taskManage.edit": true,
        // 问人网入口
        "wenren.show":false
    },
    // 普通账号
    'MEMBER': {
        // 监测
        'monitor.show': true,
        'monitor.create': true,
        'monitor.edit': true,
        'monitor.delete': true,
        // 事件
        'event.show': true,
        'event.create': true,
        'event.edit': true,
        'event.delete': true,
        // 预警
        'warn.show': true,
        'warn.create': true,
        'warn.edit': true,
        'warn.delete': true,
        'warn.Negative':true,
        // 用户信息管理
        'user.info.show': true,
        'user.info.create': true,
        'user.info.edit': true,
        'user.info.delete': true,
        // 媒体管理
        'user.media.edit': true,
        'user.media.show': true,
        // 组织管理
        'user.subject.edit': true,
        'user.subject.create': true,
        'user.subject.show': true,
        'user.subject.delete': true,
        // 业务反馈
        'user.work.edit': true,
        'user.work.create': true,
        'user.work.show': true,
        'user.work.delete': true,
        // 账号管理
        'user.account.edit': false,
        'user.account.create': false,
        'user.account.show': false,
        'user.account.delete': false,
        // 工作台
        "workbench.show": true,
        // 媒体中心
        "media.click": true,
        "reporterList.click": true,
        "mediaList.click": true,
        // 素材库
        "material.click": true,
        "material.resources.edit": true,
        "material.resources.create": true,
        "material.resources.show": true,
        "material.resources.delete": true,
        "material.collection.show": true,
        // 创作中心
        "creation.show": true,
        // 简报
        "report.edit": true,
        "report.create": true,
        "report.show": true,
        "report.delete": true,
        // 简报模版
        "report.template.create": true,
        "report.template.delete": true,
        "report.template.edit": true,
        // 简报任务
        "report.taskManage.create": true,
        "report.taskManage.delete": true,
        "report.taskManage.edit": true,
        // 问人网入口
        "wenren.show":true
    },
    // 管理员账号
    'ADMIN': {
        // 监测
        'monitor.show': true,
        'monitor.create': true,
        'monitor.edit': true,
        'monitor.delete': true,
        // 事件
        'event.show': true,
        'event.create': true,
        'event.edit': true,
        'event.delete': true,
        // 预警
        'warn.show': true,
        'warn.create': true,
        'warn.edit': true,
        'warn.delete': true,
        'warn.Negative':true,
        // 用户信息管理
        'user.info.show': true,
        'user.info.create': true,
        'user.info.edit': true,
        'user.info.delete': true,
        // 媒体管理
        'user.media.edit': true,
        'user.media.show': true,
        // 组织管理
        'user.subject.edit': true,
        'user.subject.create': true,
        'user.subject.show': true,
        'user.subject.delete': true,
        // 业务反馈
        'user.work.edit': true,
        'user.work.create': true,
        'user.work.show': true,
        'user.work.delete': true,
        // 账号管理
        'user.account.edit': true,
        'user.account.create': true,
        'user.account.show': true,
        'user.account.delete': true,
        // 工作台
        "workbench.show": true,
        // 媒体中心
        "media.click": true,
        "reporterList.click": true,
        "mediaList.click": true,
        // 素材库
        "material.click": true,
        "material.resources.edit": true,
        "material.resources.create": true,
        "material.resources.show": true,
        "material.resources.delete": true,
        "material.collection.show": true,
        // 创作中心
        "creation.show": true,
        // 简报
        "report.edit": true,
        "report.create": true,
        "report.show": true,
        "report.delete": true,
        // 简报模版
        "report.template.create": true,
        "report.template.delete": true,
        "report.template.edit": true,
        // 简报任务
        "report.taskManage.create": true,
        "report.taskManage.delete": true,
        "report.taskManage.edit": true,
        // 问人网入口
        "wenren.show":true
    }
}