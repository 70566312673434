import request from "@/service/request"

// 获取个性化监测数据
export function getcustomData() {
    return request({
        url: '/recommend/article/selectIndividualizationMonitorList',
        method: 'get',
    })
}
// 获取echarts图表数据
export function getEchartsoption(params: PRT.CustomColumns) {
    return request({
        url: '/analyze/monitorInfo/general/add',
        method: 'post',
        data: params
    })
}
// 获取IR热点数据
export function getIrHotData(params: PRT.IrHotColumns) {
    return request({
        url: '/analyze/monitorInfo/general/add',
        method: 'post',
        data: params
    })
}
// 获取GR热点数据
export function getGrHotData(params: PRT.GrHotColumns) {
    return request({
        url: '/analyze/monitorInfo/general/add',
        method: 'post',
        data: params
    })
}
// 获取个性化事件分析数据
export function getEvtData() {
    return request({
        url: '/recommend/article/selectEventMonitorList',
        method: 'get',
    })
}
// 获取AI事件分析数据
export function getAIData(params: PRT.AIColumns) {
    return request({
        url: '/analyze/monitorInfo/general/add',
        method: 'post',
        data: params
    })
}
// 获取个性化预警数据
export function getWarnData() {
    return request({
        url: '/recommend/article/selectEarlyWarnList',
        method: 'get',
    })
}
// 获取AI预警数据
export function getAIWarnData() {
    return request({
        url: '/analyze/monitorInfo/general/add',
        method: 'post',
    })
}
// 获取简报数据
export function getReportData(params: PRT.ReportColumns) {
    return request({
        url: '/analyze/monitorInfo/general/add',
        method: 'post',
        data: params
    })
}

// 收藏
export function addCollection(params: { collectId: string, collectType: string }) {
    return request({
        url: '/account/collect/insertYcCollectInfo',
        method: 'post',
        data: params
    })
}

// 获取监测方案
export function selectAllMonitorList() {
    return request({
        url: '/analyze/monitorInfo/selectAllMonitorList',
        method: 'get',
    })
}

// 事件发展脉络分析
export function eventDevelopAnalyze(params) {
    return request({
        url: '/recommend/workBench/eventDevelopAnalyze',
        method: 'post',
        data: params
    })
}


// 重要态度传播路径
export function getEmotionPath(params) {
    return request({
        url: '/analyze/eventAnalyse/getEmotionPath',
        method: 'post',
        data: params
    })
}

// 事件监测方案列表
export function selectEventMonitor() {
    return request({
        url: `/analyze/monitorInfo/selectEventMonitor`,
        method: 'get',
    })
}

// 个性化监测方案列表
// export function selectIndividualizationMonitorList() {
//     return request({
//         url: `/recommend/workBench/selectIndividualizationMonitorList`,
//         method: 'get',
//     })
// }
export function selectIndividualizationMonitorList() {
    return request({
        url: `/recommend/workBench/attentionMedia`,
        method: 'get',
    })
}

// 折线趋势图
export function eventMediaVolumeAnalyze(params) {
    return request({
        url: `/recommend/workBench/eventMediaVolumeAnalyze`,
        method: 'post',
        data:params
    })
}

// 根据媒体名称模糊搜索
export function findMediaByMediaName(params) {
    return request({
        url: `/recommend/mediaInfo/findMediaByMediaName`,
        method: 'get',
        params:{mediaName:params}
    })
}

// 查询所有专项监测方案列表
export function selectSpecialMonitorList() {
    return request({
        url: `/analyze/monitorInfo/selectSpecialMonitorList`,
        method: 'get',
    })
}

// 事件热词联系图
export function eventHotWordRelationMap(params) {
    return request({
        url: `/recommend/workBench/eventHotWordRelationMap`,
        method: 'post',
        data:params
    })
}

// 个性化预警分析图
export function warningAnalyze(params) {
    return request({
        url: `/recommend/workBench/warningAnalyze`,
        method: 'post',
        data:params
    })
}

// 分析概要
export function eventExplain(params) {
    return request({
        url: '/recommend/workBench/eventExplain',
        method: 'post',
        data: params
    })
}
// 预览
export function getArticleList(params) {
    return request({
        url: `/analyze/eventAnalyse/eventSearchArticle`,
        method: 'post',
        data: params,
    })
}


