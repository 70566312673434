import { Button, Modal, Input, message } from "antd";
import React, { useState, useRef, useEffect } from "react";
import style from "./index.module.less";
import { CheckCircleTwoTone } from "@ant-design/icons";

const App = (props: any) => {
  
  const { visible, setVisible, url, setUrl ,qrCode = () => { } } = props;
  const handleOk = () => {
    setVisible(false);
    handleRest()
  };

  const handleCancel = () => {
    handleRest()
    setVisible(false);
  };
  /**
   * 重置输入框
   */
  const handleRest = () => { setUrl('')}
  const copyEle = useRef();
  const onCopy = () => {
    const ele = copyEle.current;
    const range = document.createRange();
    window.getSelection().removeAllRanges();
    range.selectNode(ele);
    window.getSelection().addRange(range);
    const copyStatus = document.execCommand("Copy");
    if (copyStatus) {
      message.success("复制成功", 1);
    } else {
      message.error("复制失败", 1);
    }
    window.getSelection().removeAllRanges();
  };
  return (
    <div className={style.content}>
      <Modal
        width={650}
        title=""
        destroyOnClose={true}
        // afterClose={() => handleRest()}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer=""
        centered
      >
        <div className={style.title}>
          <CheckCircleTwoTone
            twoToneColor="#52c41a"
            style={{ paddingRight: "10px" }}
          />
          成功创建分享链接:
        </div>
        <div className={style.text}>您可以在PC端打开链接获得更多操作体验</div>
        <div className={style.inputbox}>
          <div className={style.linkBox}>
            <span className={style.link} ref={copyEle}>
              {url}
            </span>
            <span className={style.tips}>7天后失效</span>
          </div>
          <Button
            type="primary"
            style={{ backgroundColor: "#0085D6", borderColor: "#0085D6" }}
            onClick={onCopy}
          >
            复制链接
          </Button>
        </div>
        <div className={style.text}>
          您也可以复制链接分享给好友浏览当前链接，或者分享至微信
        </div>
      </Modal>
    </div>
  );
};

export default App;
