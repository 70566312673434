/* eslint-disable */
import { useEffect, useState } from "react";
import style from "./index.module.less";
import { Button, Col, Row, Table, Modal, Tag, message, Tooltip, Spin } from "antd";
import TreempCha from "@/components/Charts/TreempChart";
import LineCha from "@/components/Charts/LineChart";
import { Wordcloudgram, BarChartOnPolarGram, MultipleLinegram, Pie } from "@/pages/Event/components/CustomCharts/index";
import moment from "moment";
import MultipleLineChart from "@/components/Charts/MultipleLineChart";
import { saveReportResource } from "@/pages/Report/service";
import COS from "cos-js-sdk-v5";
import Nodata from "@/components/NodataSmall";
import downLoadPdf from "@/utils/downLoadPdf";
import { communityColmns, transitionColumns, attitudeColumns } from "../reportColumns";

export default function PrintReport(params) {
	const { reportTitle, reportDetailDTOList = [], repostDetailData } = params;
	const { createTime, reportContent, reportPerson } = repostDetailData;
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [txCloudFileURL, setTxCloudFileURL] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	// // 重要态度首发
	// const attitudeColumns = [
	// 	{
	// 		title: "观点",
	// 		align: "center",
	// 		width: "200px",
	// 		ellipsis: true,
	// 		dataIndex: "articleTitle",
	// 		key: "articleTitle",
	// 	},
	// 	{
	// 		title: "媒体名称",
	// 		width: "100px",
	// 		align: "center",
	// 		dataIndex: "mediaName",
	// 		key: "mediaName",
	// 		ellipsis: true,
	// 	},

	// 	{
	// 		title: "时间",
	// 		align: "center",
	// 		width: "200px",
	// 		dataIndex: "publishTime",
	// 		key: "publishTime",
	// 		ellipsis: true,
	// 	},
	// 	{
	// 		title: "态度类型",
	// 		align: "center",
	// 		width: "100px",
	// 		ellipsis: true,
	// 		dataIndex: "fullTextSentiment",
	// 		key: "fullTextSentiment",
	// 		render: (_, record) => {
	// 			return (
	// 				<>
	// 					{record?.fullTextSentiment === "0" && (
	// 						<span className={style.sentimentZx}>
	// 							<Tag>中性</Tag>
	// 						</span>
	// 					)}
	// 					{record?.fullTextSentiment === "1" && (
	// 						<span className={style.sentimentZc}>
	// 							<Tag>赞成</Tag>
	// 						</span>
	// 					)}
	// 					{record?.fullTextSentiment === "-1" && (
	// 						<span className={style.sentimentP}>
	// 							<Tag>批评</Tag>
	// 						</span>
	// 					)}
	// 				</>
	// 			);
	// 		},
	// 	},
	// ];
	// // 关键词转折点
	// const transitionColumns = [
	// 	{
	// 		title: "观点",
	// 		width: "200px",
	// 		align: "center",
	// 		ellipsis: true,
	// 		dataIndex: "articleTitle",
	// 		key: "articleTitle",
	// 	},
	// 	{
	// 		title: "媒体名称",
	// 		align: "center",
	// 		width: "100px",
	// 		dataIndex: "mediaName",
	// 		key: "mediaName",
	// 		ellipsis: true,
	// 	},
	// 	{
	// 		title: "时间",
	// 		align: "center",
	// 		width: "150px",
	// 		dataIndex: "publishTime",
	// 		key: "publishTime",
	// 		ellipsis: true,
	// 	},
	// 	{
	// 		title: "态度类型",
	// 		align: "center",
	// 		width: "100px",
	// 		ellipsis: true,
	// 		dataIndex: "fullTextSentiment",
	// 		key: "fullTextSentiment",
	// 		render: (_, record) => {
	// 			return (
	// 				<>
	// 					{record?.fullTextSentiment === "0" && (
	// 						<span className={style.sentimentZx}>
	// 							<Tag>中性</Tag>
	// 						</span>
	// 					)}
	// 					{record?.fullTextSentiment === "1" && (
	// 						<span className={style.sentimentZc}>
	// 							<Tag>赞成</Tag>
	// 						</span>
	// 					)}
	// 					{record?.fullTextSentiment === "-1" && (
	// 						<span className={style.sentimentP}>
	// 							<Tag>批评</Tag>
	// 						</span>
	// 					)}
	// 				</>
	// 			);
	// 		},
	// 	},
	// ];

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};
	// base64 转换为 文件
	const base64ConvertFile = (urlData, filename) => {
		// 64转file
		var arr = urlData.split(",");
		var type = arr[0].match(/:(.*?);/)[1];
		var fileExt = type.split("/")[1];
		var bstr = atob(arr[1]);
		var n = bstr.length;
		var u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename + "." + fileExt, {
			type: type,
		});
	};
	// 上传文件到腾讯云
	const uploadFileHandler = (params) => {
		var cos = new COS({
			SecretId: "AKIDEHUTCfqkMhlY3IGxNWP1MQDjxtYwhHch",
			SecretKey: "qRmsiJejZ9TXMwGK8gy0mJabNt99tOdc",
		});
		const time = moment(new Date().getTime()).format("YYYYMMDD");
		let type = params.type.split("/")[1];
		cos.putObject(
			{
				Region: "ap-beijing",
				Key: `webresource/dowlondfile/${time}/${params.lastModified}.${type}`,
				Bucket: "yancecloud-file-1311717884",
				Body: params, // 上传文件对象
				onProgress: function (progressData) {
					console.log(JSON.stringify(progressData));
				},
			},
			function (err, data) {
				console.log(err || data, "3333");
				setTxCloudFileURL(
					`https://image-110.yancecloud.com/webresource/dowlondfile/${time}/${params.lastModified}.${type}`
				);
			}
		);
	};
	// 下载内容添加到素材库
	const saveReportResourceHandler = async (params) => {
		const res = await saveReportResource({
			monitorName: repostDetailData?.monitorName,
			fileUrl: txCloudFileURL,
			resourceName: params,
		});
		const { code, msg } = res.data;
		if (code === 200) {
			message.success(msg);
			setIsLoading(false);
		} else {
			message.error(msg);
			setIsLoading(false);
		}
	};
	// // 给dom节点分页
	// const outPutPdfFn = async (pdfFileName) => {
	// 	return new Promise((resolve, reject) => {
	// 		const ele = pdfFileName;
	// 		ele.style.height = "initial";
	// 		const A4_WIDTH = 595;
	// 		const A4_HEIGHT = 842;
	// 		let target = ele;
	// 		let pageHeight = (target.scrollWidth / A4_WIDTH) * A4_HEIGHT;
	// 		// 获取分割dom，此处为class类名为item的dom
	// 		let domList = document.querySelectorAll("#itemDom");
	// 		// 进行分割操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
	// 		let pageNum = 1; //pdf页数
	// 		let eleBounding = ele.getBoundingClientRect();
	// 		for (let i = 0; i < domList.length; i++) {
	// 			let node = domList[i];
	// 			let bound = node.getBoundingClientRect();
	// 			let offset2Ele = bound.top - eleBounding.top;
	// 			let currentPage = Math.ceil((bound.bottom - eleBounding.top) / pageHeight); //当前元素应该在哪一页
	// 			if (pageNum < currentPage) {
	// 				pageNum++;
	// 				let divParent = domList[i].parentNode; // 获取该div的父节点
	// 				let newNode = document.createElement("div");
	// 				newNode.className = "emptyDiv";
	// 				newNode.style.background = "white";
	// 				newNode.style.height = pageHeight * (pageNum - 1) - offset2Ele + 30 + "px"; //+30为了在换下一页时有顶部的边距
	// 				newNode.style.width = "100%";
	// 				let next = domList[i].nextSibling; // 获取div的下一个兄弟节点
	// 				// 判断兄弟节点是否存在
	// 				if (next) {
	// 					// 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
	// 					divParent.insertBefore(newNode, node);
	// 				} else {
	// 					// 不存在则直接添加到最后,appendChild默认添加到divParent的最后
	// 					divParent.appendChild(newNode);
	// 				}
	// 			}
	// 		}
	// 		// 异步函数，导出成功后处理交互
	// 		getPDF(resolve, reject);
	// 	});
	// };
	// // canvans转pdf
	// const getPDF = (resolve) => {
	// 	const iframeNode = document.getElementById("printBox");
	// 	const A4_WIDTH = 595;
	// 	const A4_HEIGHT = 842;

	// 	let ele = iframeNode;
	// 	let pdfFileName = reportTitle;
	// 	let eleW = ele.offsetWidth; // 获得该容器的宽
	// 	let eleH = ele.scrollHeight; // 获得该容器的高
	// 	let eleOffsetTop = ele.offsetTop; // 获得该容器到文档顶部的距离
	// 	let eleOffsetLeft = ele.offsetLeft; // 获得该容器到文档最左的距离
	// 	let canvas = document.createElement("canvas");
	// 	let abs = 0;
	// 	let win_in = document.documentElement.clientWidth || document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
	// 	let win_out = window.innerWidth; // 获得当前窗口的宽度（包含滚动条）
	// 	if (win_out > win_in) {
	// 		abs = (win_out - win_in) / 2; // 获得滚动条宽度的一半
	// 	}
	// 	canvas.width = eleW * 2; // 将画布宽&&高放大两倍
	// 	canvas.height = eleH * 2;
	// 	let context = canvas.getContext("2d");
	// 	context.scale(2, 2); // 增强图片清晰度
	// 	context.translate(-eleOffsetLeft - abs, -eleOffsetTop);
	// 	html2canvas(ele, {
	// 		useCORS: true, //允许canvas画布内可以跨域请求外部链接图片, 允许跨域请求。
	// 		allowTaint: false,
	// 		scale: 3, // 处理模糊问题
	// 		dpi: 300,
	// 	}).then(async (canvas) => {
	// 		let contentWidth = canvas.width;
	// 		let contentHeight = canvas.height;
	// 		//一页pdf显示html页面生成的canvas高度;
	// 		let pageHeight = (contentWidth / A4_WIDTH) * A4_HEIGHT; // 这样写的目的在于保持宽高比例一致 pageHeight/canvas.width = a4纸高度/a4纸宽度// 宽度和canvas.width保持一致
	// 		//未生成pdf的html页面高度
	// 		let leftHeight = contentHeight;
	// 		//页面偏移
	// 		let position = 0;
	// 		//a4纸的尺寸[595,842],单位像素，html页面生成的canvas在pdf中图片的宽高
	// 		let imgWidth = A4_WIDTH - 10; //-10为了页面有右边距
	// 		let imgHeight = (A4_WIDTH / contentWidth) * contentHeight;
	// 		let pageData = canvas.toDataURL("image/jpeg", 1.0);
	// 		let pdf = jsPDF("", "pt", "a4");
	// 		//有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
	// 		//当内容未超过pdf一页显示的范围，无需分页
	// 		if (leftHeight < pageHeight) {
	// 			//在pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示；
	// 			pdf.addImage(pageData, "JPEG", 5, 0, imgWidth, imgHeight);
	// 			// pdf.addImage(pageData, 'JPEG', 20, 40, imgWidth, imgHeight);
	// 		} else {
	// 			// 分页
	// 			while (leftHeight > 0) {
	// 				pdf.addImage(pageData, "JPEG", 5, position, imgWidth, imgHeight);
	// 				leftHeight -= pageHeight;
	// 				position -= A4_HEIGHT;
	// 				//避免添加空白页
	// 				if (leftHeight > 0) {
	// 					pdf.addPage();
	// 				}
	// 			}
	// 		}
	// 		//   将paf上传到腾讯云
	// 		let file = base64ConvertFile(pdf.output("dataurlstring"), `${reportTitle}.pdf`);
	// 		uploadFileHandler(file);
	// 		pdf.save(pdfFileName + ".pdf", { returnPromise: true }).then(() => {
	// 			//去除添加的空div 防止页面混乱
	// 			let doms = document.querySelectorAll(".emptyDiv");
	// 			for (let i = 0; i < doms.length; i++) {
	// 				doms[i].remove();
	// 			}
	// 		});
	// 		ele.style.height = "";
	// 		resolve();
	// 	});
	// };

	// 下载
	const handleClickDownPDF = async () => {
		setIsLoading(true);
		const iframeNode = document.getElementById("printBox");
		// outPutPdfFn(iframeNode);
		const pdf = await downLoadPdf(iframeNode, reportTitle, "#itemDom");
		let file = base64ConvertFile(pdf.output("dataurlstring"), `${reportTitle}.pdf`);
		uploadFileHandler(file);
	};
	// logo
	const logoHandler = () => {
		if (repostDetailData?.entLogoUrl === ""||!repostDetailData?.entLogoUrl) {
			return "/images/logoBlack.png";
		} else {
			return repostDetailData?.entLogoUrl;
		}
	};
	// 正负面特征词数据合并
	const BarChartHandler = (params) => {
		console.log(params, "parms");
		return [...params[0].dataList, ...params[1].dataList];
	};
	useEffect(() => {
		if (txCloudFileURL === "") return;
		saveReportResourceHandler(reportTitle);
	}, [txCloudFileURL]);
	return (
		<>
			<Button size="small" onClick={showModal} style={{ marginRight: 10 }}>
				下载
			</Button>
			<div className={style.modalBox}>
				<Modal
					footer={null}
					visible={isModalVisible}
					onOk={handleOk}
					onCancel={handleCancel}
					getContainer={false}
					title={
						<div className={style.ModalTitle}>
							<span style={{ fontSize: 20 }}>下载预览</span>
							<Button type="primary" style={{ marginLeft: 20 }} size="small" onClick={handleClickDownPDF}>
								下载
							</Button>
						</div>
					}
				>
					{isLoading && <Spin className={style.loading} />}
					<div className={style.box} id="printBox">
						<div>
							<div className={style.header}>
								<img src={logoHandler()} className={style.logo_img} alt="" />
								<div>
									{/* 标题 */}
									<div className={style.detail_title}>{reportTitle}</div>
									{/* 时间 */}
									<div>
										<span style={{ marginLeft: "10px" }} className={style.detail_title_header}>
											{moment(createTime).format("YYYY-MM-DD")}
										</span>
									</div>
								</div>
								<div style={{ fontSize: 12, fontWeight: 700, width: 150 }}>
									{repostDetailData?.reportId}
								</div>
							</div>
							{reportDetailDTOList?.map((item, index) => {
								switch (item?.reportTextName) {
									// 简报简述
									case "1":
										return (
											<div className={style.box_style} id="itemDom">
												<div className={style.two_title}>简报简述</div>
												<div className={style.item_content_box}>
													<div className={style.item_content_detail}>
														{item?.reportTextDTO?.subReportText}
													</div>
												</div>
											</div>
										);
										break;
									// 处置建议
									case "2":
										return (
											<div className={style.box_style} id="itemDom">
												<div className={style.two_title}>处置建议</div>
												<div className={style.item_content_box}>
													<div className={style.item_content_detail}>
														{item?.reportTextDTO?.subReportText}
													</div>
												</div>
											</div>
										);
										break;
									// 声量趋势
									case "3":
										return (
											<div className={style.box_style} id="itemDom">
												<h4 className={style.two_title}>声量趋势</h4>
												{item?.reportTextDTO?.subReportText ? (
													<div className={style.item_content_box}>
														<div
															className={style.item_content_detail}
															style={{ height: 70 }}
														>
															{item?.reportTextDTO?.subReportText}
														</div>

														<div style={{ height: 200 }}>
															<LineCha data={item?.reportTextDTO?.analyzeInfo?.[0]} />
														</div>
													</div>
												) : (
													<Nodata description="暂无强关联数据" />
												)}
											</div>
										);
										break;
									//  监管与媒体态度
									case "5":
										return (
											<div className={style.box_style} id="itemDom">
												<div className={style.two_title}>监管与媒体态度</div>
												{item?.reportTextDTO?.subReportText ? (
													<div className={style.item_content_box}>
														<div className={style.item_content_detail}>
															{item?.reportTextDTO?.subReportText}
														</div>

														<Row style={{ height: 220 }}>
															<Col span={12}>
																<h4 className={style.item_content_title_center}>
																	{item?.reportTextDTO?.analyzeInfo?.[0]?.title}
																</h4>
																<div style={{ height: 200 }}>
																	<Pie
																		data={
																			item?.reportTextDTO?.analyzeInfo?.[0]
																				?.dataList
																		}
																	/>
																</div>
															</Col>
															<Col span={12}>
																<h4 className={style.item_content_title_center}>
																	{item?.reportTextDTO?.analyzeInfo?.[1]?.title}
																</h4>
																<div style={{ height: 200 }}>
																	<Pie
																		data={
																			item?.reportTextDTO?.analyzeInfo?.[1]
																				?.dataList
																		}
																	/>
																</div>
															</Col>
														</Row>
													</div>
												) : (
													<Nodata description="暂无强关联数据" />
												)}
											</div>
										);
										break;
									//  媒体分布 媒体声量分布
									case "6":
										return (
											<div className={style.box_style} id="itemDom">
												<div className={style.two_title}>媒体分布</div>
												{item?.reportTextDTO?.subReportText ? (
													<div className={style.item_content_box}>
														<div className={style.item_content_detail}>
															{item?.reportTextDTO?.subReportText}
														</div>

														<Row style={{ height: 200, width: "100%" }}>
															<Col span={24}>
																<TreempCha
																	data={
																		item?.reportTextDTO?.analyzeInfo?.[0]?.dataList
																	}
																/>
															</Col>
														</Row>
													</div>
												) : (
													<Nodata description="暂无强关联数据" />
												)}
											</div>
										);
										break;
									// 财经媒体
									case "7":
										return (
											<div className={style.box_style} id="itemDom">
												<div className={style.two_title}>财经媒体</div>
												{item?.reportTextDTO?.subReportText ? (
													<div className={style.item_content_box}>
														<div className={style.item_content_detail}>
															{item?.reportTextDTO?.subReportText}
														</div>
														<Row style={{ height: 220, width: "100%" }}>
															<Col span={24}>
																<div style={{ height: 200 }}>
																	<MultipleLineChart
																		data={item?.reportTextDTO?.analyzeInfo?.[0]}
																	/>
																</div>
															</Col>
														</Row>
													</div>
												) : (
													<Nodata description="暂无强关联数据" />
												)}
											</div>
										);
										break;
									// 活跃媒体
									case "8":
										return (
											<div className={style.box_style} id="itemDom">
												<div className={style.two_title}>活跃媒体</div>
												{item?.reportTextDTO?.subReportText ? (
													<div className={style.item_content_box}>
														<div className={style.item_content_detail}>
															{item?.reportTextDTO?.subReportText}
														</div>
														<Row style={{ height: 200 }}>
															<Col span={24}>
																<Pie
																	data={
																		item?.reportTextDTO?.analyzeInfo?.[0]?.dataList
																	}
																	radius={["0%", "55%"]}
																/>
															</Col>
														</Row>
													</div>
												) : (
													<Nodata description="暂无强关联数据" />
												)}
											</div>
										);
										break;
									// 关键高频词分布
									case "9":
										return (
											<div className={style.box_style} id="itemDom">
												<h4 className={style.two_title}>关键高频词分布</h4>
												{item?.reportTextDTO?.subReportText ? (
													<div className={style.item_content_box}>
														<div
															className={style.item_content_detail}
															style={{ height: 70 }}
														>
															{item?.reportTextDTO?.subReportText}
														</div>

														<div style={{ height: 200 }}>
															<Wordcloudgram
																data={item?.reportTextDTO?.analyzeInfo?.[0]?.dataList}
															/>
														</div>
													</div>
												) : (
													<Nodata description="暂无强关联数据" />
												)}
											</div>
										);
										break;
									// 重要态度首发
									case "10":
										return (
											<div className={style.box_style} id="itemDom">
												<h4 className={style.two_title}>重要态度</h4>
												{item?.reportTextDTO?.analyzeInfo?.[0]?.dataMap?.firstMediaChart ? (
													<div className={style.item_content_box}>
														<Table
															columns={attitudeColumns}
															pagination={false}
															rowKey={(record) => record?.articleTitle}
															size="small"
															dataSource={item?.reportTextDTO?.analyzeInfo?.[0]?.dataMap?.firstMediaChart?.slice(
																0,
																8
															)}
															style={{ marginTop: "20px" }}
														/>
													</div>
												) : (
													<Nodata description="暂无强关联数据" />
												)}
											</div>
										);
										break;
									// 关键词转折点
									case "11":
										return (
											<div className={style.box_style} id="itemDom">
												<h4 className={style.two_title}>热点内容</h4>
												{item?.reportTextDTO?.analyzeInfo?.[0]?.dataMap?.turningPointTop ? (
													<div className={style.item_content_box}>
														<Table
															pagination={false}
															columns={transitionColumns}
															rowKey={(record) => record?.reportId}
															size="small"
															dataSource={
																item?.reportTextDTO?.analyzeInfo?.[0]?.dataMap?.turningPointTop?.slice(
																	0,
																	8
																) || []
															}
															style={{ marginTop: "20px" }}
														/>
													</div>
												) : (
													<Nodata description="暂无强关联数据" />
												)}
											</div>
										);
										break;
									// 正负面特征词
									case "12":
										return (
											<div className={style.box_style} id="itemDom">
												<div className={style.two_title}>正负面特征词</div>
												{item?.reportTextDTO?.subReportText ? (
													<div className={style.item_content_box}>
														<div className={style.item_content_detail}>
															{item?.reportTextDTO?.subReportText}
														</div>
														<Row style={{ height: 220, justifyContent: "center" }}>
															<Col span={12}>
																<div style={{ height: 200 }}>
																	{item?.reportTextDTO?.attitudeAnalyzeVO
																		?.analyzeList?.[0]?.dataList ? (
																		<BarChartOnPolarGram
																			data={BarChartHandler(
																				item?.reportTextDTO?.attitudeAnalyzeVO
																					?.analyzeList
																			)}
																			onClick={(params) => {}}
																		/>
																	) : (
																		<Nodata description="暂无匹配数据" />
																	)}
																</div>
															</Col>
														</Row>
													</div>
												) : (
													<Nodata description="暂无强关联数据" />
												)}
											</div>
										);

										break;
									// 态度趋势
									case "13":
										return (
											<div className={style.box_style} id="itemDom">
												<div className={style.two_title}>态度趋势</div>
												{item?.reportTextDTO?.subReportText ? (
													<div className={style.item_content_box}>
														<div className={style.item_content_detail}>
															{item?.reportTextDTO?.subReportText}
														</div>
														<Row style={{ height: 200, width: "100%" }}>
															<Col span={24}>
																<MultipleLinegram
																	resize={false}
																	xaxis={item?.reportTextDTO?.sensitiveInfo?.xaxis}
																	yaxis={[]}
																	smooth={true}
																	legend={
																		item?.reportTextDTO?.sensitiveInfo
																			?.financeMediaNameData
																	}
																	data={
																		item?.reportTextDTO?.sensitiveInfo
																			?.reportSensitiveInfoData
																	}
																	colors={["#b4b6b5", "#8cdca2", "#f08755"]}
																/>
															</Col>
														</Row>
													</div>
												) : (
													<Nodata description="暂无强关联数据" />
												)}
											</div>
										);

										break;
									// 其他分析
									case "14":
										return (
											<div className={style.box_style} id="itemDom">
												<div className={style.two_title}>其他分析</div>
												<div className={style.item_content_box}>
													<div className={style.item_content_detail}>
														{item?.reportTextDTO?.subReportText
															? item?.reportTextDTO?.subReportText
															: "您可在此自定义其他分析"}
													</div>
												</div>
											</div>
										);
										break;
									//  媒体态度变化
									case "15":
										return (
											<div className={style.box_style} id="itemDom">
												<div className={style.two_title}>
													{item?.reportTextDTO?.tableDataVO?.title}
												</div>
												{item?.reportTextDTO?.subReportText ? (
													<div className={style.item_content_box}>
														<div className={style.item_content_detail}>
															{item?.reportTextDTO?.subReportText}
														</div>
														<Table
															pagination={false}
															columns={item?.reportTextDTO?.tableDataVO?.thead.map(
																(item) => ({
																	...item,
																	width: "10%",
																	align: "center",
																	title: item?.alias,
																	render:(_)=>_==="赞成"?<Tag color={"green"}>赞成</Tag> :_==="批评"?<Tag color={"red"}>批评</Tag>: _==="中性"?<Tag color={"default"}>中性</Tag>:_ 

																})
															)}
															rowKey="key"
															size="small"
															dataSource={
																item?.reportTextDTO?.tableDataVO?.tbody?.slice(0, 6) ||
																[]
															}
														/>
													</div>
												) : (
													<Nodata description="暂无强关联数据" />
												)}
											</div>
										);
										break;
									// 媒体报道变化
									case "16":
										return (
											<div className={style.box_style} id="itemDom">
												<div className={style.two_title}>
													{item?.reportTextDTO?.tableDataVO?.title}
												</div>
												{item?.reportTextDTO?.subReportText ? (
													<div className={style.item_content_box}>
														<div className={style.item_content_detail}>
															{item?.reportTextDTO?.subReportText}
														</div>
														<Table
															pagination={false}
															columns={item?.reportTextDTO?.tableDataVO?.thead.map(
																(item) => ({
																	...item,
																	width: "10%",
																	align: "center",
																	title: item?.alias,
																	render:(_)=>_==="有"?"✅": _==="-"? "❌" : _ 

																})
															)}
															rowKey="key"
															size="small"
															dataSource={
																item?.reportTextDTO?.tableDataVO?.tbody?.slice(0, 6) ||
																[]
															}
														/>
													</div>
												) : (
													<Nodata description="暂无强关联数据" />
												)}
											</div>
										);
										break;
									// 社区热度
									case "17":
										return (
											<div className={style.box_style} id="itemDom">
												<div className={style.two_title}>社区热度</div>
												{item?.reportTextDTO?.subReportText ? (
													<div className={style.item_content_box}>
														<div className={style.item_content_detail}>
															{item?.reportTextDTO?.subReportText}
														</div>
														<LineCha data={item?.reportTextDTO?.sensitiveInfo} />
													</div>
												) : (
													<Nodata description="暂无强关联数据" />
												)}
											</div>
										);
										break;
									// 社区热点内容
									case "18":
										return (
											<div className={style.box_style} id="itemDom">
												<div className={style.two_title}>社区热点内容</div>
												{item?.reportTextDTO?.subReportText ? (
													<div className={style.item_content_box}>
														<Table
															pagination={false}
															columns={communityColmns}
															rowKey="key"
															size="small"
															dataSource={
																item?.reportTextDTO?.analyzeInfo?.[0]?.dataMap
																	?.communityHotContent || []
															}
														/>
													</div>
												) : (
													<Nodata description="暂无强关联数据" />
												)}
											</div>
										);
										break;
									default:
										break;
								}
							})}
							<div className={style.footer}>
								<div>报&ensp;告&ensp;人：{reportPerson}</div>
								<div>简报日期：{moment(createTime).format("YYYY-MM-DD HH:mm:ss")}</div>
								<div>数据来源：衍策云</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		</>
	);
}
