import * as echarts from "echarts/core";
import {
	TitleComponent,
	TitleComponentOption,
	TooltipComponent,
	TooltipComponentOption,
	GridComponent,
	GridComponentOption,
	LegendComponent,
	LegendComponentOption,
} from "echarts/components";
import { BarChart, BarSeriesOption } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { useEffect, useRef } from "react";
echarts.use([TitleComponent, TooltipComponent, GridComponent, LegendComponent, BarChart, CanvasRenderer]);

type EChartsOption = echarts.ComposeOption<
	TitleComponentOption | TooltipComponentOption | GridComponentOption | LegendComponentOption | BarSeriesOption
>;

type HistogramProps = {
	resize?: any;
	data: any[];
	onClick?: Function;
	colors?: any;
};
let myChart;

const Histogram = (props: HistogramProps) => {
	const chartRef = useRef();
	const { resize, data = [], onClick, colors = [
		"#257ed8",
		"#f08755",
		"#25b07a",
		"#a99dd2",
		"#6196e2",
		"#a9d0fb",
		"#f4a874",
		"#8cdca2",
		"#b4b6b5",
	] } = props;
	useEffect(() => {
		const option = {
			tooltip: {
				trigger: "axis",
				axisPointer: {
					type: "shadow",
				},
			},
			animation: false, // 是否开启动画

			legend: {},
			grid: {
				top: 20,
			},
			xAxis: {
				type: "value",
				boundaryGap: [0, 0.01],
			},
			yAxis: {
				type: "category",
				data: data.map((item) => item.name),
			},
			series: [
				{
					type: "bar",
					data: data.map((item, index) => {
						return { ...item, itemStyle: { color: colors[index] } };
					}),
				},
			],
		};
		myChart = echarts.init(chartRef.current);

		myChart.off("click");
		myChart.on("click", (params) => {
			onClick(params);
		});
		const resizeChart = () => {
			myChart.resize();
		};
		myChart.setOption(option);
		window.addEventListener("resize", resizeChart);
		if (resize || !resize) resizeChart();
		return () => window.removeEventListener("resize", resizeChart);
	}, [props, resize]);

	return <div ref={chartRef} style={{ height: "100%" }}></div>;
};

export default Histogram;
