import * as echarts from "echarts/core";
import { TreemapChart, TreemapSeriesOption } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { useEffect, useRef } from "react";

echarts.use([TreemapChart, CanvasRenderer]);

type EChartsOption = echarts.ComposeOption<TreemapSeriesOption>;

type MapProps = {
	resize?: any;
	data: any[];
	onClick?: Function;
};
const Map = (props: MapProps) => {
	let myChart;
	const chartRef = useRef();
	const { resize, onClick } = props;
	let data = props.data.sort((a, b) => {
		return b.value - a.value;
	});

	/**
	 * 格式化数据单位
	 * @num
	 */
	const handleFormatNumber = (num: string | number) => {
		let sourceNumber: number = Number(num);
		let formatResult: any = sourceNumber;
		if (formatResult < 1000) {
			formatResult = sourceNumber;
		} else if (sourceNumber < 10000) {
			formatResult = (sourceNumber / 1000).toFixed(1) + "k";
		} else if (sourceNumber > 10000) {
			formatResult = (sourceNumber / 10000).toFixed(1) + "w";
		}
		return formatResult;
	};
	useEffect(() => {
		if (myChart !== null && myChart !== "" && myChart !== undefined) {
			myChart.dispose();
		}
		const formatUtil = echarts.format;
		const colors = [
			"#257ed8",
			"#f08755",
			"#25b07a",
			"#a99dd2",
			"#6196e2",
			"#a9d0fb",
			"#f4a874",
			"#8cdca2",
			"#b4b6b5",
		];
		let newData = data.map((item, index) => {
			return { ...item, itemStyle: { color: colors[index] } };
		})
		const option = {
			tooltip: {
				show: true,
				formatter: function (info: any) {
					var value = info.value;
					var treePathInfo = info.treePathInfo;
					var treePath = [];
					for (var i = 1; i < treePathInfo.length; i++) {
						treePath.push(treePathInfo[i].name);
					}
					return [
						'<div class="tooltip-title">' + formatUtil.encodeHTML(treePath.join("/")) + "</div>",
						formatUtil.addCommas(value) + " 条",
					].join("");
				},
			},
			series: [
				{
					name: "",
					nodeClick: "link",
					type: "treemap",
					visibleMin: 300,
					breadcrumb: {
						show: false,
					},
					roam: false,
					label: {
						show: true,
						// formatter: (a) => {
						// 	return a.dataIndex >= 4 ? " " : a.name + "\n\n" + handleFormatNumber(a.value);
						// },
						formatter: (a) => {
							// return a.name + "\n\n" + handleFormatNumber(a.value);
							if (!data || !data.length) return '';
							var medianNumer = Math.floor(data?.length / 2);
							if (a.dataIndex < (medianNumer)) {
								return a.name + "\n\n" + handleFormatNumber(a.value);
							} else {
								return a.name;
							}
						},
					},
					itemStyle: {
						borderColor: "#fff",

					},

					data: newData,
					top: 5,
					left: 0,
					right: 0,
				},
			],
		};

		myChart = echarts.init(chartRef.current);
		myChart.off("click");
		myChart.on("click", (params) => onClick(params));
		const resizeChart = () => {
			myChart.resize();
		};
		if (data.length > 0) myChart.setOption(option);
		window.addEventListener("resize", resizeChart);
		if (resize || !resize) resizeChart();
		return () => window.removeEventListener("resize", resizeChart);
	}, [props.data, resize]);

	return <div ref={chartRef} style={{ height: "100%" }}></div>;
};

export default Map;
