import * as echarts from "echarts/core";
import {
	TitleComponent,
	TitleComponentOption,
	TooltipComponent,
	TooltipComponentOption,
	LegendComponent,
	LegendComponentOption,
} from "echarts/components";
import { GraphChart, GraphSeriesOption } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { useEffect, useRef } from "react";
echarts.use([TitleComponent, TooltipComponent, LegendComponent, GraphChart, CanvasRenderer]);

type EChartsOption = echarts.ComposeOption<
	TitleComponentOption | TooltipComponentOption | LegendComponentOption | GraphSeriesOption
>;

type DiagramChartProps = {
	resize?: any;
	nodes: any[];
	links: any[];
	categories: any[];
	onClick?: Function;
};
let myChart;
const ForceDiagram = (props: DiagramChartProps) => {
	const chartRef = useRef();
	const { resize, onClick } = props;
	const { nodes = [], links = [], categories = [] } = props;
	useEffect(() => {
		if (myChart !== null && myChart !== "" && myChart !== undefined) {
			myChart.dispose();
		}
		// 只有symbolSize大于50的时候显示label
		nodes.forEach(function (node) {
			node.label = {
				// show: node.symbolSize > 50,
				fontSize: node.symbolSize > 50 ? 10 : 8,
				width: node.symbolSize > 50 ? 50 : 30,
				color: node.symbolSize > 30 ? "#fff" : "#257ed8",
			};
		});
		// 只有symbolSize小于50的时候显示tooltip
		// nodes.forEach(function (node) {
		// 	node.tooltip = {
		// 		show: node.symbolSize < 50,
		// 	};
		// });
		const option: EChartsOption = {
			legend: [
				{
					data: categories,
					selectedMode: false, // 图例不可点击
					bottom: 10,
				},
			],
			nodeClick: false,
			tooltip: {},
			series: [
				{
					type: "graph",
					layout: "force",
					symbolSize: 20,
					roam: "move",
					// edgeSymbol: ['circle', 'arrow'],
					edgeSymbolSize: [2, 10],
					stateAnimation: {
						duration: 300,
						easing: "cubicOut",
					},
					colorBy: "series",
					color: ["#db5633", "#f4a874", "#6C9DE2", "#D6D6D6"],
					zoom: 0.8,
					legendHoverLink: true,
					edgeLabel: {
						// normal: {
						//     textStyle: {
						//         fontSize: 12,
						//     },
						//     show: false,
						// }
					},
					force: {
						layoutAnimation: false,
						repulsion: 400, // 节点之间的斥力因子
						edgeLength: [30, 80], // 两个节点之间的距离
						gravity: 0.2,
					},
					draggable: false,
					lineStyle: {
						color: "source",
						curveness: 0.3,
						opacity: 0.5,
						width: 1,
						cap: "round",
					},
					label: {
						show: true,
						formatter: "{b}",
						color: "#fff",
						overflow: "truncate",
						width: 40,
						fontWeight: 700,
						fontSize: 10,
						lineHeight: 16,
					},
					labelLayout: {
						hideOverlap: true,
					},
					scaleLimit: {
						min: 0.4,
						max: 2,
					},
					data: nodes.map(item=>({...item,tooltip:{
						formatter:params=>params.name
					  }})),
					links,
					categories,
				},
			],
		};
		myChart = echarts.init(chartRef.current);
		myChart.off("click");
		myChart.on("click", (params) => {
			if(params?.data?.mediaId===null)return
			if(params?.dataType==="edge") return
			onClick(params)
		});
		const resizeChart = () => {
			myChart.resize();
		};
		myChart.setOption(option);
		window.addEventListener("resize", resizeChart);
		if (resize || !resize) resizeChart();
		return () => window.removeEventListener("resize", resizeChart);
	}, [props, resize]);

	return <div ref={chartRef} style={{ height: "100%" }}></div>;
};

export default ForceDiagram;
