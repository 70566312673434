import { Button, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom'
import TreempCha from "@/components/Charts/TreempChart"
import Template from './PrintTemplate';
import ToPrint from '@/components/ToPrint';
import jsPDF from "jspdf"
import style from "./index.module.less"
import html2canvas from "html2canvas";
import LineCha from "@/components/Charts/LineChart"
import PrintReport from "@/pages/Report/ReportManagement/components/DownloadReport"
import Print from "./Print"
const App = () => {
  const ref = useRef()
  const [ifRame, setIfRame] = useState();

  const handleClick = () => {
    ifRame.contentWindow.focus();
    ifRame.contentWindow.print();
    if (navigator.userAgent.indexOf("MSIE") > 0) {
      document.body.removeChild(ifRame);
    }
  }

  const handleClickDownPDF = () => {
    ifRame.contentWindow.focus();
    const iframeNode = document.getElementById('iframeId').contentWindow.document.documentElement;
    console.log(iframeNode);
    // return 
    if (iframeNode) {
      html2canvas(iframeNode, {
        dpi: 172,//导出pdf清晰度
      }).then(canvas => {
        var contentWidth = canvas.width;
        var contentHeight = canvas.height;
        //一页pdf显示html页面生成的canvas高度;
        var pageHeight = contentWidth / 592.28 * 841.89;
        //未生成pdf的html页面高度
        var leftHeight = contentHeight;
        //pdf页面偏移
        var position = 0;
        //html页面生成的canvas在pdf中图片的宽高（a4纸的尺寸[595.28,841.89]）
        var imgWidth = 595.28;
        var imgHeight = 592.28 / contentWidth * contentHeight;

        var pageData = canvas.toDataURL('image/jpeg', 1.0);
        var pdf = new jsPDF('', 'pt', 'a4');

        //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        //当内容未超过pdf一页显示的范围，无需分页
        if (leftHeight < pageHeight) {
          pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            leftHeight -= pageHeight;
            position -= 841.89;
            //避免添加空白页
            if (leftHeight > 0) {
              pdf.addPage();
            }
          }
        }
        pdf.save('审批办理.pdf');
      })
    }

    // ifRame.contentWindow.focus();
    // ifRame.contentWindow.print();
    // if (navigator.userAgent.indexOf("MSIE") > 0) {
    //   document.body.removeChild(ifRame);
    // }
  }
  // useEffect(() => {
  //   if (ref.current) {

  //     const iframe = document.createElement('IFRAME');
  //     iframe.setAttribute('id', 'iframeId')
  //     iframe.setAttribute('style', 'position:absolute;width:600px;height:600px;left:10px;top:10px;');
  //     ref.current.appendChild(iframe);
  //     // document.body.appendChild(iframe);
  //     let doc = iframe.contentWindow.document;
  //     setIfRame(iframe)
  //     ReactDOM.render(<Template Echarts={
  //       <LineCha id="1" data={{
  //         xaxis: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  //         yaxis: [],
  //         data: [820, 932, 901, 934, 1290, 1330, 1320],
  //       }} />}
  //     />, doc)
  //     doc.close();

  //   }
  // }, [])

  useEffect(() => {

  }, [])
  return (
    <>
      {/* <Button type="primary" onClick={handleClickDownPDF}>
        下载
      </Button> */}
      <ToPrint
        node={
          <Template/>
        }
      />

      <div ref={ref} className={style.printBox} id="printBox"></div>


    </>
  );
};

export default App;