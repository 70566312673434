import { useReducer } from "react"
import { INJECT_ENUM, UPDATE_USER } from "./instance"

const initialState = {
    user: {
        isLogin: localStorage.getItem('isLogin'),
        isTrialAccount: localStorage.getItem('isTrialAccount') || false,
        isPreviewAccount: localStorage.getItem('isPreviewAccount') || false,
        info: {
            entDTO: {
                entLogoUrl: '/images/logo.png',
                entAbbreviation:'',
                stockCode:''
            }
        },
        token: localStorage.getItem('token')
    },
    // 字典
    dict: JSON.parse(localStorage.getItem('dict')),
}

const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case INJECT_ENUM:
            return { ...state, dict: payload }
        case UPDATE_USER:
            return { ...state, user: payload }
        default:
            break;
    }
}

const GlobalStore = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return [state, dispatch]
}

export default GlobalStore