import * as echarts from "echarts/core";
import { GridComponent, GridComponentOption } from "echarts/components";
import { LineChart, LineSeriesOption } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { useEffect, useRef } from "react";

echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);

type EChartsOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption>;

type LineProps = {
	resize?: any;
	xaxis: any[];
	yaxis?: any[];
	data: any[];
	legend?: any[];
	smooth?: boolean;
	myChart?: any
	onClick?: Function;
};

const Line = (props: LineProps) => {

	const chartRef = useRef();
	let { xaxis = [], yaxis = [], data = [], resize, onClick, legend = [], smooth = false, myChart } = props;
	useEffect(() => {
		if (myChart !== null && myChart !== "" && myChart !== undefined) {
			myChart.dispose();
		}

		const option: EChartsOption = {
			legend: {
				show: true,
				data: legend,
				bottom: 10,
			},
			xAxis: {
				type: "category",
				boundaryGap: false,
				data: xaxis,
			},
			tooltip: {
				trigger: "axis",
				textStyle: {
					fontSize: 12
				},
				formatter: (param) => {
					const currentNode = param[0];
					return (
						`<div style="color:#333">
							<div>
							${currentNode?.data?.startTime +'～' + currentNode?.data?.endTime}
							</div>							
							<div style="margin-top:10px;font-weight:700;display:flex;align-items:center;">
							<i style="display:inline-block;width:6px;height:6px;border-radius:50%;background:#db5633;margin-right:10px;"></i>
							${currentNode?.data?.value}</div>
						</div>`
					)
				}
			},
			yAxis: {
				type: "value",
				data: yaxis,
				axisLabel: {
					formatter: function (value, index) {
						var value;
						if (value >= 1000 && value <= 9999) {
							value = value / 1000 + "k";
						} else if (value < 1000) {
							value = value;
						} else if (value >= 10000) {
							value = value / 10000 + "w";
						}
						return value;
					},
				},
			},
			grid: [{

				top: 10,
				bottom: "12%",
				right: "12%",
				left: "12%",
				containLabel: false,
			}],
			series: [{
				data,
				type: 'line'
			}],
		};
		myChart = echarts.init(chartRef.current);
		const resizeChart = () => {
			myChart.resize();
		};
		myChart.off("click");
		myChart.on("click", (params) => onClick(params));
		myChart.setOption(option);
		window.addEventListener("resize", resizeChart);
		if (resize || !resize) resizeChart();
		return () => window.removeEventListener("resize", resizeChart);
	}, [props, resize]);

	return <div ref={chartRef} style={{ height: "100%" }}></div>;
};

export default Line;
