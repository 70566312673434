import * as echarts from "echarts/core";
import {
	TitleComponent,
	TitleComponentOption,
	TooltipComponent,
	TooltipComponentOption,
	GridComponent,
	GridComponentOption,
	LegendComponent,
	LegendComponentOption,
} from "echarts/components";
import { BarChart, BarSeriesOption } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { useEffect, useRef } from "react";
echarts.use([TitleComponent, TooltipComponent, GridComponent, LegendComponent, BarChart, CanvasRenderer]);

type EChartsOption = echarts.ComposeOption<
	TitleComponentOption | TooltipComponentOption | GridComponentOption | LegendComponentOption | BarSeriesOption
>;

type BargramProps = {
	resize?: any;
	data: any[];
	onClick?: Function;
	colors?: any;
};

const Bargram = (props: BargramProps) => {
	let myChart;
	const chartRef = useRef();
	const { resize, data = [], onClick, colors = [] } = props;
	useEffect(() => {
		if (myChart !== null && myChart !== "" && myChart !== undefined) {
			myChart.dispose();
		}
		const option = {
			tooltip: {
				trigger: "axis",
				axisPointer: {
					type: "shadow",
				},
			},
			legend: {},
			grid: {
				top: 20,
				bottom: 20,
				left: 10,
				right: 10,
				containLabel: true,
			},

			xAxis: {
				type: "category",
				boundaryGap: [0, 0.01],
				data: data.map((item) => item.name),
			},
			yAxis: {
				type: "value",
				axisLabel: {
					formatter: function (value, index) {
						var value;
						if (value >= 1000 && value <= 9999) {
							value = value / 1000 + "k";
						} else if (value < 1000) {
							value = value;
						} else if (value >= 10000) {
							value = value / 10000 + "w";
						}
						return value;
					},
				},
			},
			series: [
				{
					type: "bar",
					data: data.map((item, index) => {
						return { ...item, itemStyle: { color: colors[index] },};
					}),
					barWidth:30,
				},
			],
		};
		myChart = echarts.init(chartRef.current);

		myChart.off("click");
		myChart.on("click", (params) => {
			onClick(params);
		});
		const resizeChart = () => {
			myChart.resize();
		};
		myChart.setOption(option);
		window.addEventListener("resize", resizeChart);
		if (resize || !resize) resizeChart();
		return () => window.removeEventListener("resize", resizeChart);
	}, [props, resize]);

	return <div ref={chartRef} style={{ height: "100%" }}></div>;
};

export default Bargram;
