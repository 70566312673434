import React from "react";
import style from "./index.module.less";
import { Form, Select } from "antd";
export default function App(props) {
  const { options, defaultValue,data,setData,newData ,mode,placeholder} = props;

  return (
    <div>
      <Form.Item initialValue={defaultValue}>
        <Select
          dropdownClassName={style.content_select}
          className={style.select}
          mode={mode}
          getPopupContainer={triggerNode => triggerNode.parentElement}  
          options={options}
          onChange={(value) => {
            setData(value)
          }}
          defaultValue={defaultValue}
          placeholder={placeholder}
        />
      </Form.Item>
    </div>
  );
}
