import * as echarts from "echarts/core";
import {
	GridComponentOption,
	MarkPointComponent,
	TitleComponent,
	ToolboxComponent,
	TooltipComponent,
	GridComponent,
	VisualMapComponent,
	MarkAreaComponent,
	LegendComponent,
	DataZoomComponent,
} from "echarts/components";
import { LineChart, LineSeriesOption } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { GraphicComponent } from "echarts/components";
import { useEffect, useRef } from "react";

echarts.use([
	GridComponent,
	LineChart,
	CanvasRenderer,
	UniversalTransition,
	MarkPointComponent,
	TitleComponent,
	ToolboxComponent,
	TooltipComponent,
	GraphicComponent,
	VisualMapComponent,
	MarkAreaComponent,
	LegendComponent,
	DataZoomComponent,
]);

type EChartsOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption>;

type LineProps = {
	resize?: any;
	xaxis: any[];
	yaxis?: any[];
	data: any[];
	newData?: any[];
	onClick?: Function;
	value?: any;
	mediaKindData?: any;
	datashiduan?: any;
	flagData?: any;
	isToolTip?:boolean
};
let myChart;

const Line = (props: LineProps) => {
	const chartRef = useRef();
	const { xaxis = [], yaxis = [], data = [], datashiduan = [], resize, onClick, mediaKindData, flagData =[],isToolTip= true} = props;
	let arr = [];
	let newFlagData = [];
	const arrHandler = () => {
		datashiduan?.map((item) => {
			arr.push([
				{
					name: item?.periodName,
					xAxis: item?.xAxisStart,
					itemStyle: {
						color: item?.itemStyle?.color,
					},
				},
				{
					xAxis: item?.xAxisEnd,
				},
			]);
			return arr;
		});
	};
	// 插旗的第一个数据如果是第一天不展示
	const flagDataHandler = () => {
		flagData?.map((item, index) => {
			if (item?.xAxis === xaxis[0]) return;
			newFlagData.push(item);
		});
	};
	useEffect(() => {
		// if (myChart !== null && myChart !== "" && myChart !== undefined) {
		// 	myChart.dispose();
		// }
		arrHandler();
		flagDataHandler();
		const option: EChartsOption = {
			// legend: {
			//   data: data?.map((item) => {
			//     return item?.name;
			//   }),
			//   x: "center",
			//   y: "bottom",
			// },
			// 提示框
			tooltip: {
				borderWidth: 0,
				padding: 0,
				show:isToolTip,
				formatter: (params) => {
					const { data } = params;
					let content;
					if (params?.componentType === "markPoint") {
						content = `
							<div style="
								width:375px;
								padding:10px 20px 5px 20px;
								box-shadow: 0px 6px 15px 0px rgba(84,84,84,0.65);
								border-radius: 11px;
								background: #FFFFFF;
								font-size: 13px;
								font-family: Noto Sans S Chinese;
								display:flex;
								flex-direction: column;
								justify-content: space-around;
							">
								<div style="
									display:flex;
									justify-content: space-between;
									margin:4px 0px;
								">
									<div style="
										color:#808080;
										text-align:center;
									">
										${data?.xAxis}
									</div>
									<div style="
										color:#fff;
										background:#db5633;
										border-radius: 4px;
										padding:0 4px;
									">
										发布媒体：<span style="color:#fff;">${data?.mediaName}</span>
									</div>
								</div>
								<div style="color:#0085D6;overflow:hidden;text-overflow:ellipsis;margin:4px 0px;">
									● 关键词：<span style="color:#777;m">${data?.keywords === null ? "" : data?.keywords}</span>
								</div>
								<div style="border-top:1px solid rgba(185,201,255,0.98)";></div>
								<div style="color:#777;margin:4px 0px;white-space:normal;">《${data?.articleTitle}》</div>
            				</div>
            `;
					} else if (params?.componentType === "markArea") {
						content = undefined;
					} else if (params?.componentType === "series" && data?.value !== 0) {
						content = `
							<div style="
								width:320px;
								height:90px;
								padding:10px 20px 5px 20px;
								box-shadow: 0px 6px 15px 0px rgba(84,84,84,0.65);
								border-radius: 11px;
								background: #FFFFFF;
								font-size: 13px;
								font-family: Noto Sans S Chinese;
								display:flex;
								flex-direction: column;
								justify-content: space-around;
							">
								<div style="
									color:#808080;
									background: #d1d1d160;
									text-align:center;
									background: #D1D1D160;
									border-radius: 0px 11px 11px 0px;
									width:120px;
								">${data?.date}</div>
								<div style="
									color:#0085D6;
								">
									● 总声量：${data?.value}
								</div>
								<div style="
									color:#DB5633;
									overflow:hidden;
									text-overflow:ellipsis;
								">
									● 关键词：${data?.keywords}
								</div>
            				</div>
           				 `;
					}

					return content;
				},
			},
			xAxis: {
				type: "category",
				data: xaxis,
				show: true,
				boundaryGap: false,
			},
			yAxis: {
				type: "value",
				data: yaxis,
				show: true,
				max: ()=>{
					if(flagData?.length){
						return Math.floor(flagData?.[0]?.yAxis * 1.1)
					}
				},
				axisLabel: {
					formatter: function (value, index) {
						// var value;
						if (value >= 1000 && value <= 9999) {
							value = value / 1000 + "k";
						} else if (value < 1000) {
							value = value;
						} else if (value >= 10000) {
							value = value / 10000 + "w";
						} 
						return value;
					},
				},
			},
			// 拖动条
			dataZoom: [
				{
					type: "slider",
					show: true,
					start: 0,
					end: 100,
					height: 20,
					realtime: true,
					bottom: 15,
					backgroundColor: "#2352b2",
				},
			],
			grid: {
				// show:false
				left: "1%",
				right: "2%",
				top: 20,
				bottom: 40,
				containLabel: true,
			},
			series: [
				{
					name: "Electricity",
					type: "line",
					data: data,
					// color: "#fff",
					markArea: {
						// data: arr,
						// silent: false,
						// data: [
						//   [
						//     {
						//       name: '潜伏期',
						//       xAxis: '07.19',
						//       itemStyle:{
						//           color:{
						//             type: 'linear',
						//             x: 1,
						//             y: 0,
						//             x2: 0,
						//             y2: 0,
						//             colorStops: [
						//               {offset:1, color: 'rgba(90,73,155,0.22)'},
						//               {offset: 0, color: 'rgba(90,73,155,0.90)'}],
						//             global: false
						//            },
						//         }

						//     },
						//     {
						//       xAxis: '07.25'
						//     }
						//   ],
						//   [
						//     {
						//       name: '爆发期',
						//       xAxis: '07.25',
						//        itemStyle:{
						//            color:{
						//             type: 'linear',
						//             x: 1,
						//             y: 0,
						//             x2: 0,
						//             y2: 0,
						//             colorStops: [
						//               {offset:1, color: 'rgba(90,73,155,0.90)'},
						//               {offset: 0, color: 'rgba(90,73,155,0.90)'}],
						//             global: false
						//            },
						//         }
						//     },
						//     {
						//       xAxis: '07.30'
						//     }
						//   ],
						//   [
						//     {
						//       name: '消退期',
						//       xAxis: "07.30",
						//       itemStyle:{
						//            color:{
						//             type: 'linear',
						//             x: 1,
						//             y: 0,
						//             x2: 0,
						//             y2: 0,
						//             colorStops: [
						//               {offset:1, color: 'rgba(90,73,155,0.90)'},
						//               {offset: 0, color: 'rgba(90,73,155,0.22)'}],
						//             global: false
						//            },
						//         }
						//     },
						//     {
						//       xAxis: "08.24"
						//     }
						//   ]
						// ]
					},
					// 标注
					markPoint: {
						symbolSize: 20, //标注大小
						symbol: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAxCAYAAACyGwuwAAAACXBIWXMAABcRAAAXEQHKJvM/AAACRUlEQVRYw+2Yv0sbYRyHnwvJ0JJe0utgJIg3SHXRZAiEbCFIhzq0gotL7XCrUNQ/oP9BxUEc9C9QaDrYSY5zURCHi2BHiVDxBxhCEuwQ8e3QSxrzO2k0Qt8PvNzd+94dDy9fju9zihAiCbzjfr4B7wHdOcYBP7WxnWEBaXoR0TiW6CxpIcSyECIshKDboQghBMB3RQHg7Z/Lcq5Mk+u9PXKp1L15j9+POjGBFouhjo3h9norl0+Bz0ASyHayQTVAb/J53F4vV6bJj8VFbmy7rRcFDIOBqSkGJif/wgmRQ1G+OmBWE7iwUxpWDVDAMLg5OSFnml2VgCcYJDA7y8jCAs8GB6uXT+vUWhjwOee7NUC9jJpIMDQ3hxaN8mJ0tJ1HHhaoOq9mZmrmipkMHk0jurkJsOvmEXO9tdUS1MUTiwSSQBJIAkkgCSSBJND/DtSyY/QEg6ixGGoohMfnK8/njo4oZrMNu8CeAw0tLaEbRlvNeebwkMz+PhfJZNe20hBITSQIra5Wg6QcZS4pjN/RFx0Y1iIRtEiEkfl5fp2fk97Y4OfaGsWzs46BGoqiI3lfgOUW9ukv+78Q0yiKCnBbKHC5s8Pl9jYX6+stbaRkHc1U+mWnGlwB9wkIlSZvCwUyBwfkjo/LtQfwXNdRx8crva0p0L+KWtgBiwPDLX963N3lFZfrw0N6mQ18rICLOzUXrrrPAmzF5bKA7GOJYuk/kvxSSyAJJIEkkASSQH1r8l+vrDwJoHKDVm+tXzs0Xadpsvu1Q78BygJL7ZgSLVAAAAAASUVORK5CYII=",
						emphasis: {
							label: {
								show: false,
								// position: 'inside'  // 'left'|'right'|'top'|'bottom'
								// textStyle: null     // 默认使用全局文本样式，详见TEXTSTYLE
							},
						},
						data: newFlagData,
					},
				},
			],
		};
		myChart = echarts.init(chartRef.current);
		const resizeChart = () => {
			myChart.resize();
		};
		myChart.off("click");
		myChart.on("click", (params) => {
			console.log(params, "params");
			if (params?.componentType === "series") {
				onClick(params, "volume");
			} else if (params?.componentType === "markPoint") {
				console.log(params, "params");
				onClick(params, "openUrl");
			}
		});
		myChart.setOption(option);
		window.addEventListener("resize", resizeChart);
		if (resize || !resize) resizeChart();
		return () => window.removeEventListener("resize", resizeChart);
	}, [props, resize]);

	return <div ref={chartRef} style={{ height: "100%", zIndex: 9 }}></div>;
};

export default Line;
