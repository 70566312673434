import { Input, message } from "antd";
import PubSub from "pubsub-js";
import { Link } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import style from "./index.module.less";
import { GlobalContext } from "@/GlobalContext";
import { useContext } from "react";

const { Search } = Input;

const HeaderSearch: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	/**
	 * 更新依赖值触发搜索
	 * @param searchText
	 */
	const handleSearch = (searchText) => {
		if (!searchText.trim()) {
			message.error("搜索内容不能为空");
			return;
		}
		if (location.pathname.indexOf("search") === -1) {
			navigate(`/search/?k=${searchText}`);
		} else {
			PubSub.publish("SEARCH", searchText);
		}
	};
	// 权限
	const {
		state: { user },
	} = useContext(GlobalContext);
	const currentUser = user?.info?.permissionName;

	useEffect(() => {}, []);

	return (
		<div className={style.headerSearch}>
			<Link className={style.hot} to="/hot">
				<div className={style.icon}>
					<img src="/images/hot.gif" alt="" style={{ height: 40 }} />
				</div>
				<div className={style.text}>热榜</div>
			</Link>
			<div className={style.search}>
				<Search
					placeholder="请输入关键词，按逗号分词"
					onSearch={handleSearch}
					enterButton={<SearchOutlined />}
					className={style.headerSearch}
				/>
			</div>
			{/* <div
				className={style.hot}
				onClick={() => window.open("http://xzx.yancecloud.com")}
				style={{ marginLeft: 20, cursor: "pointer" }}
			>
				<div className={style.icon}>
					<img src="/favicon.ico" alt="" style={{ height: 26 }} />
				</div>
				<div className={style.text}>写作芯</div>
			</div> */}
			{/* {currentUser === "ON_TRIAL" || currentUser === "EXPERIENCE" ? null : (
				<div
					className={style.hot}
					onClick={() => window.open("http://120.53.7.153/home")}
					style={{ marginLeft: 20, cursor: "pointer" }}
				>
					<div className={style.icon}>
						<img src="/images/wenrenlogo.png" alt="" style={{ height: 26 }} />
					</div>
					<div className={style.text}>职业数据查询</div>
				</div>
			)} */}
		</div>
	);
};

export default HeaderSearch;
