import request from "@/service/request"

// 获取监测方案
export function selectAllMonitorList() {
    return request({
        url: '/analyze/monitorInfo/selectAllMonitorList',
        method: 'get',
    })
}

// 新增简报
export function insertYcReportInfo(params:any) {
    return request({
        url: '/analyze/report-rule/universalReportAdd',
        method: 'post',
        data:params
    })
}

// 新增定时简报
export function insertYcReportInfoTiming(params:any) {
    return request({
        url: '/analyze/report-rule/timingReportAdd',
        method: 'post',
        data:params
    })
}

// 隐藏恢复简报内容
export function updateHideReport(params) {
    const {reportId,reportStatus}=params
    return request({
        url: '/analyze/report-content/updateReportHide',
        method: 'post',
        data:params
    })
}

// 收藏
export function addCollection(params: { collectId: string, collectType: string}) {
    return request({
        url: '/account/collect/insertYcCollectInfo',
        method: 'post',
        data: params
    })
}

// 条件查询简报列表
export function selectYcReportInfoList(params: { reportClass?: string, reportType?: string ,reportName?:string,reportStatus?:string,page:any,size:any}) {
    return request({
        url: `/analyze/report-content/conditionSearchReportList`,
        method: 'post',
        data:params
    })
}

// 新增简报草稿
export function insertYcReportInfoDraft(params:any) {
    return request({
        url: `/analyze/report-rule/draftReportAdd`,
        method: 'post',
        data: params
    })
}

// 获取监测方案共享用户
export function getMonitorUser(monitorInfoId:string) {
    return request({
        url: `/analyze/monitorInfo/getMonitorUser?monitorInfoId=${monitorInfoId}`,
        method: 'get',
        // params: { monitorInfoId},
    })
}

// 删除简报
export function deleteYcReportInfoByReportId(reportId:string) {
    return request({
        url: `/analyze/report-content/deleteById`,
        method: 'get',
        params: { reportId },
    })
}

// 分享简报
export function shareReportInfo(params:any) {
    return request({
        url: `/analyze/report-content/shareReportInfo`,
        method: 'post',
        data: params,
    })
}

// 根据简报ID查询简报内容
export function selectYcReportInfoByReportId(params:any) {
    return request({
        url: `/analyze/report-content/queryById`,
        method: 'get',
        params:{ reportId:params},
    })
}

// 查询用户简报模板
export function selectYcReportTemplateInfoByUserName() {
    return request({
        url: `analyze/report-template/selectYcReportTemplateInfoByUserName`,
        method: 'get',
    })
}

// 分享简报
export function getShareReportInfo() {
    return request({
        url: `/analyze/report/getShareReportInfo`,
        method: 'get',
    })
}

// 修改简报详情数据
export function updateYcReportInfo(params:any) {
    return request({
        url: '/analyze/report-content/update',
        method: 'post',
        data:params
    })
}

// 获取定时规则列表
export function selectRuleTimingListByUser(params) {
    return request({
        url: `/analyze/report-rule/selectRuleTimingListByUser`,
        method: 'get',
        params:{page:params.page,size:params.size}
    })
}

// 获取草稿箱列表
export function selectRuleDraftListByUser(params) {
    return request({
        url: `/analyze/report-rule/selectRuleDraftListByUser`,
        method: 'get',
        params:{page:params.page,size:params.size}
    })
}

// 删除简报规则数据
export function deleteById(reportRuleId:any) {
    return request({
        url: '/analyze/report-rule/deleteById',
        method: 'post',
        params:{reportRuleId}
    })
}

// 根据简报规则id查询规则内容
export function queryById(params) {
    return request({
        url: `/analyze/report-rule/queryById`,
        method: 'get',
        params,
    })
}

// 修改定时简报规则数据
export function updateTimingReport(params) {
    return request({
        url: '/analyze/report-rule/updateTimingReport',
        method: 'post',
        data:params,
    })
}

// 获取分享简报内容
export function getshare(params) {
    return request({
        url: `/analyze/report-content/get-share`,
        method: 'post',
        data:{shareKey:params}
    })
}

// 修改草稿规则数据
export function updateDraftRule(params) {
    return request({
        url: `/analyze/report-rule/updateDraftRule`,
        method: 'post',
        data:params
    })
}

// 查询企业轮询用户列表
export function getEntWechatList() {
    return request({
        url: '/base/contact/getEntWechatList',
        method: 'get',
    })
}

// 获取新建简报二维码
export function getWechatQrCode() {
    return request({
        url: '/base/contact/getWechatQrCode',
        method: 'get',
    })
}

// 查询简报简报模板
export function queryTemplateList() {
    return request({
        url: '/analyze/report-template/queryTemplateList',
        method: 'get',
    })
}

// 简报下载后保存到资源库
export function saveReportResource(params) {
    return request({
        url: '/analyze/report-content/saveReportResource',
        method: 'post',
        data:params
    })
}

// 删除模板
export function deleteYcReportTemplateInfoById(params) {
    return request({
        url: '/analyze/report-template/deleteYcReportTemplateInfoById',
        method: 'get',
        params
    })
}

// 根据模板名称模糊查询模板
export function selectYcReportTemplateInfoList(params:any) {
    return request({
        url: '/analyze/report-template/selectYcReportTemplateInfoList',
        method: 'get',
        params
    })
}


