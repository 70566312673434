import React, {useContext, useState} from "react";
import {Button, Form, Input, message, Modal, Radio, Upload, UploadProps} from "antd";
import {debounce} from "@/utils/util";
import {createWork, uploadImgResource} from "@/pages/User/service";
import style from "@/pages/User/UserProfile/components/UserWork/index.module.less";
import {PlusOutlined} from "@ant-design/icons";
import {GlobalContext} from "@/GlobalContext";

export default function Consultation({consultationVisible, setConsultationVisible, ...props}) {
  const [form] = Form.useForm();
  const {
    state: {dict},
  } = useContext(GlobalContext);
  // 操作类型
  const [actionType, setActionType] = useState("呼叫服务");

  // 新建工单图片列表
  const [fileList, setFileList] = useState([]);

  const handleCancel = () => {
    setConsultationVisible(false);
  };

  const onFinish = debounce(300, async (values) => {
    const params = {
      ...values,
      imageUrl: fileList.map((item) => item.url),
    };
    try {
      const response = await createWork(params);
      const {data, msg, code} = response.data;
      if (code === 200) {
        setConsultationVisible(false);
        message.success(msg);
      } else {
        message.error(msg);
      }
    } catch (error) {
    }
  });
  /**
   * 新建工单
   */
    // 新建工单上传props配置
  const UpLoadprops: UploadProps = {
      name: "file",
      listType: "picture-card",
      fileList: fileList,
      beforeUpload: (file) => {
        return false;
      },
      onRemove(file) {
        const newList = fileList.filter((item) => item.uid !== file.uid);
        setFileList(newList);
      },
      async onChange(info) {
        if (info.file.lastModifiedDate) {
          const formData = new FormData();
          formData.append("file", info.file as any);
          const result = await handleUpload(formData);
        }
      },
    };
  // 处理新建工单上传
  const handleUpload = async (params) => {
    try {
      const response = await uploadImgResource(params);
      const {msg, data, code} = response.data;
      if (code === 200) {
        const newFile = {name: "", url: data, uid: data};
        setFileList([...fileList, newFile]);
        return true;
      } else {
        message.error(msg);
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  // 新建工单Button
  const uploadButton = (
    <div className={style.uploadButton}>
      <PlusOutlined/>
      <div style={{marginTop: 8}}>点击上传</div>
    </div>
  );

  return (
    <Modal title={actionType} visible={consultationVisible} onCancel={handleCancel} centered footer={false}>
      <Form
        name="basic"
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label="业务类型"
          name="problemType"
          rules={[{required: true, message: "请选择业务类型!"}]}
          initialValue="0"
        >
          <Radio.Group options={dict?.workQuestionTypeEnum} defaultValue="0"></Radio.Group>
        </Form.Item>
        <Form.Item
          label="业务描述"
          name="problemDescribe"
          rules={[{required: true, message: "请输入您的业务描述!"}]}
        >
          <Input.TextArea placeholder="请输入业务描述，以便于我们更好的协助您解决问题"/>
        </Form.Item>
        <Form.Item label="上传图片">
          <Upload {...UpLoadprops} style={{marginLeft: 10}}>
            {fileList.length >= 4 ? null : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item
          label="联系方式"
          name="contactDetails"
          rules={[{required: false, message: "请输入联系方式!"}]}
        >
          <Input placeholder="您可以输入手机号或邮箱便于联系"/>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" size="small">
            提交
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
