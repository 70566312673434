import request from "@/service/request"
import type { AnalyseParams, deleteArticleParams, ShareLinkParams, MonitorUserParams, ClusterParams, listParams, monitorParams, lineParams, editLineParams, createLinkParams } from "../ts/type"



// 创建监测方案项
export function createMonitor(params: monitorParams) {
    return request({
        url: '/analyze/monitorInfo/event/add',
        method: 'post',
        data: params
    })
}

// 新增Group
export function addMonitorGroup(params: lineParams) {
    return request({
        url: '/analyze/monitor-folder/event/add',
        method: 'post',
        data: params
    })
}

// 获取下拉框路径list-查询通用文件夹列表路径
export function getMonitorSelect() {
    return request({
        url: '/analyze/monitor-folder/event/get',
        method: 'get',
    })
}

// 获取Group Monitor
export function getMonitorAll() {
    return request({
        url: '/analyze/monitorInfo/event/list',
        method: 'get',
    })
}

// 删除Group
export function delteMonitorGroup(id: string | number) {
    return request({
        url: `/analyze/monitor-folder/${id}`,
        method: 'delete',
    })
}

// 删除Monitor
export function delteMonitor(id: string | number) {
    return request({
        url: `/analyze/monitorInfo/${id}`,
        method: 'delete',
    })
}

// 编辑保存Group
export function updateEditGroup(params: editLineParams) {
    return request({
        url: `/analyze/monitor-folder/edit`,
        method: 'put',
        data: params
    })
}

// 生成分享链接 监测方案
export function createLink(params: createLinkParams) {
    return request({
        url: `/analyze/monitorInfo/create-share`,
        method: 'post',
        data: params
    })
}

// 获取可共享用户list
export function getCustomerList() {
    return request({
        url: `/account/customer/getCustomerList`,
        method: 'get',
    })
}

export function getMonitorInfo(id: string) {
    return request({
        url: `/analyze/monitorInfo/${id}`,
        method: 'get',
    })
}

// 获取文章列表
export function getMonitorList(params: listParams) {
    return request({
        url: `/analyze/article/monitor/getList`,
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

// 获取文章详情
export function getMonitorArt(id: string, monitorInfoId?: number) {
    return request({
        url: `/recommend/article/get`,
        method: 'get',
        params: { articleId: id, monitorInfoId },
    })
}
// 更新文章态度
export function updateFeel(params: { monitorInfoId: number, articleIdList: string[], attitudeFeelType: string }) {
    return request({
        url: `/analyze/article/updateFeel`,
        method: 'post',
        data: params,
    })
}



// 分享文章 生成分享链接
export function createArtShareLink(params: ShareLinkParams) {
    return request({
        url: `/recommend/article/create-share`,
        method: 'post',
        data: params,
    })
}

// 获取文章相关推荐
export function getRecommend(id: string) {
    return request({
        url: `/recommend/article/getRecommend`,
        method: 'get',
        data: { articleId: id },
    })
}

// 获取合并文章
export function getClusterList(params: ClusterParams) {
    return request({
        url: `/analyze/article/monitor/getClusterList`,
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

// 获取统计图数据
export function getCountData(params: listParams) {
    return request({
        url: `/analyze/article/monitor/getCountData`,
        method: 'post',
        data: params,
    })
}
// 获取媒体数据
export function getCountByMedia(params: listParams) {
    return request({
        url: `/analyze/article/monitor/getCountByMedia`,
        method: 'post',
        data: params,
    })
}

// 获取描述转词语推荐
export function getDescSplit(content: string) {
    return request({
        url: `/analyze/hadoop/shortestParticiple`,
        method: 'post',
        data: { content },
    })
}

// 创建监测方案项
export function updateMonitor(params: monitorParams) {
    return request({
        url: '/analyze/monitorInfo/event/edit',
        method: 'put',
        data: params
    })
}

// 删除文章
export function deleteArticle(params: deleteArticleParams) {
    return request({
        url: `/analyze/article/delete`,
        method: 'post',
        data: params,
    })
}

// 获取描述转词语推荐
export function getRecommendSplit(content: string) {
    return request({
        url: `/analyze/hadoop/associateRecommend`,
        method: 'post',
        data: { content },
    })
}

// 获取监测方案共享用户
export function getMonitorUser(monitorInfoId: number) {
    return request({
        url: `/analyze/monitorInfo/getMonitorUser`,
        method: 'get',
        params: { monitorInfoId },
    })
}

// 修改监测方案共享用户
export function updateMonitorUser(params: MonitorUserParams) {
    return request({
        url: `/analyze/monitorInfo/updateMonitorUser`,
        method: 'post',
        data: params,
    })
}


// 获取对比数据
export function selectEventContrasByMonitor(data: string[]) {
    return request({
        url: `analyze/monitorInfo/selectEventContrasByMonitor`,
        method: 'post',
        data,
    })
}

// 事件分析接口
// 获取核心媒体态度
export function getCoreMediaEmotion(params: { monitorId: string }) {
    return request({
        url: `/analyze/eventAnalyse/getCoreMediaEmotion`,
        method: 'get',
        params,
    })
}

// 获取媒体声量分布
export function getMediaVolume(params: { monitorId: string }) {
    return request({
        url: `/analyze/eventAnalyse/getMediaVolume`,
        method: 'get',
        params,
    })
}
// 重要态度传播路径
export function getEmotionPath(params: { monitorInfoId: number }) {
    return request({
        url: `/analyze/eventAnalyse/getEmotionPath`,
        method: 'post',
        data: params,
    })
}

// 财经媒体声量传播趋势
export function getFinanceVolumeTrend(params: { monitorId: string }) {
    return request({
        url: `/analyze/eventAnalyse/getFinanceVolumeTrend`,
        method: 'get',
        params,
    })
}

// 社区热度折线
export function getCommunityHotLine(params: { monitorId: string }) {
    return request({
        url: `/analyze/eventAnalyse/getCommunityHotInfo`,
        method: 'get',
        params,
    })
}
// 社区热度Table内容
export function getCommunityHotspot(params: { monitorId: string }) {
    return request({
        url: `/analyze/eventAnalyse/getCommunityHotContentInfo`,
        method: 'get',
        params,
    })
}
// 监管态度
export function getSupervisionEmotion(params: { monitorId: string }) {
    return request({
        url: `/analyze/eventAnalyse/getSupervisionEmotion`,
        method: 'get',
        params,
    })
}

// 重要首发态度
export function getFirstEmotion(params: { monitorId: string }) {
    return request({
        url: `/analyze/eventAnalyse/getFirstEmotion`,
        method: 'get',
        params,
    })
}

// 事件监测方案列表
export function selectEventMonitor() {
    return request({
        url: `/analyze/monitorInfo/selectEventMonitor`,
        method: 'get',
    })
}

// 关键转折点top5
export function getTurningPointTopByMonitor(params: { monitorId: string }) {
    return request({
        url: `/analyze/eventAnalyse/getTurningPointTopByMonitor`,
        method: 'get',
        params,
    })
}
// 关键态度趋势
export function getSensitiveInfoTrend(params: { monitorId: string }) {
    return request({
        url: `/analyze/eventAnalyse/getSensitiveInfoTrend`,
        method: 'get',
        params,
    })
}
// 关键态度趋势
export function getHighAttitudeKeywords(params: { monitorId: string }) {
    return request({
        url: `/analyze/eventAnalyse/attitudeKeywords`,
        method: 'get',
        params,
    })
}

// 观点关键词分布
export function getMonitorHighKeyword(params: { monitorId: string, monitorTimeList: any[] }) {
    return request({
        url: `/analyze/eventAnalyse/getMonitorHighKeyword`,
        method: 'post',
        data: params,
    })
}

// 活跃媒体
export function getActiveMedia(params: { monitorId: string }) {
    return request({
        url: `/analyze/eventAnalyse/getActiveMedia`,
        method: 'get',
        params,
    })
}


// 预览
export function getPreview(params: monitorParams) {
    return request({
        url: `/analyze/monitorInfo/preview`,
        method: 'post',
        data: params,
    })
}

// 预览
export function getArticleList(params: AnalyseParams) {
    return request({
        url: `/analyze/eventAnalyse/eventSearchArticle`,
        method: 'post',
        data: params,
    })
}

// 文字分析
export function eventAnalysisText(params: { monitorId }) {
    return request({
        url: `/analyze/eventAnalyse/eventAnalysisText`,
        method: 'get',
        params: params,
    })
}

// 获取媒体态度变化
export function mediaAttitudeChange(params: { monitorId }) {
    return request({
        url: `/analyze/eventAnalyse/mediaAttitudeChange`,
        method: 'get',
        params: params,
    })
}

// 获取媒体报道变化
export function getMediaReportChangeInfo(params: { monitorId }) {
    return request({
        url: `/analyze/eventAnalyse/getMediaReportChangeInfo`,
        method: 'get',
        params: params,
    })
}

// 记者态度&全文情感态度
// GET /analyze/eventAnalyse/reporterAttitude
export function getReporterAttitude(params: { monitorId }) {
    return request({
        url: `/analyze/eventAnalyse/reporterAttitude`,
        method: 'get',
        params: params,
    })
}

// 模型支持
export function getEntModel() {
    return request({
        url: `/account/ent/entModel`,
        method: 'get',
    })
}
