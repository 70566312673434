import { Tag } from "antd";
import style from "./index.module.less";

// 社区热点内容
export const communityColmns:any = [
	{
		title: "观点",
		ellipsis: true,
		dataIndex: "articleTitle",
		key: "articleTitle",
		width: "50%",
	},
	{
		title: "态度",
		align: "center",
		dataIndex: "fullTextSentiment",
		key: "fullTextSentiment",
		ellipsis: true,
		render: (_, record) => {
			return (
				<>
					{record?.fullTextSentiment === "0" && (
						<span className={style.sentimentZx}>
							<Tag>中性</Tag>
						</span>
					)}
					{record?.fullTextSentiment === "1" && (
						<span className={style.sentimentZc}>
							<Tag>赞成</Tag>
						</span>
					)}
					{record?.fullTextSentiment === "-1" && (
						<span className={style.sentimentP}>
							<Tag>批评</Tag>
						</span>
					)}
				</>
			);
		},
	},
	{
		title: "相似文章数",
		align: "center",
		width: "100px",
		dataIndex: "contentClustersCount",
		key: "contentClustersCount",
		ellipsis: true,
	},
	{
		title: "时间",
		align: "center",
		width: "30%",
		dataIndex: "publishTime",
		key: "publishTime",
		ellipsis: true,
	},
];

// 关键词转折点
export const transitionColumns :any= [
	{
		title: "观点",
		ellipsis: true,
		dataIndex: "articleTitle",
		key: "articleTitle",
	},
	{
		title: "媒体名称",
		align: "center",
		width: "100px",
		dataIndex: "mediaName",
		key: "mediaName",
		ellipsis: true,
	},
	{
		title: "时间",
		align: "center",
		width: "170px",
		dataIndex: "publishTime",
		key: "publishTime",
		ellipsis: true,
	},
	{
		title: "态度类型",
		align: "center",
		width: "100px",
		ellipsis: true,
		dataIndex: "fullTextSentiment",
		key: "fullTextSentiment",
		render: (_, record) => {
			return (
				<>
					{record?.fullTextSentiment === "0" && (
						<span className={style.sentimentZx}>
							<Tag>中性</Tag>
						</span>
					)}
					{record?.fullTextSentiment === "1" && (
						<span className={style.sentimentZc}>
							<Tag>赞成</Tag>
						</span>
					)}
					{record?.fullTextSentiment === "-1" && (
						<span className={style.sentimentP}>
							<Tag>批评</Tag>
						</span>
					)}
				</>
			);
		},
	},
];

// 重要首发态度
export const attitudeColumns :any= [
	{
		title: "观点",
		ellipsis: true,
		dataIndex: "articleTitle",
		key: "articleTitle",
	},
	{
		title: "媒体名称",
		width: "100px",
		align: "center",
		dataIndex: "mediaName",
		key: "mediaName",
		ellipsis: true,
	},
	{
		title: "时间",
		align: "center",
		width: "170px",
		dataIndex: "publishTime",
		key: "publishTime",
		ellipsis: true,
	},
	{
		title: "态度类型",
		align: "center",
		width: "100px",
		ellipsis: true,
		dataIndex: "fullTextSentiment",
		key: "fullTextSentiment",
		render: (_, record) => {
			return (
				<>
					{record?.fullTextSentiment === "0" && (
						<span className={style.sentimentZx}>
							<Tag>中性</Tag>
						</span>
					)}
					{record?.fullTextSentiment === "1" && (
						<span className={style.sentimentZc}>
							<Tag>赞成</Tag>
						</span>
					)}
					{record?.fullTextSentiment === "-1" && (
						<span className={style.sentimentP}>
							<Tag>批评</Tag>
						</span>
					)}
				</>
			);
		},
	},
];
