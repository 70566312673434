import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Navigate } from "react-router";
import BasicLayout from "@/layout/BaseLayout";
import NotFound from "@/pages/NoFound";
import Login from "@/pages/User/Login";
import Loading from "@/components/Loading";
import Workbench from "@/pages/Workbench";
import Writing from "@/pages/Creation/Writing";
import CustomDatePicker from "@/components/CustoDatePicker";
import Error from "@/pages/ServiceError";
import type { RouteType } from "./data";
import Drag from "@/pages/Event/pages/Drag";
import CeShiPrint from "@/pages/CeShiPrint/index";
// 监测
const MonitorPanel = lazy(() => import("@/pages/Monitor/pages/MonitorPanel"));
const CreateMonitor = lazy(() => import("@/pages/Monitor/pages/AddEditMonitor"));
// 事件
const EventPanel = lazy(() => import("@/pages/Event/pages/EventPanel"));
const EventCases = lazy(() => import("@/pages/Event/pages/EventCases"));
const AddEditEvent = lazy(() => import("@/pages/Event/pages/AddEditEvent"));
const EventAnalysis = lazy(() => import("@/pages/Event/pages/EventAnalysis"));

const Probation = lazy(() => import("@/pages/User/Probation"));
const Search = lazy(() => import("@/pages/Search"));

const Outline = lazy(() => import("@/pages/Creation/Outline"));
const MyDocument = lazy(() => import("@/pages/Creation/Document"));
const OutlineList = lazy(() => import("@/pages/Creation/OutlineList"));

const Hot = lazy(() => import("@/pages/Hot"));
const Oriented = lazy(() => import("@/pages/Oriented"));
const Kol = lazy(() => import("@/pages/Kol"));
const Media = lazy(() => import("@/pages/Media"));
const Reporter = lazy(() => import("@/pages/Reporter"));
const Portrait = lazy(() => import("@/pages/Reporter/components/ReporterPort"));
const Article = lazy(() => import("@/pages/Reporter/components/Article"));
const ReportManagement = lazy(() => import("@/pages/Report/ReportManagement"));
const TemplateManagement = lazy(() => import("@/pages/Report/TemplateManagement"));
const MaterialCollection = lazy(() => import("@/pages/Material/Collection"));
const MaterialResources = lazy(() => import("@/pages/Material/Resources"));
const Drafts = lazy(() => import("@/pages/Report/ReportManagement/pages/Drafts"));
const HideManagement = lazy(() => import("@/pages/Report/ReportManagement/pages/HideManagement"));
const ConciseTemplate = lazy(() => import("@/pages/Report/TemplateManagement/components/ConciseTemplate"));
const StandardTemplate = lazy(() => import("@/pages/Report/TemplateManagement/components/StandardTemplate"));
const Expert = lazy(() => import("@/pages/Expert"));
const WechatAuthorization = lazy(() => import("@/pages/WechatAuthorization"));
const ReportDetail = lazy(() => import("@/pages/Report/ReportManagement/components/ReportDetail"));
const Notice = lazy(() => import("@/pages/Notice"));
const ShareReport = lazy(() => import("@/pages/Report/ReportManagement/components/ShareReport"));
const ShartPage = lazy(() => import("@/pages/SharePage/Article"));
const WarnShartPage = lazy(() => import("@/pages/SharePage/Warn"));
const ArticleDescPage = lazy(() => import("@/pages/SharePage/ArticleDesc"));
const ArticleDetial = lazy(() => import("@/pages/Article"));
const TaskManagement = lazy(() => import("@/pages/Report/ReportManagement/pages/TaskManagement"));
const UserAccount = lazy(() => import("@/pages/User/UserProfile"));
const Experience = lazy(() => import("@/pages/User/Experience"));
const MediaHotList = lazy(() => import("@/pages/Media/components/MediaHotList"));
const ReporterHotList = lazy(() => import("@/pages/Reporter/components/ReporterHotList"));
const Template = lazy(() => import("@/pages/Template"));
const WarnManage = lazy(() => import("@/pages/Warn/pages/Manage"));
const WarnList = lazy(() => import("@/pages/Warn/pages/WarnList"));
const WarnNegative = lazy(() => import("@/pages/Warn/pages/WarnNegative"));
const Creation = lazy(() => import("@/pages/Creation/List"));
const Overview = lazy(() => import("@/pages/Overview"));
const DragSort = lazy(() => import("@/pages/DragSort"));
const PayMoney = lazy(() => import("@/pages/PayMoney"));

const SusComp = (Component: any, key?: string) => (
	<Suspense fallback={<Loading />}>
		<Component key={key} />
	</Suspense>
);

const _basic = [
	{ path: "/report/sharereport/:id", name: "分享简报", element: SusComp(ShareReport), },
	{ path: "/login", name: "衍策云-登录", element: <Login /> },
	{ path: "/monitorshare", name: "衍策云-监测方案分享", element: SusComp(ShartPage) },
	{ path: "/articleshare", name: "衍策云-文章分享", element: SusComp(ShartPage) },
	{ path: "/warnshare", name: "衍策云-预警分享", element: SusComp(WarnShartPage) },
	{ path: "/articleDesc", name: "衍策云-文章信息", element: SusComp(ArticleDescPage) },
	{ path: "/probation", name: "衍策云-申请试用", element: SusComp(Probation) },
	{ path: "/experience", name: "衍策云-体验Demo", element: SusComp(Experience) },
	{ path: "/wechatAuthorization", name: "授权", element: SusComp(WechatAuthorization) },
	{ path: "/event/cases", name: "经典案例", element: SusComp(EventCases) },
	{ path: "*", element: <NotFound /> },
]

const _monitor = [
	{
		path: "/monitor",
		name: "监测",
		element: <Navigate to="/event/panel" />,
	},
	{
		path: "/monitor/panel",
		name: "监测方案",
		element: SusComp(MonitorPanel),
	},
	{
		path: "/monitor/create",
		name: "创建监测方案",
		element: SusComp(CreateMonitor, "ADD"),
	},
	{
		path: "/monitor/edit/:id",
		name: "编辑监测",
		element: SusComp(CreateMonitor, "EDIT"),
	},
]

const _event = [
	{
		path: "/event",
		name: "事件",
		element: <Navigate to="/event/panel" />,
	},
	{
		path: "/event/panel",
		name: "事件",
		element: SusComp(EventPanel),
	},
	{
		path: "/event/create",
		name: "创建事件方案",
		element: SusComp(AddEditEvent, "ADD"),
	},

	{
		path: "/event/edit/:id",
		name: "修改事件",
		element: SusComp(AddEditEvent, "EDIT"),
	},
	{
		path: "/insight/eventAnalysis",
		name: "事件分析",
		element: SusComp(EventAnalysis),
	},
]

const _report = [
	{
		path: "/reporter",
		name: "记者",
		element: SusComp(Reporter),
	},
	{
		path: "/reporter/portrait",
		name: "记者画像",
		element: SusComp(Portrait),
	},
	{
		path: "/reporter/article",
		name: "文章篇幅",
		element: SusComp(Article),
	},
	{
		path: "/report/reportmanagement",
		name: "简报集合",
		element: SusComp(ReportManagement),
	},
	{
		path: "/report/templatemanagement",
		name: "简报模板",
		element: SusComp(TemplateManagement),
	},
	{
		path: "/report/drafts",
		name: "草稿箱",
		element: SusComp(Drafts),
	},
	{
		path: "/report/hidemanagement",
		name: "隐藏管理",
		element: SusComp(HideManagement),
	},
	{
		path: "/report/concisetemplate",
		name: "简易模板",
		element: SusComp(ConciseTemplate),
	},
	{
		path: "/report/standardtemplate",
		name: "标准模板",
		element: SusComp(StandardTemplate),
	},
	{
		path: "/report/reportdetail/:id",
		name: "简报详情",
		element: SusComp(ReportDetail),
	},
	{
		path: "/report/taskManagement",
		name: "简报任务",
		element: SusComp(TaskManagement),
	},
]

const _warn = [
	{
		path: "/warn/manage",
		name: "预警任务管理",
		element: SusComp(WarnManage),
	},
	{
		path: "/warn/list",
		name: "预警信息内容",
		element: SusComp(WarnList),
	},
	{
		path: "/warn/negative",
		name: "负面信息内容",
		element: SusComp(WarnNegative),
	},
]

const _crearion=[
	{
		path: "/creation/list",
		name: "创作列表",
		element: SusComp(Creation),
	},
	{
		path: "/creation/writing",
		name: "创作中心",
		element: SusComp(Writing),
	},
	{
		path: "/creation/outline",
		name: "文章大纲",
		element: SusComp(Outline),
	},
	{
		path: "/creation/outlinelist",
		name: "大纲列表",
		element: SusComp(OutlineList),
	},
	{
		path: "/creation/doc",
		name: "我的文档",
		element: SusComp(MyDocument),
	},
]

const _material = [
	{
		path: "/material/collection",
		name: "我的收藏",
		element: SusComp(MaterialCollection),
	},
	{
		path: "/material/resources",
		name: "素材库",
		element: SusComp(MaterialResources),
	},
]

const routes: RouteType[] = [
	{
		path: "/",
		name: "衍策云",
		element: <BasicLayout />,
		children: [
			// 监测
			..._monitor,
			// 事件
			..._event,
			// 报告
			..._report,
			// 资源库
			..._material,
			// 预警
			..._warn,
			// 创作
			..._crearion,
			{
				path: "/overview",
				name: "数据概览",
				element: SusComp(Overview),
			},
			{
				path: "/dragsort",
				name: "",
				element: SusComp(DragSort),
			},
			{
				path: "/template/create",
				name: "模版创建",
				element: SusComp(Template),
			},
			{
				path: "/template/edit/:id",
				name: "模版编辑",
				element: SusComp(Template),
			},
			{
				path: "/drag",
				name: "服务器错误",
				element: <Drag></Drag>,
			},
			{
				path: "/ceshi",
				name: "服务器错误",
				element: <CeShiPrint></CeShiPrint>,
			},
			{
				path: "/error",
				name: "服务器错误",
				element: <Error></Error>,
			},
			{
				path: "/customDatePicker",
				name: "自定义时间选择",
				element: <CustomDatePicker></CustomDatePicker>,
			},
			{
				path: "/",
				name: "工作台",
				element: <Navigate to="/workbench" />,
			},
			{
				path: "/notice",
				name: "通知中心",
				element: SusComp(Notice),
			},
			{
				path: "/user_oriented",
				name: "用户引导",
				element: SusComp(Oriented),
			},
			{
				path: "/workbench",
				name: "工作台",
				element: SusComp(Workbench),
			},
			{
				path: "/hot",
				name: "热点",
				element: SusComp(Hot),
			},
			{
				path: "/article/:id",
				name: "文章详情",
				element: SusComp(ArticleDetial),
			},
			{
				path: "/search",
				name: "全局搜索",
				element: SusComp(Search),
			},
			{
				path: "/kol",
				name: "KOL",
				element: SusComp(Kol),
			},
			{
				path: "/media",
				name: "媒体",
				element: SusComp(Media),
			},
			{
				path: "/expert",
				name: "专家前沿",
				element: SusComp(Expert),
			},
			{
				path: "/user",
				name: "用户中心",
				element: SusComp(UserAccount),
			},
			{
				path: "/user/:id",
				name: "用户中心",
				element: SusComp(UserAccount),
			},
			{
				path: "/media/mediaHotList",
				name: "媒体热榜",
				element: SusComp(MediaHotList),
			},
			{
				path: "/reporter/reporterHotList",
				name: "记者热榜",
				element: SusComp(ReporterHotList),
			},
			{
				path: "/pay",
				name: "支付",
				element: SusComp(PayMoney),
			},
			{ path: "*", element: <NotFound /> },
		],
	},
	..._basic,
];

/**
 * 处理标签页title
 * @param param
 * @returns
 */
const HandleTitle = ({ routeParams }: { routeParams: RouteType }) => {
	document.title = routeParams.name;
	return routeParams.element;
};
/**
 * load routes
 * @param rs
 * @returns
 */
export const renderRoutes = (rs: RouteType[]) => {
	return (
		<Routes>
			{rs.map((item) => {
				const { path, children } = item;
				return (
					<Route key={path} path={path} element={<HandleTitle routeParams={item} />}>
						{!!children &&
							children.map((i) => (
								<Route key={i.path} path={i.path} element={<HandleTitle routeParams={i} />} />
							))}
					</Route>
				);
			})}
		</Routes>
	);
};

export default routes;
