import { useContext, useEffect } from "react";
import { Layout, message } from "antd";
import { Link } from "react-router-dom";
import { Outlet } from "react-router";
import BasicMenu from "@/components/BasicMenu";
import BasicHeader from "@/components/BasicHeader";
import { getGlobalEnum } from "@/service/globalApi";
import { INJECT_ENUM, UPDATE_USER, GET_USER } from "../../store/instance";
import { getAccountInfo } from "@/pages/User/service";
import { GlobalContext } from "../../GlobalContext";
import AccessHOC from "@/access";
import PubSub from "pubsub-js";
import style from "./layout.module.less";

const { Content } = Layout;

const BasicLayout: React.FC = () => {
	const { state, dispatch } = useContext(GlobalContext);
	/**
	 * 载入用户信息
	 */
	const loadUserInfo = async () => {
		try {
			let response = await getAccountInfo();
			const { data, code, msg } = response.data;

			if (code === 200) {
				dispatch({
					type: UPDATE_USER,
					payload: {
						...state.user,
						info: {
							...data,
						},
					},
				});
			} else {
				message.error(msg);
			}
		} catch (error) {}
	};

	const getEnum = async () => {
		try {
			let response = await getGlobalEnum();
			const { code, data } = response.data;
			if (code === 200) {
				dispatch({ type: INJECT_ENUM, payload: { ...data } });
				localStorage.setItem("dict", JSON.stringify(data));
			}
		} catch (error) {}
	};

	useEffect(() => {
		PubSub.subscribe("LOADUSERINFO", () => {
			loadUserInfo();
		});
		return () => PubSub.unsubscribe("LOADUSERINFO");
	}, []);

	useEffect(() => {
		getEnum();
		loadUserInfo();
	}, []);

	// if (!token && pathname !== "/login" && pathname !== "/share_art") {
	//     return <Navigate to="/login" />
	// }

	return (
		<div className={style.layout}>
			<Layout>
				<BasicHeader />
				<Layout>
					<BasicMenu />
					<Content style={{ background: "#f8f9ff", width: `calc(100% - 70px)` }}>
						<Outlet />
					</Content>
					{/* <Link className={style.fixed_icon} to="/user/4">
                    <img src="/images/WechatIMG172.png" alt="" />
                    <span className={style.hot_title} style={{ fontSize: 14, flex: 1 }}>帮助</span>
                </Link> */}
				</Layout>
			</Layout>
		</div>
	);
};

export default AccessHOC(BasicLayout);
