import { Fragment } from "react";
import { Tabs } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import style from "./index.module.less";

const { TabPane } = Tabs;

type Tab = {
	name: string;
	key: string;
	label?: string;
	value?: string;
	disabled?: boolean;
};

type SubHeaderProps = {
	title: string;
	tabs?: Tab[];
	onChange?: (activeKey) => void;
	active?: string;
	extra?: any[];
	subTitle?: any;
	backIcon?: boolean;
	onBack?: any;
};

const SubHeader = (props: SubHeaderProps) => {
	const navigate = useNavigate();

	const {
		title = "",
		onChange = () => {},
		subTitle = [],
		extra = [],
		tabs = [],
		active = "",
		backIcon = false,
		onBack = true,
	} = props;

	const handleOnBack = () => {
		let backType = typeof onBack;
		switch (backType) {
			case "boolean":
				if (onBack) navigate(-1);
				break;
			case "string":
				navigate(onBack);
				break;
			case "function":
				onBack();
				break;
			default:
				return false;
		}
	};

	return (
		<div className={style.sub_header}>
			<div className={style.sub_header_title}>
				<h4>
					{backIcon ? <ArrowLeftOutlined onClick={handleOnBack} style={{ marginRight: 10 }} /> : null}
					{title}
				</h4>
				<span>{subTitle}</span>
				<Tabs defaultActiveKey={active} onChange={onChange} activeKey={active}>
					{tabs?.map((tab) => (
						<TabPane
							tab={tab.name || tab.label}
							key={tab.key || tab.value}
							disabled={tab.disabled || false}
						/>
					))}
				</Tabs>
			</div>
			<div className={style.sub_header_extra}>
				{extra?.map((item, index) => (
					<Fragment key={index}>{item}</Fragment>
				))}
			</div>
		</div>
	);
};

export default SubHeader;
