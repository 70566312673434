import { message } from "antd";
import axios from "axios";
import { BASEURL as baseURL, TIMEOUT as timeout } from "./config";

const instance = axios.create({ baseURL, timeout });

instance.interceptors.request.use(
    config => {
        if (config.url.indexOf('/media_api') !== -1) config.baseURL = ''
        const token = localStorage.getItem('token');
        if (token) if (config.headers) config.headers['Authorization-YC'] = `${token}`;
        return config
    },
    error => Promise.reject(error)

);

instance.interceptors.response.use(
    resp => {
        const { data } = resp;
        if (data.code === 401) {
            localStorage.clear()
            message.error(data.msg)
            setTimeout(() => {window.location.href = '/login' }, 1000)
        }
        return resp
    },
    error => Promise.reject(error)

);

export default instance;



