import { useState, useContext, useRef, useEffect } from "react"
import { LeftCircleFilled } from '@ant-design/icons';
import InfiniteScroll from "react-awesome-infinite-scroll";
import Nodata from "@/components/Nodata";
import Loading from "@/components/Loading";
import { GlobalContext } from "@/GlobalContext";
import { Segmented, Input, message, Tabs, Tooltip } from 'antd';
import { getSearchList, getSearchItemDetail, recommendArticleSplit } from "@/pages/Creation/service"
import styles from "./index.module.less"
const { TabPane } = Tabs;

/**
 * 创作中心搜索资源库
 * @param
 * @returns 
 */

export default function EditorMaterial(
    {
        getEditor,
        getEditorContents,
        setEditorContents,
        getEditorSelection,
        setTextSelection,
        setTextReplace,
        templateId,
        // SaveComponent //保存组件
    }
) {
    const { state: { dict: { creationSearchScope } } } = useContext(GlobalContext)
    const [detailParams, setDetailParams] = useState({ id: undefined, scope: undefined })
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0)
    const [detail, setDetail] = useState<any>()

    const [detailOpen, setDetailOpen] = useState(false)
    const [params, setParams] = useState({
        scope: '3',
        text: '',
        pageNum: 1,
        pageSize: 20,
        templateId: null
    })
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    // 切换搜索类别
    const onChange = async (val) => {
        const newParams = { ...params, scope: val, pageNum: 1 }
        setParams({ ...newParams })
        setList([])
        setDetailOpen(false)
        try {
            const response = await getSearchList({ ...newParams, });
            const { code, msg, data } = response.data;
            if (code === 200) {
                if (data?.list?.length) {
                    setTotal(data.total);
                    setLoading(false);
                    setList(data.list)
                    setHasMore(data.list?.length < data.total);
                }
            } else {
                setLoading(false);
            }
        } catch (error) {

        }
    }

    const onInputSearchText = (e) => {
        setParams({ ...params, text: e?.target?.value })
    }
    /**
     * 搜索
     * @returns 
     */
    const handleSearch = async () => {
        if (!params.text) return;
        setParams({ ...params, templateId, pageNum: 1 })
        setLoading(true)
        try {
            const response = await getSearchList({ ...params, });
            const { code, msg, data } = response.data;
            if (code === 200) {
                if (data?.list?.length) {
                    setTotal(data.total);
                    setLoading(false);
                    setList(data.list)
                    setHasMore(data.list?.length < data.total);
                }else{
                    setTotal(data.total);
                    setLoading(false);
                    setList(data.list)
                    setHasMore(false);
                }
            } else {
                setLoading(false);
            }
        } catch (error) { }
    }
    /**
     * next list
     * @param params 
     */
    const fetchList = async (params) => {
        try {
            setLoading(true)
            const response = await getSearchList(params);
            const { code, msg, data } = response.data;
            if (code === 200) {
                if (data.list?.length) {
                    const next = list.concat(data.list);
                    setList(next)
                    setTotal(data.total)
                    setParams({ ...params, pageNum: data.pageNum })
                    setHasMore(true);
                } else {
                    setHasMore(false);
                }
                setLoading(false);
            } else {
                setLoading(false);
                message.warning(msg);
            }
        } catch (error) {
            setLoading(false);
            message.warning(error);
        }
    };

    const onClickItem = async (cb_params) => {
        console.log(cb_params);

        // 精品稿件
        if (cb_params.articleId && cb_params.scope === '3') {
            try {
                const response = await recommendArticleSplit({ ...cb_params })
                const { code, msg, data } = response.data;
                if (code === 200) {
                    setDetail({
                        ...data,
                        paragraphList: data?.paragraphList?.filter(item => item?.trim()?.length)
                    })
                    setDetailOpen(true)
                } else {
                    message.error(msg)
                }
            } catch (error) {
                message.error(error)
            }
        }
        // 非精品稿件
        else if (cb_params.searchId && cb_params.scope) {
            try {
                const response = await getSearchItemDetail({ ...cb_params })
                const { code, msg, data } = response.data;
                if (code === 200) {
                    setDetail({
                        ...data,
                        paragraphList: data?.paragraphList?.filter(item => item?.trim()?.length)
                    })
                    setDetailOpen(true)
                } else {
                    message.error(msg)
                }
            } catch (error) {
                message.error(error)
            }
        }
    }

    const renderList = () => {
        return (
            // list?.length ? 
            <InfiniteScroll
                length={list?.length}
                next={() => fetchList({ ...params, pageNum: params.pageNum + 1 })}
                scrollableParent={document.querySelector(".listWrapper")}
                hasMore={hasMore}
                isRefreshing={false}
                pullDownToRefresh={false}
                pullDownComponent={<div style={{ height: "50px" }}>下拉</div>}
                releaseComponent={<div style={{ height: "50px" }}>释放</div>}
                refreshingComponent={<div style={{ height: "50px" }}>加载中</div>}
                refreshEndComponent={<div style={{ height: "50px" }}>加载完成</div>}
                loadingComponent={<Loading />}
                endComponent={
                    list?.length ? (
                        <div style={{ textAlign: "center", fontWeight: "normal", color: "#999" }}>
                            <span>没有更多内容了</span>
                        </div>
                    ) : null
                }
            >
                {
                    list.length ? list?.map((item, index) => (
                        (params.scope !== "2") ? (params.scope === "3") ? <TimelineItem3
                            {...item}
                            key={index}
                            scope={params?.scope}
                            setDetailOpen={setDetailOpen}
                            onClick={onClickItem}
                            setDetailParams={setDetailParams}
                        /> : <TimelineItem
                            {...item}
                            key={index}
                            scope={params?.scope}
                            setDetailOpen={setDetailOpen}
                            onClick={onClickItem}
                            setDetailParams={setDetailParams}
                        /> : <ParagraphItem
                            {...item}
                            key={index}
                            getEditor={getEditor}
                            getEditorContents={getEditorContents}
                            setEditorContents={setEditorContents}
                            getEditorSelection={getEditorSelection}
                            setTextSelection={setTextSelection}
                            setTextReplace={setTextReplace}
                        />
                    ))
                        : <Nodata description="暂无搜索内容" />
                }
            </InfiniteScroll>

        )
    }

    const onClose = () => {
        setDetailOpen(false)
    }

    // 获取精品稿件
    const handleGetTemplateRecommend = async () => {
        setParams({ ...params, templateId, pageNum: 1 })
        setLoading(true)
        try {
            const response = await getSearchList({ ...params, templateId });
            const { code, msg, data } = response.data;
            if (code === 200) {
                if (data?.list?.length) {
                    setTotal(data.total);
                    setLoading(false);
                    setList(data.list)
                    setHasMore(data.list?.length < data.total);
                }
            } else {
                setLoading(false);
            }
        } catch (error) { }
    }

    useEffect(() => {
        if (templateId) {
            handleGetTemplateRecommend()
        }
    }, [templateId])

    return (
        <div className={styles.editorMaterial}>
            <div className={styles.materialHeader}>
                {/* <Segmented options={creationSearchScope} value={params?.scope} onChange={onChange} /> */}
                <Tabs activeKey={params?.scope} onChange={onChange}>
                    {
                        creationSearchScope.map(item => {
                            return (
                                <Tabs.TabPane tab={item.label} key={item.value} />
                            )
                        })
                    }

                </Tabs>
            </div>
            <div className={styles.searchInput}>
                <Input.Search placeholder="输入搜索内容" onSearch={handleSearch} value={params?.text} onChange={onInputSearchText} />
            </div>
            <div className={styles.listWrapper}>
                {detailOpen ?
                    <Detail
                        detail={detail}
                        detailParams={detailParams}
                        getEditor={getEditor}
                        getEditorContents={getEditorContents}
                        setEditorContents={setEditorContents}
                        getEditorSelection={getEditorSelection}
                        setTextSelection={setTextSelection}
                        setTextReplace={setTextReplace}
                        onClose={onClose}
                    />
                    :
                    renderList()
                }
            </div>
            {/* <div className={styles.saveWrapper}>
                <SaveComponent />
            </div> */}
        </div>
    )
}

const Detail = ({
    detailParams,
    getEditor,
    getEditorContents,
    setEditorContents,
    getEditorSelection,
    setTextSelection,
    setTextReplace,
    detail,
    onClose,
    ...props
}) => {
    const copyEle = useRef();
    const onCopy = () => {
        const ele = copyEle.current;
        const range = document.createRange();
        window.getSelection().removeAllRanges();
        range.selectNode(ele);
        window.getSelection().addRange(range);
        const copyStatus = document.execCommand("copy");
        if (copyStatus) {
            message.success("复制成功", 1);
        } else {
            message.error("复制失败", 1);
        }
        window.getSelection().removeAllRanges();
    }

    /**
    * 文本追加
    * @param value 
    */
    const onAppend = (value) => {
        const range = getEditorSelection();
        setTextSelection(range, value)
    }
    /**
     * 文本替换
     * @param value 
     */
    const onReplace = (value) => {
        setTextReplace(getEditorSelection(), value)
    }

    const handleClose = () => {
        onClose()
    }

    return (
        <div className={styles.detail}>
            <div className={styles.header}>
                <h4>
                    <span className={styles.title}>{detail?.title}</span>
                    <span className={styles.icon}><LeftCircleFilled onClick={handleClose} /></span>
                </h4>
                <div>{detail?.infoSource && '来源:' + detail?.infoSource}</div>
            </div>
            <div className={styles.paragraphList}>
                {
                    detail?.paragraphList.map((item, index) => {
                        return (
                            <div className={styles.paragraph} key={index}>
                                <p ref={copyEle}>
                                    {item}
                                </p>
                                <div className={styles.action}>
                                    <span className={styles.actionItem} onClick={() => onAppend(item)}>
                                        <Tooltip title="导入到写作区">
                                            <i className='iconfont icon-youjiantou'></i>
                                        </Tooltip>
                                    </span>
                                    <span className={styles.actionItem} onClick={() => onReplace(item)}>
                                        <Tooltip title="替换">
                                            <i className='iconfont icon-tihuan'></i>
                                        </Tooltip>
                                    </span>
                                    <span className={styles.actionItem} onClick={onCopy}>
                                        <Tooltip title="复制">
                                            <i className='iconfont icon-fuzhi'></i>
                                        </Tooltip>
                                    </span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
// 如果是其他板块的时间轴
const TimelineItem = ({
    getEditor,
    getEditorContents,
    setEditorContents,
    getEditorSelection,
    setTextSelection,
    setTextReplace,
    onClick,
    ...props
}) => {

    return (
        <div className={styles.TimelineItem} onClick={() => onClick({ searchId: props.searchId, scope: props.scope })}>
            <div className={styles.lfetLine}>
                <div className={styles.circle}></div>
                <div className={styles.line}></div>
            </div>
            <div className={styles.timeAuthor}>
                <span>{props?.createTime}</span>
                <span>{props?.infoSource ? '｜' : ''}</span>
                <span>{props?.infoSource}</span>
            </div>
            <div className={styles.timelineContent}>
                <h4>{props?.title}</h4>
                <p>
                    {props?.sketch}
                </p>

            </div>

        </div>
    )
}
// 如果是精品稿件的时间轴
const TimelineItem3 = ({
    getEditor,
    getEditorContents,
    setEditorContents,
    getEditorSelection,
    setTextSelection,
    setTextReplace,
    onClick,
    ...props
}) => {

    return (
        <div className={styles.TimelineItem} onClick={() => onClick({ articleId: props.articleId, scope: props.scope })}>
            <div className={styles.lfetLine}>
                <div className={styles.circle}></div>
                <div className={styles.line}></div>
            </div>
            <div className={styles.timeAuthor}>
                <span>{props?.createTime}</span>
                <span>{props?.mediaName ? '｜' : ''}</span>
                <span>{props?.mediaName}</span>
            </div>
            <div className={styles.timelineContent}>
                <h4>{props?.articleTitle}</h4>
                <p dangerouslySetInnerHTML={{ __html: props.articleContent }}>
                </p>

            </div>

        </div>
    )
}
// 段落item
const ParagraphItem = ({
    content,
    getEditor,
    getEditorContents,
    setEditorContents,
    getEditorSelection,
    setTextSelection,
    setTextReplace,
}) => {
    const copyEle = useRef();
    const onCopy = () => {
        const ele = copyEle.current;
        const range = document.createRange();
        window.getSelection().removeAllRanges();
        range.selectNode(ele);
        window.getSelection().addRange(range);
        const copyStatus = document.execCommand("copy");
        if (copyStatus) {
            message.success("复制成功", 1);
        } else {
            message.error("复制失败", 1);
        }
        window.getSelection().removeAllRanges();
    }
    /**
   * 文本追加
   * @param value 
   */
    const onAppend = (value) => {
        const range = getEditorSelection();
        setTextSelection(range, value)
    }
    /**
     * 文本替换
     * @param value 
     */
    const onReplace = (value) => {
        setTextReplace(getEditorSelection(), value)
    }
    return (
        <div className={styles.paragraphItem}>
            <p ref={copyEle}>
                {content}
            </p>
            <div className={styles.action}>
                <span className={styles.actionItem} onClick={() => onAppend(content)}>
                    <Tooltip title="导入到写作区">
                        <i className='iconfont icon-youjiantou'></i>
                    </Tooltip>
                </span>
                <span className={styles.actionItem} onClick={() => onReplace(content)}>
                    <Tooltip title="替换">
                        <i className='iconfont icon-tihuan'></i>
                    </Tooltip>
                </span>
                <span className={styles.actionItem} onClick={onCopy}>
                    <Tooltip title="复制">
                        <i className='iconfont icon-fuzhi'></i>
                    </Tooltip>
                </span>
            </div>
        </div>
    )
}