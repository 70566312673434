import { Layout } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import PR from "./PR";
import IR from "./IR";
import GR from "./GR";
import { FireFilled } from "@ant-design/icons";
import style from "./workbench.module.less";
import SubHeader from "@/components/SubHeader";

const { Content } = Layout;

const Workbench = () => {
	const [key, setKey] = useState("1");

	const SubHeaderProps = {
		title: "工作台",
		defaultKey: key,
		tabs: [
			// { name: "PR场景", key: "1" },
			// { name: "IR场景", key: "2" },
			// { name: "GR场景", key: "3" },
		],
		onChange: (activeKey) => {
			setKey(activeKey);
		},
		active: "1",
		extra: [],
	};
	return (
		<Content className={style.content}>
			<SubHeader {...SubHeaderProps}></SubHeader>
			<PR />
			<Link className={style.hot_icon} to="/hot">
				<img src="/images/hot.gif" alt="" style={{height:30,marginLeft:4}}/>
				<span className={style.hot_title} style={{ fontSize: 12, flex: 1, marginLeft: 4 }}>
					热榜
				</span>
			</Link>
			{/* {key === "1" ? <PR /> : key === "2" ? <IR /> : <GR />} */}
		</Content>
	);
};

export default Workbench;
