// import { useRoutes,useLocation} from 'react-router';
import { useEffect } from 'react';
import routes from '@/route/routes';
import { Navigate, useLocation, useNavigate } from 'react-router';
import GlobalStore from "@/store/index"
import { GlobalContext } from "./GlobalContext"
import { ConfigProvider, message } from 'antd';
import { renderRoutes } from "@/route/routes"
import 'moment/locale/zh-cn';
import SwiperCore, {Autoplay,Pagination,Navigation} from 'swiper/core';
import {getGlobalEnum} from "@/service/globalApi"
import {INJECT_ENUM} from "@/store/instance"
import locale from 'antd/es/locale/zh_CN';
import global from "@/styles/global.module.less"
import './App.css';

SwiperCore.use([Autoplay,Pagination,Navigation])
message.config({ maxCount: 1 })

const App = (props: any) => {
    const [state, dispatch] = GlobalStore();
    const location = useLocation()
    const navigate = useNavigate()

    const getEnum = async () => {
        try {
            let response = await getGlobalEnum();
            const { code, data } = response.data;
            if (code === 200) {
                dispatch({ type: INJECT_ENUM, payload: { ...data } })
                localStorage.setItem('dict', JSON.stringify(data))
            }
        } catch (error) { }
    }

    useEffect(() => {
        getEnum()
        if (location.pathname.indexOf('monitor') == -1 && location.pathname.indexOf("event") == -1) {
            localStorage.removeItem('ID');
            localStorage.removeItem('EDITID');
        }
    }, [location.pathname])

    return (
        <ConfigProvider locale={locale}>
            <div className={global.app_container}>
                <GlobalContext.Provider value={{ state, dispatch }}>
                    {/* {useRoutes(routes)} */}
                    {renderRoutes(routes)}
                </GlobalContext.Provider>
            </div>
        </ConfigProvider>

    )
}

export default App

