import { Navigate, useLocation } from "react-router";
import { useContext } from "react";
import { GlobalContext } from "@/GlobalContext";
const TOKEN = "token";
const ISLOGIN = "isLogin";

function AccessHOC(WrappedComponent) {
    return function Wrapper(props) {
        const { state: { user } } = useContext(GlobalContext);
        const { pathname = '/' } = useLocation()
        const hasToken = localStorage.getItem(TOKEN);
        const LoggedIn = localStorage.getItem(ISLOGIN);

        if (!hasToken && pathname !== "/login" && pathname !== "/share_art") {
            return <Navigate to="/login" />
        }

        return <WrappedComponent {...props} />
    }
}

export default AccessHOC