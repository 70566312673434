import * as echarts from 'echarts/core';
import {
    ToolboxComponent,
    ToolboxComponentOption,
    LegendComponent,
    LegendComponentOption
} from 'echarts/components';
import { PieChart, PieSeriesOption } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useEffect, useRef } from "react";

echarts.use([
    ToolboxComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
]);

type EChartsOption = echarts.ComposeOption<
    ToolboxComponentOption | LegendComponentOption | PieSeriesOption
>;



type BarChartOnPolarProps = {
    resize?: any;
    data: any[];
    onClick?: Function;
    colors?: any;
}


export default function BarChartOnPolar(props: BarChartOnPolarProps) {
    let mountVariable = null;
    const chartRef = useRef();
    const { resize, data = [], onClick, colors = ["#f08755", "#f08755", "#f08755", "#f08755", "#f08755", "#f08755", "#f08755", "#f08755", "#f08755"] } = props;
    useEffect(() => {
        if (mountVariable !== null && mountVariable !== "" && mountVariable !== undefined) {
            mountVariable.dispose();
        }
        const option = {
            color: colors,
            angleAxis: {
                type: 'category',
                data: data?.map((item, index) => item?.name),
                boundaryGap: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: ['#ccc'],
                        width: 1,
                        type: 'dashed'
                    }
                },
                axisLine: {
                    show: false,
                    onZero: false
                },
                axisTick: {
                    show: false
                }
            },
            radiusAxis: {
                axisLine: {
                    show: false
                },
            },
            polar: {},

            series: [
                {
                    name: '',
                    startAngle: 100,
                    max: 100,

                    type: 'pie',
                    radius: [15, 80],
                    center: ['50%', '50%'],
                    roseType: 'area',
                    label: {
                        show: false
                    },
                    labelLine: {
                        show: false
                    },
                    itemStyle: {
                        borderRadius: 0,
                        borderColor: "#fff",
                        borderWidth: 3,
                    },
                    data:data.map(item=>{
                        return {
                            ...item,
                            itemStyle:{
                                color:item?.color
                            }
                        }
                    })
                }
            ]
        };
        mountVariable = echarts.init(chartRef.current);
        mountVariable.off("click");
        mountVariable.on("click", (params) => {
            console.log('params', params);
            onClick(params)
        });
        const resizeChart = () => { mountVariable.resize() };
        mountVariable.setOption(option);
        window.addEventListener("resize", resizeChart);
        if (resize || !resize) resizeChart();
        return () => window.removeEventListener("resize", resizeChart);
    }, [props, resize])
    return <div ref={chartRef} style={{ height: "100%" }}></div>;
}
