import { Table, Tag } from "antd";
import style from "./index.module.less";
import Nodata from "../Nodata";
import { useState } from "react";

export default function App(props: any) {
	const { columns, dataSource, title, keyWords, icon } = props;
	return (
		<div className={style.content}>
			<div className={style.header}>
				{title && <div className={style.two_title}>{title}</div>}
				{keyWords?.[0] && (
					<div className={style.keyWords}>
						关键词：
						{keyWords?.[0] && <Tag color="#2A6DDB">{keyWords?.[0]}</Tag>}
						{keyWords?.[1] && <Tag color="#407CDE">{keyWords?.[1]}</Tag>}
						{keyWords?.[2] && <Tag color="#5A8FE3">{keyWords?.[2]}</Tag>}
						{keyWords?.[0] && <Tag color="#5A8FE3">...</Tag>}
					</div>
				)}
			</div>
			<div className={style.tableContent}>
				{dataSource?.length === 0 ? (
					<Nodata description={<span>暂无强关联数据</span>} />
				) : (
					<Table
						columns={columns}
						rowKey={(record) => record.articleId}
						dataSource={dataSource}
						pagination={false}
						size="small"
					/>
				)}
			</div>
		</div>
	);
}
