import { useEffect, useRef } from "react";
import * as echarts from "echarts/core";
import { TooltipComponent, GridComponent, LegendComponent, DataZoomComponent } from "echarts/components";
import { ScatterChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
	GridComponent,
	ScatterChart,
	CanvasRenderer,
	UniversalTransition,
	TooltipComponent,
	GridComponent,
	LegendComponent,
	DataZoomComponent,
]);

let myChart;

const Bar = (props) => {
	const { xaxis = [], yaxis = [], resize, onClick, data, datashiduan } = props;
	const chartRef = useRef();
	let keyWordData = [];
	let timeSlot = [];
	let yaxisData = [];

	// y轴最大值,最小值
	let maxYaxis, minYaxis;
	// 最大值 最小值处理
	const getRndInteger = () => {
		maxYaxis = Math.max(...yaxisData);
		minYaxis = Math.min(...yaxisData);
	};
	// 处理关键词
	const keyWordDataHandler = () => {
		let num;
		// const maxYaxis=Math.max(...yaxisData)
		// const minYaxis=Math.min(...yaxisData)
		// getRndInteger()
		data?.map((item) => {
			num = Math.floor(Math.random() * (maxYaxis - minYaxis + minYaxis));
			// num=Math.random()
			keyWordData.push([item.xAxis, num, "", item.keywordList]);
			return keyWordData;
		});
	};
	// 提取y轴
	const yaxisHandler = () => {
		data?.map((item) => {
			yaxisData.push(item?.yAxis);
			return yaxisData;
		});
	};
	// 时间段颜色
	const colorList = [
		{
			type: "linear",
			x: 1,
			y: 0,
			x2: 0,
			y2: 0,
			colorStops: [
				{ offset: 1, color: "#aec6f2" },
				{ offset: 0, color: "#2352b2" },
			],
			global: false,
		},
		{
			type: "linear",
			x: 1,
			y: 0,
			x2: 0,
			y2: 0,
			colorStops: [
				{ offset: 1, color: "#2352b2" },
				{ offset: 0, color: "#2352b2" },
			],
			global: false,
		},
		{
			type: "linear",
			x: 1,
			y: 0,
			x2: 0,
			y2: 0,
			colorStops: [
				{ offset: 1, color: "#2352b2" },
				{ offset: 0, color: "#d0e2fa" },
			],
			global: false,
		},
	];
	// 处理时间段
	// const timeSlotHandler = () => {
	// 	datashiduan?.map((item, index) => {
	// 		timeSlot.push([
	// 			{
	// 				name: "",
	// 				xAxis: item?.xAxisStart,
	// 				itemStyle: {
	// 					color: colorList[index],
	// 				},
	// 			},
	// 			{
	// 				xAxis: item?.xAxisEnd,
	// 			},
	// 		]);
	// 		return timeSlot;
	// 	});
	// };
	useEffect(() => {
		if (myChart !== null && myChart !== "" && myChart !== undefined) {
			myChart.dispose();
		}
		// timeSlotHandler();
		yaxisHandler();
		getRndInteger();
		keyWordDataHandler();

		const option = {
			xAxis: {
				splitLine: { show: false },
				show: true,
				data: xaxis,
				boundaryGap: false,
				axisLine:{     //x轴坐标轴
					show:false
				},
				axisTick: {		//x轴刻度线
					show:false,
				},
			},
			yAxis: {
				splitLine: { show: false},
				show:false,
				max:Math.floor(maxYaxis + maxYaxis / 5),
				min: Math.floor(minYaxis - maxYaxis / 5),
			},
			// 滚动条
			dataZoom: [
				{
					type: "slider",
					show: true,
					start: 0,
					end: 100,
					height: 20,
					bottom: 15,
					left:"4%",
					right:22,
					backgroundColor: "#2352b2",
					showDetail :false,
				},
			],
			grid: {
				// show:false
				left: "1%",
				right: "2%",
				top: 20,
				bottom: 40,
				containLabel: true,
			},
			series: [
				{
					name: "",
					data: keyWordData,
					type: "scatter",
					symbolSize: 36,
					emphasis: {
						focus: "none",
					},
					legendHoverLink: false,
					// 时间段
					markArea: {
						// data: timeSlot,
						// silent: true,
						// data: [
						//   [
						//     {
						//       name: '潜伏期',
						//       xAxis: '07.19',
						//       itemStyle:{
						//           color:{
						//             type: 'linear',
						//             x: 1,
						//             y: 0,
						//             x2: 0,
						//             y2: 0,
						//             colorStops: [
						//               {offset:1, color: 'rgba(90,73,155,0.11)'},
						//               {offset: 0, color: 'rgba(90,73,155,0.50)'}],
						//             global: false
						//            },
						//         }

						//     },
						//     {
						//       xAxis: '07.25'
						//     }
						//   ],
						//   [
						//     {
						//       name: '爆发期',
						//       xAxis: '07.25',
						//        itemStyle:{
						//            color:{
						//             type: 'linear',
						//             x: 1,
						//             y: 0,
						//             x2: 0,
						//             y2: 0,
						//             colorStops: [
						//               {offset:1, color: 'rgba(90,73,155,0.50)'},
						//               {offset: 0, color: 'rgba(90,73,155,0.50)'}],
						//             global: false
						//            },
						//         }
						//     },
						//     {
						//       xAxis: '07.30'
						//     }
						//   ],
						//   [
						//     {
						//       name: '消退期',
						//       xAxis: "07.30",
						//       itemStyle:{
						//            color:{
						//             type: 'linear',
						//             x: 1,
						//             y: 0,
						//             x2: 0,
						//             y2: 0,
						//             colorStops: [
						//               {offset:1, color: 'rgba(90,73,155,0.50)'},
						//               {offset: 0, color: 'rgba(90,73,155,0.11)'}],
						//             global: false
						//            },
						//         }
						//     },
						//     {
						//       xAxis: "08.24"
						//     }
						//   ]
						// ]
					},
					labelLine: {
						show: false,
						length: 5,
						lineStyle: {
							color: "#333",
						},
					},
					itemStyle: {
						opacity: 1,
						// color: "#fff",
						// color: function (param) {
						// 	// const color = [
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// 	"#71d9a0",
						// 	// 	"#a99dd2",
						// 	// ];
						// 	return color[param.dataIndex];
						// },
					},
					// 鼠标事件
					// silent: true,
					label: {
						show: true,
						formatter: function (param) {
							return param.data[3];
						},
						fontSize: "10",
						position: "inside",
						minMargin: 2,
						color: "#fff",
						width: 26,
						overflow: "break",
						focus: "none",
					},
				},
			],
		};

		myChart = echarts.init(chartRef.current);
		const resizeChart = () => {
			myChart.resize();
		};
		myChart.off("click");
		myChart.on("click", (params) => onClick(params, "keyWords"));
		myChart.setOption(option);
		window.addEventListener("resize", resizeChart);
		if (resize || !resize) resizeChart();
		return () => window.removeEventListener("resize", resizeChart);
	}, [props, resize]);

	return <div ref={chartRef} style={{ height: "100%" }}></div>;
};

export default Bar;
