import imgs from "../verticallogo.png";
const Template = () => {
	const styles = `
            div{
                height:200px;
            }
            .dv1{
                -webkit-print-color-adjust: exact;
                background: pink
            }
            .dv2{
                -webkit-print-color-adjust: exact;
                background: green
            }
            .dv3{
                -webkit-print-color-adjust: exact;
                background: orange
            }
            .dv4{
                -webkit-print-color-adjust: exact;
                background: skyblue
            }
            .dv111{
                height:300px;
                width:500px;
            }
        `;
	return (
		<div>
			<style>{styles}</style>
			<div>
				<img src={imgs} alt="" />
			</div>
			<div className="dv2">2</div>
			<div className="dv3">3</div>
			<div>
				<img src={imgs} alt="" />
			</div>
			<div style={{ pageBreakAfter: "always", height: "50px" }}></div>
			<div className="dv4">4</div>
			<div className="dv1">1</div>
			<div className="dv2">2</div>
			<div className="dv3">3</div>
			<div className="dv4">4</div>
			<div style={{ pageBreakAfter: "always", height: "50px" }}></div>

			<div className="dv1">你好啊</div>
			<div style={{ pageBreakAfter: "always", height: "50px" }}></div>

			<div className="dv2">2</div>
			<div className="dv3">3</div>
			<div className="dv4">4</div>
			<div className="dv1">1</div>
			<div className="dv2">2</div>
			<div className="dv3">3</div>
			<div className="dv4">4</div>
			<div className="dv1">1</div>
			<div className="dv2">2</div>
			<div className="dv3">3</div>
			<div className="dv4">4</div>
			<div className="dv1">1</div>
			<div className="dv2">2</div>
			<div className="dv3">3</div>
			<div className="dv4">4</div>
			<div className="dv1">1</div>
			<div className="dv2">2</div>
			<div className="dv3">3</div>
			<div className="dv4">4</div>
			<div className="dv1">1</div>
			<div className="dv2">2</div>
			<div className="dv3">3</div>
			<div className="dv4">4</div>
			<div className="dv1">1</div>
			<div className="dv2">2</div>
			<div className="dv3">3</div>
			<div className="dv4">4</div>
			<div className="dv1">1</div>
			<div className="dv2">2</div>
			<div className="dv3">3</div>
			<div className="dv4">4</div>
		</div>
	);
};

export default Template;
