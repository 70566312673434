// @ts-nocheck
import { Layout, Menu, Badge } from "antd";
import {
	AppstoreFilled,
	PieChartFilled,
	FundFilled,
	AlertFilled,
	FolderOpenFilled,
	InstagramFilled,
	FlagFilled,
	ReconciliationFilled,
	FireFilled,
	QuestionCircleOutlined,
	MacCommandFilled,
	HighlightFilled,
	ReadFilled
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState, useContext } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { GlobalContext } from "@/GlobalContext";
import menuStyle from "./menu.module.less";
import { hasPermsAccess } from "@/access/AccessWrap";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

const BasicMenu: React.FC = () => {
	const { pathname } = useLocation();
	const {
		state: { user },
	} = useContext(GlobalContext);
	const hasNewWarn = user?.info?.isNewWarn;
	const [isNewWarnClick, setIsNewWarnClick] = useState(false);
	// 体验用户
	const isDisabled = user?.info?.permissionName === "EXPERIENCE" ? true : false;
	const navigate = useNavigate();
	const [key, setKey] = useState<string>(pathname);
	const MenuItems: MenuItem[] = [
		{
			title: "数据概览",
			label: "数据概览",
			key: "/overview",
			icon: renderIcon(ReadFilled, "overview.show", "/overview"),
		},
		{
			title: "工作台",
			label: "工作台",
			key: "/workbench",
			icon: <AppstoreFilled />,
		},
		{
			title: "监测",
			label: (
				<span
					style={{ margin: 0, width: "100%" }}
					className={menuStyle.submenuTitle}
					onClick={() => {
						if (hasPermsAccess(user?.info?.permissionName, "monitor.show")) return false;
						setKey("/monitor/panel");
						navigate("/monitor/panel");
					}}
				>
					声誉监测
				</span>
			),
			key: "/Monitor",
			icon: (
				<FundFilled
					onClick={() => {
						if (hasPermsAccess(user?.info?.permissionName, "monitor.show")) return false;
						setKey("/monitor/panel");
						navigate("/monitor/panel");
					}}
				/>
			),
			children: [
				{
					title: "监测方案列表",
					label: "监测方案列表",
					key: "/monitor/panel",
					disabled: hasPermsAccess(user?.info?.permissionName, "monitor.show"),
					// icon: <HeartFilled />
				},
				{
					title: "创建监测方案",
					label: "创建监测方案",
					key: "/monitor/create",
					disabled: hasPermsAccess(user?.info?.permissionName, "monitor.create"),
					// icon: <HeartFilled />
				},
			],
		},
		{
			title: "事件",
			key: "/Event",
			label: (
				<span
					style={{ margin: 0, width: "100%" }}
					className={menuStyle.submenuTitle}
					onClick={() => {
						if (hasPermsAccess(user?.info?.permissionName, "event.show")) return false;
						setKey("/event/panel");
						navigate("/event/panel");
					}}
				>
					声誉分析
				</span>
			),
			disabled: hasPermsAccess(user?.info?.permissionName, "event.show"),
			icon: (
				<PieChartFilled
					onClick={() => {
						if (hasPermsAccess(user?.info?.permissionName, "event.show")) return false;
						setKey("/event/panel");
						navigate("/event/panel");
					}}
				/>
			),
			children: [
				{
					title: "事件方案列表",
					label: "事件方案列表",
					key: "/event/panel",
					disabled: hasPermsAccess(user?.info?.permissionName, "event.show"),
				},
				{
					title: "创建事件方案",
					label: "创建事件方案",
					key: "/event/create",
					disabled: hasPermsAccess(user?.info?.permissionName, "event.show"),
				},
				{
					title: "事件分析",
					label: "事件分析",
					key: "/insight/eventAnalysis",
				},
				{
					title: "经典案例",
					label: "经典案例",
					key: "/event/cases",
				},
			],
		},
		{
			title: "声誉预警",
			label: (
				<span
					style={{ margin: 0, width: "100%" }}
					className={menuStyle.submenuTitle}
					onClick={() => {
						if (hasPermsAccess(user?.info?.permissionName, "warn.show")) return false;
						setKey("/warn/list");
						navigate("/warn/list");
					}}
				>
					声誉预警
				</span>
			),
			key: "/warn/list",
			disabled: hasPermsAccess(user?.info?.permissionName, "warn.show"),
			icon: (
				<Badge count={hasNewWarn && !isNewWarnClick ? "New" : ""} offset={[-20, 10]} size="small" color="red" onClick={() => {
					setKey("/warn/list");
					navigate("/warn/list");
				}}>
					<AlertFilled
						style={{ minWidth: 44 }}

					/>
				</Badge>
			),
			// children: [
			// 	{
			// 		title: "预警信息内容",
			// 		label: "预警信息内容",
			// 		key: "/warn/list",
			// 	},
			// 	{
			// 		title: "预警任务管理",
			// 		label: "预警任务管理",
			// 		key: "/warn/manage",
			// 	},
			// 	// {
			// 	// 	title: "负面信息内容",
			// 	// 	label: "负面信息内容",
			// 	// 	key: "/warn/negative",
			// 	// 	disabled: hasPermsAccess(user?.info?.permissionName, "warn.Negative"),
			// 	// },
			// ],
		},
		{
			title: "声誉简报",
			label: (
				<span
					style={{ margin: 0, width: "100%" }}
					className={menuStyle.submenuTitle}
					onClick={() => {
						if (isDisabled) return;
						setKey("/report/reportmanagement");
						navigate("/report/reportmanagement");
					}}
				>
					声誉简报
				</span>
			),
			key: "/Report/reportmanagement",
			disabled: hasPermsAccess(user?.info?.permissionName, "report.show"),
			icon: (
				<ReconciliationFilled
					onClick={() => {
						if (hasPermsAccess(user?.info?.permissionName, "report.show")) return;
						setKey("/report/reportmanagement");
						navigate("/report/reportmanagement");
					}}
				/>
			),
			// children: [
			// 	{
			// 		title: "简报集合",
			// 		label: "简报集合",
			// 		key: "/report/reportmanagement",
			// 	},
			// 	{
			// 		title: "简报模板",
			// 		label: "简报模板",
			// 		key: "/report/templatemanagement",
			// 	},
			// 	{
			// 		title: "简报任务",
			// 		label: "简报任务",
			// 		key: "/report/taskManagement",
			// 	},
			// ],
		},
		{
			title: "素材库",
			label: (
				<span
					style={{ margin: 0, width: "100%" }}
					className={menuStyle.submenuTitle}
					onClick={() => {
						if (isDisabled) return;
						setKey("/material/resources");
						navigate("/material/resources");
					}}
				>
					素材库
				</span>
			),
			key: "/6",
			disabled: hasPermsAccess(user?.info?.permissionName, "material.click"),
			icon: (
				<FolderOpenFilled
					onClick={() => {
						if (hasPermsAccess(user?.info?.permissionName, "material.resources.show")) return;
						setKey("/material/resources");
						navigate("/material/resources");
					}}
				/>
			),
			children: [
				{
					title: "素材库",
					label: "素材库",
					disabled: hasPermsAccess(user?.info?.permissionName, "material.resources.show"),
					key: "/material/resources",
				},
				{
					title: "我的收藏",
					label: "我的收藏",
					disabled: hasPermsAccess(user?.info?.permissionName, "material.collection.show"),
					key: "/material/collection",
				},
			],
		},
		{
			title: "媒体中心",
			label: (
				<span
					style={{ margin: 0, width: "100%" }}
					className={menuStyle.submenuTitle}
					onClick={() => {
						if (isDisabled) return;
						// console.log(user?.info?.isBuyMediaCenter,"user");
						// if(user?.info?.isBuyMediaCenter){
						// 	setKey("/media");
						// 	navigate("/media");
						// }else {
						// 	setKey("/pay");
						// 	navigate("/pay");
						// }
						setKey("/media");
						navigate("/media");

					}}
				>
					媒体中心
				</span>
			),
			disabled: hasPermsAccess(user?.info?.permissionName, "media.click"),
			key: "/media",
			icon: (
				<InstagramFilled
					onClick={() => {
						if (hasPermsAccess(user?.info?.permissionName, "media.click")) return;
						// if(user?.info?.isBuyMediaCenter){
						// 	setKey("/media");
						// 	navigate("/media");
						// }else {
						// 	setKey("/pay");
						// 	navigate("/pay");
						// }
						setKey("/media");
						navigate("/media");
					}}
				/>
			),
			// children: [
			// 	{
			// 		title: "核心媒体",
			// 		label: "核心媒体",
			// 		key: "/media",
			// 		disabled: !user?.info?.isBuyMediaCenter
			// 	},
			// 	// {
			// 	// 	title: "媒体热榜",
			// 	// 	label: "媒体热榜",
			// 	// 	disabled: isDisabled,
			// 	// 	key: "/media/mediaHotList",
			// 	// },
			// 	{
			// 		title: "活跃记者",
			// 		label: "活跃记者",
			// 		key: "/reporter",
			// 		disabled: !user?.info?.isBuyMediaCenter
			// 	},
				// {
				// 	title: "记者画像",
				// 	label: "记者画像",
				// 	key: "/reporter/portrait",
				// },
				// {
				// 	title: "记者热榜",
				// 	label: "记者热榜",
				// 	disabled: isDisabled,
				// 	key: "/reporter/reporterHotList",
				// },
				// {
				//   title: "KOL",
				//   label: "KOL",
				//   key: "/kol",
				//   // icon: <HeartFilled />
				// },
			// ],
		},
		// {
		// 	title: "创作中心",
		// 	label: (
		// 		<span
		// 			style={{ margin: 0, width: "100%" }}
		// 			className={menuStyle.submenuTitle}
		// 			onClick={() => {
		// 				if (hasPermsAccess(user?.info?.permissionName, "creation.show")) return false;
		// 				setKey("/creation/list");
		// 				navigate("/creation/list");
		// 			}}
		// 		>
		// 			创作中心
		// 		</span>
		// 	),
		// 	disabled: hasPermsAccess(user?.info?.permissionName, "creation.show"),
		// 	key: "/Creation",
		// 	icon: <HighlightFilled
		// 		onClick={() => {
		// 			if (hasPermsAccess(user?.info?.permissionName, "creation.show")) return;
		// 			setKey("/creation/list");
		// 			navigate("/creation/list");
		// 		}}
		// 	/>,
		// 	children: [
		// 		{
		// 			title: "创作中心",
		// 			label: "创作中心",
		// 			key: "/creation/list",
		// 		},
		// 		{
		// 			title: "我的文档",
		// 			label: "我的文档",
		// 			key: "/creation/doc",
		// 		},
		// 		{
		// 			title: "大纲列表",
		// 			label: "大纲列表",
		// 			key: "/creation/outlinelist",
		// 		},
		// 		{
		// 			title: "文章大纲",
		// 			label: "文章大纲",
		// 			hidden:true,
		// 			key: "/creation/outline",
		// 		},
		// 		{
		// 			title: "文章写作",
		// 			label: "文章写作",
		// 			hidden:true,
		// 			key: "/creation/writing",
		// 		},
		// 	]
		// },

	];

	useEffect(() => {
		console.log(pathname,"pathname");
		setKey(pathname);
	}, [pathname]);

	const onMenuSelect = ({ key }: { key: string }) => {
		setKey(key);
		navigate(key);
		if ((key = "/warn")) setIsNewWarnClick(true);
	};

	return (
		<Sider width="70" className={menuStyle.sider}>
			<Scrollbars>
				<Menu
					defaultSelectedKeys={[key]}
					selectedKeys={[key]}
					theme="light"
					mode="vertical"
					style={{ height: "100%" }}
					items={MenuItems}
					onSelect={onMenuSelect}
					onClick={({ item, key, keyPath, domEvent }) => { }}
				/>
			</Scrollbars>
		</Sider>
	);
};

const renderIcon = (Icon, access, path) => {
	const handleClick = () => {
		if (hasPermsAccess(user?.info?.permissionName, access)) return false;
		setKey(path);
		navigate(path);
	};
	return <Icon onClick={handleClick} />;
};

export default BasicMenu;

// {
		//     title: '关键记者',
		//     label: '关键记者',
		//     key: '/Reporter',
		//     icon: <InstagramFilled />,
		//     children: [
		//         {
		//             title: '记者',
		//             label: '记者',
		//             key: '/reporter',
		//             // icon: <HeartFilled />
		//         },
		//         {
		//             title: '记者画像',
		//             label: '记者画像',
		//             key: '/reporter/portrait',
		//             // icon: <HeartFilled />
		//         },
		//         {
		//             title: '文章篇幅',
		//             label: '文章篇幅',
		//             key: '/reporter/article',
		//             // icon: <HeartFilled />
		//         },
		//     ]
		// },
		// {
		//     title: 'KOL',
		//     label: 'KOL',
		//     key: '/kol',
		//     icon: <InstagramFilled />
		// },
		// {
		//   title: "专家前沿",
		//   label: "专家前沿",
		//   key: "/expert",
		//   icon: <FlagFilled />,
		// },
		// {
		//   title: "用户引导",
		//   label: "用户引导",
		//   key: "/user_oriented",
		//   icon: <InstagramFilled />,
		// },
