/**
 * 用户登录
 */
import { useState, useEffect, useCallback, useContext, useRef } from "react";
import { Navigate, useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Layout, Button, Tabs, Form, Input, Image, message } from "antd";
import {getSmsCode, getImgCode, getAccountInfo} from "../service";
import { login, smsLogin } from "@/pages/User/service";
import { GlobalContext } from "@/GlobalContext";
import type { loginParams, smsLoginParams } from "../type";
import loginStyle from "./login.module.less";
import cookie from 'react-cookies';

const { Header, Footer, Content } = Layout;
const { TabPane } = Tabs;
let $win: any = window;
let isUnmounted = false;
const Login: React.FC = (props) => {
	const token = getCookieToken()
	const [formType, setFormType] = useState<string>("PasswordLoginForm");
	const onChange = (key: string) => setFormType(key);
	const ref = useRef(null);
	let location = useLocation()
	const params = new URLSearchParams(location.search);
	let redirect:string = params.get('redirect') || '/'
	useEffect(() => {
		if (ref.current) {
			$win.particlesJS("particles-js", {
				particles: {
					number: {
						value: 80,
						density: {
							enable: true,
							value_area: 700,
						},
					},
					color: {
						value: "#ffffff",
					},

					shape: {
						type: "circle",
						stroke: {
							width: 0,
							color: "#000000",
						},

						polygon: {
							nb_sides: 5,
						},
					},
					opacity: {
						value: 0.5,
						random: false,
						anim: {
							enable: false,
							speed: 0.1,
							opacity_min: 0.1,
							sync: false,
						},
					},
					size: {
						value: 3,
						random: true,
						anim: {
							enable: false,
							speed: 10,
							size_min: 0.1,
							sync: false,
						},
					},

					line_linked: {
						enable: true,
						distance: 150,
						color: "#ffffff",
						opacity: 0.4,
						width: 1,
					},

					move: {
						enable: true,
						speed: 2,
						direction: "none",
						random: false,
						straight: false,
						out_mode: "out",
						bounce: false,
						attract: {
							enable: false,
							rotateX: 600,
							rotateY: 1200,
						},
					},
				},
				interactivity: {
					detect_on: "canvas",
					events: {
						onhover: {
							enable: true,
							mode: "grab",
						},

						onclick: {
							enable: true,
							mode: "push",
						},

						resize: true,
					},
					modes: {
						grab: {
							distance: 140,
							line_linked: {
								opacity: 1,
							},
						},
						bubble: {
							distance: 400,
							size: 40,
							duration: 2,
							opacity: 8,
							speed: 3,
						},

						repulse: {
							distance: 200,
							duration: 0.4,
						},

						push: {
							particles_nb: 4,
						},

						remove: {
							particles_nb: 2,
						},
					},
				},
				retina_detect: true,
			});
		}
	}, []);
	if (!token) {
		return (
			<div className={loginStyle.login} id="particles-js" ref={ref}>
				<div className={loginStyle.loginPosition}>
					<Header style={{ background: "unset", display: "flex", alignItems: "center", padding: "0 20px" }}>
						<img src="/images/logo.png" alt="" style={{ height: 30 }} />
					</Header>
					<Content className={loginStyle.loginContent}>
						<div className={loginStyle.loginForm}>
							<Tabs activeKey={formType} onChange={onChange} centered>
								<TabPane tab="密码登录" key="PasswordLoginForm">
									{formType === "PasswordLoginForm" && <PasswordLoginForm {...props} />}
								</TabPane>
								<TabPane tab="短信登录" key="MobileLoginForm">
									{formType === "MobileLoginForm" && <MobileLoginForm {...props} />}
								</TabPane>
							</Tabs>
						</div>
					</Content>
					<Footer style={{ textAlign: "center", background: "unset", color: "#fff" }}>
						含光素问 |{" "}
						<Link to="/probation" style={{ color: "#fff" }}>
							申请试用 |
						</Link>{" "}
						<Link to="/experience" style={{ color: "#fff" }}>
							体验Demo
						</Link>
						<br />
						<a href="https://beian.miit.gov.cn/#/Integrated/index" style={{ color: "#f6f6f6" }}>
							京ICP备2022020525号
						</a>
					</Footer>
				</div>
			</div>
		);
	} else {
		getAccountInfo().then(res => {
			const { code } = res.data;
			if (code === 200) {
				//token没有失效
				cookieToken(token);
				if (redirect.includes('cartoon.yancecloud.com')) {
					window.location.href = redirect
				} else {
					return <Navigate to={redirect} />;
				}
			} else {
				//token失效，需要重新登陆
				return (
					<div className={loginStyle.login} id="particles-js" ref={ref}>
						<div className={loginStyle.loginPosition}>
							<Header style={{ background: "unset", display: "flex", alignItems: "center", padding: "0 20px" }}>
								<img src="/images/logo.png" alt="" style={{ height: 30 }} />
							</Header>
							<Content className={loginStyle.loginContent}>
								<div className={loginStyle.loginForm}>
									<Tabs activeKey={formType} onChange={onChange} centered>
										<TabPane tab="密码登录" key="PasswordLoginForm">
											{formType === "PasswordLoginForm" && <PasswordLoginForm {...props} />}
										</TabPane>
										<TabPane tab="短信登录" key="MobileLoginForm">
											{formType === "MobileLoginForm" && <MobileLoginForm {...props} />}
										</TabPane>
									</Tabs>
								</div>
							</Content>
							<Footer style={{ textAlign: "center", background: "unset", color: "#fff" }}>
								含光素问 |{" "}
								<Link to="/probation" style={{ color: "#fff" }}>
									申请试用 |
								</Link>{" "}
								<Link to="/experience" style={{ color: "#fff" }}>
									体验Demo
								</Link>
								<br />
								<a href="https://beian.miit.gov.cn/#/Integrated/index" style={{ color: "#f6f6f6" }}>
									京ICP备2022020525号
								</a>
							</Footer>
						</div>
					</div>
				);
			}
		});
	}
};
// 密码登入
const PasswordLoginForm = (props: any) => {
	const navigate = useNavigate();
	const [captchaCode, setCaptchaCode] = useState<string>("");
	const { state, dispatch } = useContext(GlobalContext);
	const [uuid, setUuid] = useState<string>("");

	let location = useLocation()
	const params = new URLSearchParams(location.search);
	let redirect:string = params.get('redirect') || '/'
	// 获取图形验证码
	const getCaptchaCode = async () => {
		try {
			const response = await getImgCode();
			const { code, img, msg, uuid } = response.data;
			if (code === 200) {
				if (!isUnmounted) {
					console.log("未卸载");
					const imgdata = `data:image/png;base64,${img}`;
					setCaptchaCode(imgdata);
					setUuid(uuid);
				}
			} else {
				message.error(msg);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const handleLogin = async (values: loginParams) => {
		// 普通账户登录-用户密码登录
		const hide = message.loading("正在登录");
		try {
			const response = await login(values);
			hide();
			const { code, msg, data } = response.data;
			if (code === 200) {
				if (data && data.token) {
					if (!isAuth(redirect, data)) {
						await getCaptchaCode();
						return false
					}
					return data;
				} else {
					message.error(msg);
				}
			} else {
				message.error(msg);
				getCaptchaCode();
				return false;
			}
		} catch (error) {
			hide();
			console.log("Store Error", error);
		}
	};
	// submit
	const handleSubmit = async (values: loginParams) => {
		const res = await handleLogin({...values, imageCaptchaUuid: uuid});
		if (res && res.token) {
			cookieToken(res.token);
			if (res.firstLogin) navigate('/user')
			else if (redirect.indexOf('http') == 0) {
				window.location.href = redirect
			} else {
				navigate(redirect)
			}
		}
	};
	useEffect(() => {
		isUnmounted = false;
		if (!localStorage.getItem("token")) {
			getCaptchaCode();
		}
		return () => {
			isUnmounted = true;
		};
	}, []);

	useEffect(() => {
		if (localStorage.getItem("token")) {
			const token = localStorage.getItem("token");
			getAccountInfo().then(res => {
				const { code } = res.data;
				if (code === 200) {
					if (redirect.indexOf('http') == 0) {
						cookieToken(token);
						window.location.href = redirect
					} else {
						navigate(redirect);
					}
				}
			});
		}
	}, []);

	return (
		<Form
			name="PasswordLoginForm"
			layout="horizontal"
			onFinish={handleSubmit}
			autoComplete="off"
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 20 }}
		>
			<Form.Item
				label={<span>用户名</span>}
				name="userName"
				rules={[{ required: true, message: "请输入用户名/手机号!" }]}
			>
				<Input placeholder="请输入用户名/手机号" />
			</Form.Item>

			<Form.Item
				label={<span>密&emsp;码</span>}
				name="passWord"
				rules={[{ required: true, message: "请输入密码!" }]}
			>
				<Input.Password placeholder="请输入密码" />
			</Form.Item>

			<Form.Item
				label={<span>校验码</span>}
				name="imageCaptchaCode"
				rules={[{ required: true, message: "请输入图形校验码!" }]}
			>
				<Input
					placeholder="请输入图形校验码"
					addonAfter={
						<Image
							preview={false}
							src={captchaCode}
							className={loginStyle.captcha}
							onClick={() => getCaptchaCode()}
						/>
					}
				/>
			</Form.Item>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<Button
					style={{ marginTop: "10px", width: 200 }}
					type="primary"
					htmlType="submit"
					className="login-form-button"
				>
					登录
				</Button>
			</div>
		</Form>
	);
};

// 短信登入
const MobileLoginForm: React.FC = (props: any) => {
	const { state, dispatch } = useContext(GlobalContext);
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [captchaCode, setCaptchaCode] = useState<string>("");
	const [uuid, setUuid] = useState<string>("");

	let location = useLocation()
	const params = new URLSearchParams(location.search);
	let redirect:string = params.get('redirect') || '/'
	// 短信登入
	const handleSmsLogin = async (values: smsLoginParams) => {
		// 普通账户登录-短息验证码登录
		const hide = message.loading("正在登录");
		try {
			const response = await smsLogin(values);
			hide();
			const { code, msg, data } = response.data;
			if (code === 200) {
				if (data.token) {
					if (!isAuth(redirect, data)) {
						return false
					}
					return data;
				} else {
					return false;
				}
			} else {
				getCaptchaCode(false);
				message.error(msg);
				return false;
			}
		} catch (error) {
			hide();
			console.log("Store Error", error);
			return false;
		}
	};
	// 倒计时
	const [countFlag, setCountFlag] = useState(false);
	const [count, setCount] = useState(60);

	// 处理倒计时
	const handleCount = useCallback(() => {
		let init: number = 60;
		let timer: any = null;
		if (timer) clearInterval(timer);
		timer = setInterval(() => {
			init -= 1;
			if (init > 0) {
				setCount(init);
			} else {
				clearInterval(timer);
				setCountFlag(false);
				setCount(60);
			}
		}, 1000);
	}, []);

	// 获取图形验证码
	const getCaptchaCode = async (isUnmounted: boolean) => {
		try {
			const response = await getImgCode();
			const { code, img, msg, uuid } = response.data;
			if (code === 200 && !isUnmounted) {
				// message.success(msg)
				const imgdata = `data:image/png;base64,${img}`;
				setCaptchaCode(imgdata);
				setUuid(uuid);
			} else {
				message.error(msg);
			}
		} catch (error) {
			console.log(error);
		}
	};

	// 获取短信验证码
	const handleGetSmsCode = async (isUnmounted: boolean) => {
		const mobile = form.getFieldValue("mobile");
		const imageCaptchaCode = form.getFieldValue("imageCaptchaCode");
		try {
			if (mobile) {
				if (imageCaptchaCode) {
					const hide = message.loading("正在获取");
					let response = await getSmsCode({ mobile, imageCaptchaUuid: uuid, imageCaptchaCode });
					if (response.data.code === 200 && !isUnmounted) {
						hide();
						setCountFlag(true);
						message.success(response.data.msg);
						handleCount();
					}
				} else {
					message.error("请先输入图形验证码");
				}
			} else message.error("请先输入手机号");
		} catch (error) {}
	};

	// subimt
	const onMobileLogin = async (values: smsLoginParams) => {
		let result = await handleSmsLogin({...values, imgUuid: uuid});
		console.log(result, "result");

		if (result && result.token) {
			cookieToken(result.token);
			if (result.firstLogin) navigate("/user_oriented");
			else if (redirect.indexOf('http') == 0) {
				window.location.href = redirect
			} else {
				navigate(redirect);
			}
		}
	};

	useEffect(() => {
		let isUnmounted = false;
		getCaptchaCode(isUnmounted);
		return () => {
			isUnmounted = true;
		};
	}, []);

	return (
		<Form
			name="MobileLoginForm"
			form={form}
			layout="horizontal"
			onFinish={onMobileLogin}
			autoComplete="off"
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 20 }}
		>
			<Form.Item label="手机号" name="mobile" rules={[{ required: true, message: "请输入手机号!" }]}>
				<Input placeholder="请输入手机号码" />
			</Form.Item>

			<Form.Item
				label="校验码"
				name="imageCaptchaCode"
				rules={[{ required: true, message: "请输入图形校验码!" }]}
			>
				<Input
					placeholder="请输入图形校验码"
					addonAfter={
						<Image
							preview={false}
							src={captchaCode}
							className={loginStyle.captcha}
							onClick={() => getCaptchaCode(false)}
						/>
					}
				/>
			</Form.Item>

			<Form.Item label="验证码" name="mobileCaptcha" rules={[{ required: true, message: "请输入短信验证码!" }]}>
				<Input
					placeholder="请输入短信验证码"
					addonAfter={
						countFlag ? (
							<div style={{ color: "#999",width: "80px" }}>{count}秒</div>
						) : (
							<div style={{ width: "80px" }} onClick={() => handleGetSmsCode(false)}>
								发送验证码
							</div>
						)
					}
				/>
			</Form.Item>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<Button
					style={{ marginTop: "10px", width: 200 }}
					type="primary"
					htmlType="submit"
					className="login-form-button"
				>
					登录
				</Button>
			</div>
		</Form>
	);
};


// 域登录态，用于其他产品共用登录状态
const cookieToken = (token: string) => {
	cookie.remove("token");
	cookie.save("token", token, { domain: 'yancecloud.com' });
}
// 域登录态，用于其他产品共用登录状态
const getCookieToken = () => {
	console.log(cookie.getToken);
	return cookie.getToken
}
const isAuth = (redirect: string, data: any) => {
	if (redirect.includes('cartoon.yancecloud.com')) {
		if (!data.loginPort.includes('2')) {
			message.error('未开通平台权限，请联系管理员');
			return false;
		}
	} else {
		if (!data.loginPort.includes('1')) {
			message.error('未开通平台权限，请联系管理员');
			return false;
		}
		console.log("登录成功");
		localStorage.setItem("token", data.token);
		localStorage.setItem("isLogin", "true");
		localStorage.setItem("isFirstLogin", data.firstLogin + "");
	}
	cookieToken(data.token);
	return true;
}

export default Login;
