
import { useReducer } from "react"
import { UPDATE_ARTICLE_LIST, UPDATE_ARTICLE_PARAMS } from "./instance"

const initialState = {
    articleList: [],
    params: {
        attitudeAnalysisMediaType: '',
        attitudeFeelType: '',
        keyWord: '',
        mediaClassId: '',
        mediaId: '',
        mediaName: '',
        monitorInfoId: null,
        analysisTime: '',
        monitorTimeList: [],
        pageNum: 1,
        pageSize: 20,
        timeIntervalType:null,
        multimodalLatitude:'',
        selectType:''
    }
}

const reducer = (state, action = { type: null, payload: null }) => {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_ARTICLE_LIST:
            return { ...state, articleList: [...payload] }
        case UPDATE_ARTICLE_PARAMS:
            return { ...state, params: { ...payload } }
        default: return state
    }
}

const Store = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return [state, dispatch]
}

export default Store