import * as echarts from "echarts/core";
import { TreemapChart, TreemapSeriesOption } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { useEffect, useRef } from "react";

echarts.use([TreemapChart, CanvasRenderer]);

type EChartsOption = echarts.ComposeOption<TreemapSeriesOption>;
type TreempChaProps = {
	data: any[];
};

const TreempCha = (props: TreempChaProps) => {
	let myChart;

	const chartsBox = useRef();
	const { data = [] } = props;
	/**
	 * 格式化数据单位
	 * @num
	 */
	// const handleFormatNumber = (num: string | number) => {
	// 	let sourceNumber: number = Number(num);
	// 	let formatResult: any = sourceNumber;
	// 	if (formatResult < 99) {
	// 		formatResult = sourceNumber;
	// 	} else if (sourceNumber > 99 && sourceNumber < 1000) {
	// 		formatResult = (sourceNumber / 1000).toFixed(1) + "k";
	// 	} else if (sourceNumber > 999 && sourceNumber < 100000) {
	// 		formatResult = (sourceNumber / 10000).toFixed(1) + "w";
	// 	}
	// 	return formatResult;
	// };
	useEffect(() => {
		if (myChart !== null && myChart !== "" && myChart !== undefined) {
			myChart.dispose();
		}
		const resizeChart = () => {
			myChart.resize();
		};

		/**
		 * 格式化数据单位
		 * @num
		 */
		const handleFormatNumber = (num: any) => {
			let sourceNumber: number = Number(num);
			let formatResult: any = sourceNumber;
			if (formatResult < 1000) {
				formatResult = sourceNumber;
			} else if (sourceNumber < 10000) {
				formatResult = (sourceNumber / 1000).toFixed(1) + "k";
			} else if (sourceNumber > 10000) {
				formatResult = (sourceNumber / 10000).toFixed(1) + "w";
			}
			return formatResult;
		};

		const colors = [
			"#257ed8",
			"#f08755",
			"#25b07a",
			"#a99dd2",
			"#6196e2",
			"#a9d0fb",
			"#f4a874",
			"#8cdca2",
			"#b4b6b5",
		];

		const option: EChartsOption = {
			tooltip: {
				show: true,
			},
			textStyle: {
				fontSize: "12px",
			},
			series: {
				type: "treemap",
				nodeClick: "link",
				data: data
					?.sort((a, b) => b.value - a.value)
					?.map((item, index) => {
						return { ...item, itemStyle: { color: colors[index] } };
					}),
				sort: true,
				label: {
					formatter: (a) => {						
						if(!data||!data.length) return '';
						var medianNumer = Math.floor(data?.length / 2);
						if (a.dataIndex < (medianNumer + 1)) {
							return a.name + "\n\n" + handleFormatNumber(a.value);
						} else {
							return a.name;
						}
					},
					distance: 0,
					padding: 5,
					position: "inside",
					color: "#fff",
					overflow: "truncate",
				},
				breadcrumb: {
					show: false,
				},
				left: 0,
				right: 0,
				roam: false,
			},
		};
		window.addEventListener("resize", resizeChart);
		myChart = echarts.init(chartsBox.current);
		myChart.setOption(option);
		return () => window.removeEventListener("resize", resizeChart);
	}, [data]);

	return <div ref={chartsBox} style={{ height: "100%" }}></div>;
};

export default TreempCha;
