import { Tag, Select, message } from "antd"
import { HeartOutlined, CaretUpOutlined, CaretDownOutlined, DeleteFilled, HeartFilled, StarFilled, StarOutlined } from "@ant-design/icons"
import classnames from "classnames"
import style from "./index.module.less"
import { getClusterList, updateFeel } from "../../service"
import React, { Fragment, useEffect, useState } from "react"
import { handleAddCollection, handleCancelCollection } from "../../api"
import PubSub from "pubsub-js"
import AccessWrap from "@/access/AccessWrap"

const { Option } = Select;

type ArtProps = {
    media_id?: string
    clickAuthor?: boolean
    onOpen?: any
    highlightTitle?: string
    isCollect?: string
    author_name?: string
    highlightContent?: string
    dispatch?: any,
    highlight?: boolean
    clusterType: string
    clusterId: string
    cluster_count: number
    content_length: number
    monitorInfoId: number
    doc_id: string
    setMenuShow?: any
    title: string
    media_name: string
    pub_date_time: string
    url: string
    media_matrix_name: string
    full_text_sentiment: string
    content: string
    path?: string
    isDel?: boolean
    isSetType?: boolean
    handleArticle: (id: string, monitorInfoId: number) => void
    onDelete: Function
    setAuthorArticleListModalVisible?: Function
    articleUrl?:string
    mediaName?:string
    pubDateTime?:string
}

const ArtItem = (props: ArtProps) => {
    const [firstGet, setFirstGet] = useState<boolean>(true)
    const [polymerization, setPolymerization] = useState<boolean>(false)
    const {
        clickAuthor = true,
        media_id,
        isDel = true,
        isSetType = true,
        path,
        highlightTitle,
        onOpen,
        isCollect,
        onDelete,
        dispatch,
        highlightContent,
        highlight,
        clusterType,
        author_name,
        clusterId,
        content_length,
        doc_id,
        cluster_count,
        handleArticle,
        setMenuShow,
        title,
        media_name,
        pub_date_time,
        media_matrix_name,
        full_text_sentiment,
        content,
        monitorInfoId,
        setAuthorArticleListModalVisible,
        articleUrl,
        mediaName,
        pubDateTime
    } = props;
    const [clusterData, setClusterData] = useState<any>({
        articleList: [],
        pageNum: 1,
        pageSize: 1,
        totalCount: 0
    })
    const [sentiment, setSentiment] = useState(full_text_sentiment ? full_text_sentiment : '0');
    const [collected, setCollected] = useState<boolean>(false);

    /**
     * 点击文章
     */
    const onArtItemClick = async () => {
        if (path) window.open(path, '_blank');
        else {
            await handleArticle(doc_id, monitorInfoId)
            onOpen(true)
        }

    }
    /**
     * 点击合并文章
     * @param id
     */
    const onClusterArtItemClick = async (id) => {
        await handleArticle(id, monitorInfoId)
        onOpen(true)
    }

    /**
     * 合并文章
     * @param params
     */
    const handleClusterArticle = async (params) => {

        try {
            let res = await getClusterList(params);
            const { code, msg, data } = res.data;
            if (code === 200) {
                setFirstGet(false)
                setClusterData({
                    ...data, articleList: [...clusterData.articleList, ...data.articleList]
                })
            }
        } catch (error) {

        }

    }
    /**
     * 获取更多
     */
    const onGetMore = () => {
        const params = {
            articleId: doc_id,
            monitorInfoId,
            clusterType: clusterType,
            clusterId,
            pageNum: clusterData.pageNum + 1,
            pageSize: 8,
        }
        handleClusterArticle(params)
    }
    /**
     * 收藏 操作成功更新icon状态
     */
    const onCollected = () => {
        const res = handleAddCollection({ collectId: doc_id, collectType: '0' });
        if (res) setCollected(true);
    }
    /**
     * 取消收藏  操作成功更新icon状态
     */
    const onCancelCollected = () => {
        const res = handleCancelCollection({ collectId: doc_id, collectType: '0' })
        if (res) setCollected(false);
    }

    const onChange = async (value) => {
        if (!isSetType) return;
        try {
            const response = await updateFeel({ monitorInfoId, articleIdList: [doc_id], attitudeFeelType: value })
            const { code, msg } = response.data;
            if (code === 200) {
                setSentiment(value)
            } else {
                message.error(msg)
            }
        } catch (error) {

        }

    }

    useEffect(() => {
        setCollected(isCollect === "1" ? true : false)
    }, [isCollect])

    return <Fragment>
        {articleUrl ? (
            <div className={style.artItem}>
                <h4 className={style.title} onClick={() => onArtItemClick()} >
                    <span dangerouslySetInnerHTML={{ __html: highlight ? highlightTitle : title }} onClick={()=>{window.open(articleUrl)}}></span>
                </h4>
                <div className={style.artitem_detail}>
                    <span className={style.medianame}>{mediaName}</span>
                    <span className={style.time}>{pubDateTime}</span>
                    <div className={style.tagP}>
                        <Tag>批评</Tag>
                    </div>
                </div>
            </div>
            ) : (
        <div className={style.artItem}>
            {/* 标题 */}
            <h4 className={style.title} onClick={() => onArtItemClick()} >
                <span dangerouslySetInnerHTML={{ __html: highlight ? highlightTitle : title }}></span>
                <span className={style.delete_icon}>
                    <AccessWrap action="event.delete">
                        {isDel && <i
                            className={classnames({
                                ['iconfont']: true,
                                ['icon-shanchu']: true
                            })}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(doc_id)
                            }} />}
                    </AccessWrap>
                </span>
            </h4>
            {/* 描述 */}
            <p className={style.desc} onClick={() => onArtItemClick()} dangerouslySetInnerHTML={{ __html: highlight ? highlightContent : content }}></p>
            {/* 其他信息 */}
            <div className={style.other}>
                <div>
                    <span className={style.source}
                        onClick={() => {
                            if (!clickAuthor) return;
                            setAuthorArticleListModalVisible(media_name, media_id, "MEDIA")
                        }}
                    >{media_name}</span>
                    <span className={style.author} onClick={() => {
                        if (!clickAuthor) return;
                        setAuthorArticleListModalVisible(author_name, null, "AUTHOR")
                    }
                    } >{author_name}</span>
                    <span className={style.time}>{pub_date_time}</span>
                    {content_length ? <span className={style.word}>{content_length}字</span>:""}
                    <span className={style.author}>{media_name === media_matrix_name ? null : media_matrix_name}</span>
                    {/* 0是文章 1是简报 收藏效果 */}
                    {collected ? <StarFilled style={{ color: 'orange' }} onClick={() => onCancelCollected()} /> : <StarOutlined onClick={() => onCollected()} />}
                </div>
                {/* 态度 */}
                 <div className={style.tag}>
                    <Select
                        onChange={onChange}
                        value={sentiment}
                        className={sentiment === '0' ? style.sentimentZx : sentiment === '1' ? style.sentimentZc : style.sentimentP}
                        bordered={false}
                        size="small"
                    >
                        <Option value="0">中性</Option>
                        <Option value="1">赞成</Option>
                        <Option value="-1">批评</Option>
                    </Select>
                    {/* {
                        full_text_sentiment === '0' ?
                            <Tag color={"orange"}>中性</Tag> :
                            full_text_sentiment === '1' ?
                                <Tag color={"green"}>赞成</Tag> :
                                <Tag color={"red"}>批评</Tag>

                    } */}
                    {clusterType !== '0' && cluster_count !== null && cluster_count !== 0 && <span className={style.tag_num} onClick={() => {
                        const params = {
                            articleId: doc_id,
                            monitorInfoId,
                            clusterType: clusterType,
                            clusterId,
                            pageNum: clusterData.pageNum,
                            pageSize: 8,
                        };
                        firstGet && handleClusterArticle(params)
                        setPolymerization(!polymerization)
                    }}>
                        <span className={style.tag_num_count} style={{ background: polymerization ? '#0043e8' : '' }}>{cluster_count}</span>

                        {polymerization ? <CaretUpOutlined /> : <CaretDownOutlined />}

                    </span>}
                </div>


            </div>
        </div>)}

        {/* 合并 */}
        <div className={style.polymerization} style={{ display: polymerization ? 'block' : 'none', marginTop: '-3px' }}>
            {
                clusterData?.articleList?.map((item, index) => {
                    return <ClusterItem {...item} key={index} onClusterArtItemClick={onClusterArtItemClick} />
                })
            }
            {(clusterData.totalCount / clusterData.pageSize) - clusterData.pageNum > 0 ? <div style={{ textAlign: 'center', padding: '4px 0', fontSize: 12 }} onClick={() => onGetMore()}>加载更多</div> : <div style={{ textAlign: 'center', color: '#999', padding: '4px 0', fontSize: 12 }}>没有更多了</div>}
        </div>
    </Fragment>

}

const ClusterItem = (props) => {
    const { doc_id } = props;
    const [collected, setCollected] = useState<boolean>(false);
    /**
     * 收藏 操作成功更新icon状态
     */
    const onCollected = () => {
        const res = handleAddCollection({ collectId: doc_id, collectType: '0' });
        if (res) setCollected(true);
    }
    /**
     * 取消收藏  操作成功更新icon状态
     */
    const onCancelCollected = () => {
        const res = handleCancelCollection({ collectId: doc_id, collectType: '0' })
        if (res) setCollected(false);
    }
    return <div className={style.cluster_item}>
        <h4 onClick={() => props.onClusterArtItemClick(props.doc_id)}>{props.title}</h4>
        <div className={style.otherbox}>
            <div className={style.otherInfo}>
                <span className={style.source}>{props.media_matrix_name}</span>
                <span className={style.author}>{props.author_name}</span>
                <span className={style.time}>{props.pub_date_time}</span>
                <span className={style.word}>{props.content_length}字</span>
                <span className={style.author}>{props.media_name === props.media_matrix_name ? null : props.media_matrix_name}</span>

                <span className={style.star}>
                    {collected ? <StarFilled style={{ color: 'orange' }} onClick={() => onCancelCollected()} /> : <StarOutlined onClick={() => onCollected()} />}
                </span>
            </div>
            {
                props.full_text_sentiment === '0' ?
                    <Tag className={style.sentimentZx}>中性</Tag> :
                    props.full_text_sentiment === '1' ?
                        <Tag color={"green"}>赞成</Tag> :
                        <Tag color={"red"}>批评</Tag>

            }
        </div>

    </div>
}

export default React.memo(ArtItem, (prevProps, nextProps) => {
    return (prevProps.clusterId !== nextProps.clusterId) || (prevProps.doc_id !== nextProps.doc_id) || (prevProps.clusterType !== nextProps.clusterType)
})
