import { useEffect, useRef, useState } from 'react';
import useDraggable from '@/hooks/useDraggable';
import DraggleLayout from '@/components/DraggleLayout';

const Drag = () => {
    const containerRef = useRef()
    const [width, setWidth] = useState(50);
    const [initLeftWidth, setInitLeftWidth] = useState(0);
    const [containerWidth, setContainerWidth] = useState(1200)

    useEffect(() => {
        if (containerRef.current) {
            const initWidth = containerRef.current['offsetWidth'];
            const initLeftWidth = initWidth * 0.5;
            setInitLeftWidth(initLeftWidth)
            setContainerWidth(initWidth)
        }
    }, [])

    return (
        <div style={{ height: '100%', width: '100%' }} ref={containerRef} id="containerRef">
            <DraggleLayout
                containerWidth={containerWidth}
                containerHeight="100%"
                min={containerWidth*0.5}
                max={containerWidth*0.8}
                initLeftWidth={initLeftWidth}
                onWidthChange={w => setWidth(w)}
                handler={
                    <div
                        style={{
                            width: 4,
                            height: '100%',
                            background: 'rgb(77, 81, 100)',
                        }}
                    />
                }
            >
                <div
                    style={{
                        backgroundColor: `rgb(36, 205, 208)`,
                        color: `#fff`,
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    left
                </div>
                <div
                    style={{
                        backgroundColor: `rgb(116, 140, 253)`,
                        color: `#fff`,
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    right
                </div>
            </DraggleLayout>
        </div>
    )
}

export default Drag