import * as echarts from "echarts/core";
import { TooltipComponent, TooltipComponentOption, LegendComponent, LegendComponentOption } from "echarts/components";
import { PieChart, PieSeriesOption } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { useEffect, useRef, useState } from "react";
import { emotion } from "./colors"
echarts.use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer, LabelLayout]);

type EChartsOption = echarts.ComposeOption<TooltipComponentOption | LegendComponentOption | PieSeriesOption>;

type PieProps = {
	resize?: any;
	data: any[];
	onClick?: Function;
	legend?: boolean;
	labelLine?: boolean;
	label?: boolean;
	name?: string;
	colors?: any;
	radius?: any[]
};

const Pie = (props: PieProps) => {
	const [dataImg, setDataImg] = useState();
	const chartRef = useRef();
	const {
		resize,
		onClick,
		data = [],
		legend = true,
		labelLine = true,
		label = true,
		name,
		radius = ["30%", "55%"],
		colors = ["#b4b6b5", "#8cdca2", "#f08755"],
	} = props;

	useEffect(() => {
		let myChart;
		if (myChart !== null && myChart !== "" && myChart !== undefined) {
			myChart.dispose();
		}
		const option: EChartsOption = {
			tooltip: {
				trigger: "item",
			},
			legend: {
				show: legend,
				bottom: "0%",
				left: "center",
			},
			series: [
				{
					name,
					type: "pie",
					radius,
					avoidLabelOverlap: false,
					itemStyle: {
						borderRadius: 0,
						borderColor: "#fff",
						borderWidth: 3,
					},
					label: {
						show: label,
						position: "outside",
						width: 60,
						formatter: (a) => {
							return a.name+' '+a.value;
						},
					},
					emphasis: {
						label: {
							show: label,
							fontSize: "12",
							fontWeight: "bold",
						},
					},
					labelLine: {
						show: labelLine,
					},
					data: data?.map((item, index) => {
						return {
							...item,
							itemStyle: {
								color: item?.color ? item?.color : colors[index]
							}
						};
					}),
				},
			],
		};
		myChart = echarts.init(chartRef.current);
		myChart.off("click");
		myChart.on("click", (params) => onClick(params));
		const resizeChart = () => {
			myChart.resize();
		};
		myChart.setOption(option);

		setDataImg(myChart.getDataURL());
		window.addEventListener("resize", resizeChart);
		if (resize || !resize) resizeChart();
		return () => window.removeEventListener("resize", resizeChart);
	}, [props, resize]);

	return (
		// <div style={{ height: "100%" }}>

		<div ref={chartRef} style={{ height: "100%" }}></div>
	);
};

export default Pie;
