import * as echarts from "echarts/core";
import { GridComponent, GridComponentOption, MarkPointComponent } from "echarts/components";
import { LineChart, LineSeriesOption } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { useEffect, useRef } from "react";
echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition, MarkPointComponent]);

type EChartsOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption>;

type LineProps = {
	resize?: any;
	xaxis: any[];
	yaxis: any[];
	data: any[];
	newData?: any[];
	onClick?: Function;
	value?: any;
	mediaKindData?: any;
	flagData?: any;
};
let myChart;

const Line = (props: LineProps) => {
	const chartRef = useRef();
	const { xaxis = [], yaxis = [], data = [], resize, onClick, flagData } = props;
	useEffect(() => {
		if (myChart !== null && myChart !== "" && myChart !== undefined) {
			myChart.dispose();
		}
		const option = {
			// 图表图例
			legend: {
				// orient: "vertical",
				data: data?.map((item) => {
					return item?.title;
				}),
				top: 20,
				x: "center",
				y: "top",
			},
			// 移入之后显示
			tooltip: {
				borderWidth: 0,
				padding: 0,
				formatter: (params) => {
					const { data } = params;
					let content;
					// 插旗
					if (params?.componentType === "markPoint") {
						content = `
            			<div style="
             				width:320px;
              				padding:10px 20px 5px 20px;
              				box-shadow: 0px 6px 15px 0px rgba(84,84,84,0.65); 
              				border-radius: 11px;
              				background: #FFFFFF;
              				font-size: 13px;
							display:flex;
							flex-direction: column;
							justify-content: space-around;
							font-family: Noto Sans S Chinese;
            			">
              				<div style="
                				color:#808080;
                				background: #d1d1d160;
                				border-radius: 0px 11px 11px 0px;
                				text-align:center;
                				padding:0px 10px 0px 10px;
								width:160px;
                			">
								${data?.date}
							</div>
							<div style="
								color:#DB5633;
                				overflow:hidden;
                				text-overflow:ellipsis;
								margin:4px 0 ;
                			">
								● 关键词：${data?.keywords}
							</div>
							<div style="border-top:1px solid rgba(185,201,255,0.98)";></div>
              				<div style="
								white-space:normal;
								margin:4px 0 ;
              				">
								${data?.articleTitle}
              				</div>	
            			</div>
            `;
					}
					// 线
					if (params?.componentType === "series" && data?.value !== 0) {
						content = `
						<div style="
							width:320px;
							padding:10px 20px 4px 20px;
							box-shadow: 0px 6px 15px 0px rgba(84,84,84,0.65);
							border-radius: 11px;
							background: rgba(255,255,255) ;
							font-size: 13px;
							display:flex;
							flex-direction: column;
							justify-content: space-around;
							font-family: Noto Sans S Chinese;
						">
						<div style="
							color:#808080;
							width: 120px;
							height: 25px;
							background: #D1D1D160;
							border-radius: 0px 11px 11px 0px;
							text-align:center;
							line-height:25px;
							display:table;
							padding:0px 10px 0px 10px;
						">
							${data?.date}
						</div>
						<div style="color:#0085D6;margin:2px 0;">● 总声量：${data?.value}</div>
						<div style="
							color:#DB5633;
							text-overflow:ellipsis;
							overflow:hidden;
							margin:2px 0;
						">
							● 关键词：${data?.keywords ? data?.keywords : ""}
						</div>
						<div style="border-top:1px solid rgba(185,201,255,0.98)";></div>
						<div style="color:#808080;white-space:normal;margin:4px 0 ;" >${data?.title ? data?.title : ""}</div>
					</div>
              `;
					}
					return content;
				},
			},
			xAxis: {
				type: "category",
				data: xaxis,
			},
			yAxis: {
				type: "value",
				data: yaxis,
				axisLabel: {
					formatter: function (value, index) {
						var value;
						if (value >= 1000 && value <= 9999) {
							value = value / 1000 + "k";
						} else if (value < 1000) {
							value = value;
						} else if (value >= 10000) {
							value = value / 10000 + "w";
						}
						return value;
					},
				},
				// max: Math.max(yaxis),
			},
			animation: true,
			grid: {
				top: 60,
				left: 40,
				right: 20,
				bottom: 20,
			},
			series: data?.map((item) => {
				return {
					name: item?.title,
					type: "line",
					data: item?.periodDiagram?.lineData,
					symbolSize: 16,
					symbol: "pin",
					// 标注
					markPoint: {
						symbolSize: 20,
						symbol: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAxCAYAAACyGwuwAAAACXBIWXMAABcRAAAXEQHKJvM/AAACRUlEQVRYw+2Yv0sbYRyHnwvJ0JJe0utgJIg3SHXRZAiEbCFIhzq0gotL7XCrUNQ/oP9BxUEc9C9QaDrYSY5zURCHi2BHiVDxBxhCEuwQ8e3QSxrzO2k0Qt8PvNzd+94dDy9fju9zihAiCbzjfr4B7wHdOcYBP7WxnWEBaXoR0TiW6CxpIcSyECIshKDboQghBMB3RQHg7Z/Lcq5Mk+u9PXKp1L15j9+POjGBFouhjo3h9norl0+Bz0ASyHayQTVAb/J53F4vV6bJj8VFbmy7rRcFDIOBqSkGJif/wgmRQ1G+OmBWE7iwUxpWDVDAMLg5OSFnml2VgCcYJDA7y8jCAs8GB6uXT+vUWhjwOee7NUC9jJpIMDQ3hxaN8mJ0tJ1HHhaoOq9mZmrmipkMHk0jurkJsOvmEXO9tdUS1MUTiwSSQBJIAkkgCSSBJND/DtSyY/QEg6ixGGoohMfnK8/njo4oZrMNu8CeAw0tLaEbRlvNeebwkMz+PhfJZNe20hBITSQIra5Wg6QcZS4pjN/RFx0Y1iIRtEiEkfl5fp2fk97Y4OfaGsWzs46BGoqiI3lfgOUW9ukv+78Q0yiKCnBbKHC5s8Pl9jYX6+stbaRkHc1U+mWnGlwB9wkIlSZvCwUyBwfkjo/LtQfwXNdRx8crva0p0L+KWtgBiwPDLX963N3lFZfrw0N6mQ18rICLOzUXrrrPAmzF5bKA7GOJYuk/kvxSSyAJJIEkkASSQH1r8l+vrDwJoHKDVm+tXzs0Xadpsvu1Q78BygJL7ZgSLVAAAAAASUVORK5CYII=",
						data: flagData,
						label: {
							show: false,
						},
					},
					itemStyle: {
						color: item.color,
					},
				};
			}),
		};

		myChart = echarts.init(chartRef.current);
		const resizeChart = () => {
			myChart.resize();
		};
		myChart.off("click");
		myChart.on("click", (params) => onClick(params, "Linegram"));
		myChart.setOption(option);
		window.addEventListener("resize", resizeChart);
		if (resize || !resize) resizeChart();
		return () => window.removeEventListener("resize", resizeChart);
	}, [props, resize]);

	return <div ref={chartRef} style={{ height: "100%" }}></div>;
};

export default Line;
