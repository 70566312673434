import { Empty } from "antd";
import style from "./index.module.less";
const Nodata = ({
	description = "系统正在匹配数据，请稍后查看",
	fontSize = 14,
}: {
	description?: any;
	fontSize?: number;
}) => {
	return (
		<Empty
			className={style.nodata}
			description={<span style={{ fontSize }}>{description}</span>}
			image={Empty.PRESENTED_IMAGE_SIMPLE}
			style={{
				width: "100%",
				height: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				margin: 0,
			}}
		/>
	);
};
export default Nodata;
