import { useEffect, useState, useContext } from "react";
import style from "./index.module.less";
import GraphChart from "@/components/Charts/GraphChart";
import { Row, Col, Button, Select, Form, message, Spin, Tooltip } from "antd";
import Linegram from "@/components/Charts/Linegram";
import { GlobalContext } from "@/GlobalContext";
import MySelect from "../../../components/MySelect";
import MediaSelect from "../../../components/MediaSelect";
import { SearchOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Nodata from "@/components/NodataSmall";
import Loading from "@/components/Loading";
import {
	eventHotWordRelationMap,
	getEmotionPath,
	eventMediaVolumeAnalyze,
	findMediaByMediaName,
	selectSpecialMonitorList,
	eventExplain,
} from "@/pages/Workbench/service";
import MyForceDiagram from "@/components/Charts/MyForceDiagram";
import { useNavigate } from "react-router";
import png1 from "../../PNG/1.png";
import { WorkContext } from "../..";
import { UPDATE_ARTICLE_PARAMS } from "../../redux/instance";
import moment from "moment";

export default function App(props) {
	const { isShow, setIsShow, setFilterText, current, setEventMonitorList } = props;
	const navigate = useNavigate();
	const { Option } = Select;
	const { state } = useContext(GlobalContext);
	const [monitorList, setMonitorList] = useState<any>([]);
	const [monitorIdData, setMonitorIdData] = useState();
	const [spreadDiagram, setSpreadDiagram] = useState<any>();
	const [mediaSelect, setMediaSelect] = useState<any>();
	const [loadingLinegram, setLoadingLinegram] = useState(false);
	const {
		state: { params },
		dispatch,
	} = useContext(WorkContext);
	// 关键词关系
	const [keywordRelationship, setKeywordRelationship] = useState<any>([]);
	let time = null;
	const [form] = Form.useForm();
	// 当日，前一日，前一周
	const [contrastSection, setContrastSection] = useState("1");
	// 选择的媒体类型
	const [mediaClassIds, setMediaClassIds] = useState([]);
	// 媒体声量分布数据
	const [mediaVolumeData, setMediaVolumeData] = useState<any>();
	// 标注
	const [flagData, setFlagData] = useState([]);
	// 媒体id
	const [mediaId, setMediaId] = useState();
	// 媒体
	const [mediaList, setMediaList] = useState<any>();
	// 分析概要数据
	const [analysisData, setAnalysisData] = useState<any>();

	// 获取事件监测方案
	const getMonitorListHandler = async () => {
		const res = await selectSpecialMonitorList();
		const { code, msg, data } = res.data;
		if (code === 200) {
			setMonitorList(data);
			setEventMonitorList(data);
			setMonitorIdData(data?.[0]?.monitorInfoId);
			form.setFieldsValue({
				monitorInfoId: data?.[0]?.monitorInfoId,
			});
		} else {
			message.error(msg);
		}
	};
	// 详情分析跳转
	const jumpDetail = () => {
		navigate("/monitor/panel", { state: { monitorIdData } });
	};
	// 监测方案改变
	const changeMonitor = (value) => {
		console.log(value);
		setMonitorIdData(value);
		form.setFieldsValue({
			monitorInfoId: value,
		});
		setIsShow(false);
		dispatch({
			type: UPDATE_ARTICLE_PARAMS,
			payload: { ...state.params, monitorInfoId: value },
		});
	};
	// 重要态度传播路径
	const getEmotionPathHandler = async (id) => {
		if (!id) {
			return false;
		}
		const res = await getEmotionPath({ monitorInfoId: id });
		const { code, data, msg } = res.data;
		if (code === 200) {
			setSpreadDiagram(data?.spreadDiagram);
		} else {
			message.error(msg);
		}
	};
	// 根据媒体名称模糊搜索
	const findMediaByMediaNameHandler = async (value) => {
		const mediaName = value?.nativeEvent?.data;
		if (time !== null) {
			clearTimeout(time);
		}
		if (value === "") return;
		time = setTimeout(async () => {
			const res = await findMediaByMediaName(value);
			const { code, msg, data } = res.data;
			if (code === 200 && data) {
				// setTaggingData()
				setMediaList(data);
			} else {
				message.error(msg);
			}
		}, 500);
	};

	// 获取折线图数据
	const eventMediaVolumeAnalyzeHandler = async () => {
		if (!monitorIdData) return false;
		setLoadingLinegram(true);
		const res = await eventMediaVolumeAnalyze({
			monitorInfoId: monitorIdData,
			contrastSection,
			mediaClassIds,
			mediaId,
		});
		const { msg, code, data } = res.data;
		if (code === 200) {
			setMediaVolumeData(data?.analyzeInfoList);
			setFlagData(data?.flagData);
			setLoadingLinegram(false);
		} else message.error(msg);
	};
	// 事件热词联系图
	const eventHotWordRelationMapHandler = async () => {
		if (!monitorIdData) return false;
		const res = await eventHotWordRelationMap({
			monitorInfoId: monitorIdData,
		});
		const { code, msg, data } = res.data;
		if (code === 200) {
			setKeywordRelationship(data);
		}
	};
	// 分析概要
	const eventExplainHandler = async () => {
		if (!monitorIdData) return false;
		const res = await eventExplain({ monitorInfoId: monitorIdData });
		const { code, msg, data } = res.data;
		if (code === 200) {
			setAnalysisData(data);
		} else {
			message.error(msg);
		}
	};

	// 跳转新建专项监测
	const jumpCreateMonitor = () => {
		navigate("/monitor/create");
	};
	// chartsHandler
	const chartsHandler = (data, key) => {
		const newParams = {
			attitudeAnalysisMediaType: "",
			attitudeFeelType: "",
			keyWord: "",
			mediaClassId: "",
			mediaId: "",
			mediaName: "",
			timeIntervalType: "",
			monitorInfoId: "",
			analysisTime: "",
			monitorTimeList: [],
			volumeTime: "",
			pageNum: 1,
			pageSize: 20,
		};
		setEventMonitorList(monitorList);
		setIsShow(true);
		switch (key) {
			case "Linegram":
				setFilterText(data?.data?.date);
				const analysisTime = moment(data?.data?.date)
				let mediaClassId
				data?.seriesName==="上期趋势"||data?.seriesName==="当前趋势" ?mediaClassId="":mediaClassId = data?.data?.mediaClassId;
				console.log(data,"mediaClassId");
				let timeIntervalType;
				if (contrastSection === "1") {
					timeIntervalType = "1";
				} else if (contrastSection === "2") {
					timeIntervalType = "1";
				} else if (contrastSection === "3") {
					timeIntervalType = "2";
				}
				dispatch({
					type: UPDATE_ARTICLE_PARAMS,
					payload: {
						...newParams,
						analysisTime,
						monitorInfoId: monitorIdData,
						mediaClassId,
						timeIntervalType: timeIntervalType,
					},
				});
				break;
			case "MyForceDiagram":
				setFilterText(data?.name);
				dispatch({
					type: UPDATE_ARTICLE_PARAMS,
					payload: {
						...newParams,
						monitorInfoId: monitorIdData,
						mediaId: data?.data?.mediaId,
					},
				});
				break;
			case "GraphChart":
				setFilterText(data?.data?.name);
				dispatch({
					type: UPDATE_ARTICLE_PARAMS,
					payload: {
						...newParams,
						keyWord: data?.data?.name,
						monitorInfoId: monitorIdData,
					},
				});
				break;
			default:
				break;
		}
	};
	const NoDataStyle = () => {
		if (monitorList?.length === 0) return { height: 140 };
	};
	useEffect(() => {
		getMonitorListHandler();
	}, []);
	useEffect(() => {
		setContrastSection(state?.dict?.contrastSectionEnum?.[0]?.value);
		setMediaSelect(state?.dict?.mediaType?.slice(1));
	}, [state]);
	useEffect(() => {
		eventMediaVolumeAnalyzeHandler();
	}, [monitorIdData, contrastSection, mediaClassIds, mediaId]);
	useEffect(() => {
		getEmotionPathHandler(monitorIdData);
		eventExplainHandler();
		eventHotWordRelationMapHandler();
	}, [monitorIdData]);
	return (
		<div className={style.content} style={NoDataStyle()}>
			<div style={{ display: "flex" }}>
				{/* 标题 */}
				<div style={{ display: "flex" }}>
					<img src={png1} alt="" style={{ width: 18, height: 18, marginRight: 10, marginTop: 8 }} />
					<Tooltip placement="top" title="活动、品牌专项监测全方面跟踪传播过程中趋势变化">
						<div className={style.title}>专项监测</div>
					</Tooltip>
				</div>
				{/* 事件监测方案 */}
				{monitorList?.length !== 0 && (
					<Form form={form}>
						<Form.Item
							name="monitorInfoId"
							style={{ width: 160, marginRight: 20 }}
							initialValue={monitorList?.[0]?.monitorInfoId}
						>
							<Select
								dropdownClassName={style.content_select}
								className={style.select}
								options={monitorList}
								getPopupContainer={(triggerNode) => triggerNode.parentElement}
								onChange={(value) => {
									changeMonitor(value);
								}}
								fieldNames={{
									label: "monitorName",
									value: "monitorInfoId",
								}}
							/>
						</Form.Item>
					</Form>
				)}
				{/* 时间段 */}
				{monitorList?.length !== 0 && (
					<MySelect
						options={state?.dict?.contrastSectionEnum}
						defaultValue={state?.dict?.contrastSectionEnum?.[0]?.value}
						setData={setContrastSection}
					/>
				)}
			</div>
			{monitorList?.length === 0 ? (
				<Nodata
					description={
						<span>
							您尚未创建专项监测，您可以通过
							<span
								onClick={jumpCreateMonitor}
								style={{ color: "#DB5633", fontWeight: 700, cursor: "pointer" }}
							>
								{" "}创建监测{" "}
							</span>
							创建专项监测对象
						</span>
					}
				/>
			) : (
				<div>
					{/* 媒体声量趋势 */}
					<Row style={{ height: 500 }} className={style.content_top}>
						<div className={style.content_top_bottom}>
							<div className={style.content_top_left}>
								<Tooltip placement="top" title="本图表展示媒体传播声量趋势及同期对比变化">
									<div className={style.content_title} style={{width:142}}>媒体声量趋势</div>
								</Tooltip>

								{/* 媒体大类 */}
								<Form.Item
									initialValue=""
									className={style.content_top_mediaList}
									style={{ minWidth: 300 }}
								>
									<MediaSelect
										options={mediaSelect}
										setData={setMediaClassIds}
										mode="multiple"
										placeholder="请选择媒体类型"
									/>
								</Form.Item>
								{/* 媒体名称 */}
								<Form.Item>
									<Select
										className={style.mediaTitle}
										showSearch
										suffixIcon={<SearchOutlined />}
										showArrow={true}
										style={{ width: 200, marginLeft: 20, height: 34 }}
										placeholder="请输入媒体名称"
										optionFilterProp="children"
										onSearch={findMediaByMediaNameHandler}
										getPopupContainer={(triggerNode) => triggerNode.parentElement}
										onChange={(value) => {
											setMediaId(value);
										}}
										filterOption={(input, option) =>
											(option!.children as unknown as string).includes(input)
										}
										filterSort={(optionA, optionB) =>
											(optionA!.children as unknown as string)
												.toLowerCase()
												.localeCompare((optionB!.children as unknown as string).toLowerCase())
										}
									>
										{mediaList?.map((item) => {
											return <Option value={item?.mediaId}>{item?.mediaName}</Option>;
										})}
									</Select>
								</Form.Item>
							</div>
							{/* 时间趋势 */}
							<div className={style.timequshi}>
								<div style={{ color: mediaVolumeData?.[0]?.color }}>
									{mediaVolumeData?.[0]?.trendDesc}
								</div>
								<div style={{ color: mediaVolumeData?.[1]?.color }}>
									{mediaVolumeData?.[1]?.trendDesc}
								</div>
							</div>
							<div>
								<Button type="primary" onClick={jumpDetail} className={style.btnDetail}>
									详情分析
								</Button>
							</div>
						</div>
						<Col span={24} style={{ height: 400 }}>
							{/* {mediaVolumeData === undefined ? ( */}
							{mediaVolumeData === null ? (
								<Nodata description="暂无声量趋势数据" />
							) : (
								<>
									{loadingLinegram && <Spin className={style.loading} />}
									<Linegram
										data={mediaVolumeData}
										xaxis={mediaVolumeData?.[0]?.periodDiagram?.dayList}
										yaxis={[]}
										flagData={flagData}
										onClick={chartsHandler}
									/>
								</>
							)}
						</Col>
					</Row>
					{/* 媒体传播路径 关键词密切度 */}
					<Row style={{ height: 540 }} className={style.content_bottom}>
						<Col span={14}>
							<div className={style.content_botton_left}>
								<Tooltip placement="top" title="本图表展示媒体传播衍生路径">
									<div className={style.content_title} style={{ width: 142, margin: 10 }}>
										媒体传播路径
									</div>
								</Tooltip>

								<div className={style.content_botton_left_echarts}>
									{spreadDiagram === undefined ? (
										<Loading />
									) : spreadDiagram?.nodes.length === 0 ? (
										<Nodata description="暂无媒体传播路径数据" />
									) : (
										<MyForceDiagram
											links={spreadDiagram?.links}
											nodes={spreadDiagram?.nodes}
											categories={spreadDiagram?.categories}
											onClick={chartsHandler}
										/>
									)}
								</div>
							</div>
						</Col>
						<Col span={10}>
							<div className={style.content_botton_right}>
								<div className={style.content_botton_right_header}>
									<Tooltip placement="top" title="本图表展示入文文章提及热词密切关系">
										<div className={style.content_title} style={{ width: 142, margin: 10 }}>
											关键词密切度
										</div>
									</Tooltip>
									<span>
										<InfoCircleOutlined style={{ marginRight: 5 }} />
										关键词从上到下词频由高到低
									</span>
								</div>

								<div className={style.content_botton_right_echarts}>
									{keywordRelationship?.length === 0 ||
									keywordRelationship?.keyWordsData?.length === 0 ? (
										<Nodata />
									) : (
										<GraphChart
											links={keywordRelationship?.linksData}
											nodes={keywordRelationship?.keyWordsData}
											categories={keywordRelationship?.keyWordsList}
											onClick={chartsHandler}
										/>
									)}
								</div>
							</div>
						</Col>
					</Row>
					{/* 分析概要 处置建议 */}
					{/*<Row className={style.boxStyle}>
						<Col span={24}>
							<Tooltip placement="top" title="综合信息分析结论">
								<div className={style.content_title} style={{ width: 120, marginBottom: 10 }}>
									分析概要
								</div>
							</Tooltip>

							<div className={style.item_content_title_box}>
								{analysisData?.articleTotal === 0 ? (
									<Nodata description="此监测方案暂无分析概要数据" />
								) : (
									<span className={style.item_content_detail}>{`本事件在${
										analysisData?.analysisTimeList[0] + "-" + analysisData?.analysisTimeList[1]
									}期间，监测到${
										analysisData?.articleTotal
									}条信息，在整个传播过程中，传播热度较高的文章是《${
										analysisData?.articleList?.[0]?.articleTitle
									}》，信息主要发布于${analysisData?.releaseMedia?.map((item) => {
										return item;
									})}等媒体，并于${analysisData?.articleMaxCountDate}达到传播高峰。`}</span>
								)}
							</div>
						</Col>
					</Row>*/}
				</div>
			)}
		</div>
	);
}
