import * as echarts from "echarts/core";
import { useEffect, useRef, useState } from "react";
import {
	TitleComponentOption,
	TooltipComponent,
	LegendComponent,
	LegendComponentOption,
	TitleComponent,
} from "echarts/components";
import { GraphChart, GraphSeriesOption } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([TitleComponent, TooltipComponent, LegendComponent, GraphChart, CanvasRenderer]);

export default function App(props) {
	const { nodes = [], links = [], categories = [], onClick, resize } = props;
	const chartsBox = useRef();
	const [newNodeDara, setNewNodeDara] = useState([]);
	interface GraphNode {
		symbolSize: number;
		label?: {
			show?: boolean;
		};
	}
	// const nodeHandler = () => {
	// 	const nodeOne = nodes.slice(0, 4);
	// 	const nodeTwo = nodes.slice(4, 14);
	// 	const newNode = [...nodeTwo, ...nodeOne];
	// 	setNewNodeDara(newNode);
	// 	console.log(newNode, "nodeone");
	// };
	let newNodes = new Array(14);

	nodes?.forEach((item, index) => {
		if (!(index + 10 >= 14)) {
			newNodes[index + 10] = item;
		} else {
			newNodes[index + 10 - 14] = item;
		}
	});
	let myChart;
	useEffect(() => {
		if (myChart != null && myChart != "" && myChart != undefined) {
			myChart.dispose(); //销毁
		}
		myChart = echarts.init(chartsBox.current);

		const option = {
			tooltip: {
				show: false,
			},
			animationDurationUpdate: 1500,
			animationEasingUpdate: "quinticInOut",
			series: [
				{
					name: "",
					type: "graph",
					layout: "circular",
					circular: {
						rotateLabel: false,
					},
					roam: false, // 鼠标滚轮放大缩小
					data: newNodes,
					// data: [{}],
					links: links,
					categories: categories,

					label: {
						show: true,
						formatter: (v) => {
							return `{b${v.dataIndex}|${v.name}}`;
						},
						// 每个字的颜色
						rich: {
							b0: {
								color: "#fff",
							},
							b1: {
								color: "#fff",
							},
							b2: {
								color: "#417dd2",
							},
							b3: {
								color: "#417dd2",
							},
							b4: {
								color: "#417dd2",
							},
							b5: {
								color: "#fff",
							},
							b6: {
								color: "#fff",
							},
							b7: {
								color: "#cb5e3c",
							},
							b8: {
								color: "#fff",
							},
							b9: {
								color: "#fff",
							},
							b10: {
								color: "#fff",
							},
							b11: {
								color: "#fff",
							},
							b12: {
								color: "#fff",
							},
							b13: {
								color: "#cb5e3c",
							},
						},
						color: "#fff",
						overflow: "break",
						fontSize: 13,
						align: "center",
						// distance: -30,
						width: 40,
						// rotate: -102,
					},
					lineStyle: {
						color: "source",
						curveness: 0.3,
					},
					colorBy: "series",
					// color: [
					// 	"#cb5e3c",
					// 	"#dc7c6a",
					// 	"#df9e89",
					// 	"#eec8c0",
					// 	"#417dd2",
					// 	"#6d95dc",
					// 	"#c3d5ff",
					// 	"#dde3f2",
					// 	"#c3d5ff",
					// 	"#6d95dc",
					// 	"#417dd2",
					// 	"#eec8c0",
					// 	"#df9e89",
					// 	"#dc7c6a",
					// ],
					color: [
						"#db5633",
						"#f08755",
						"#f4a874",
						"#f1ccb1",
						"#257ed8",
						"#6196e2",
						"#aecaf8",
						"#d2e5ff",
						"#aecaf8",
						"#6196e2",
						"#257ed8",
						"#f1ccb1",
						"#f4a874",
						"#f08755",
					],
				},
			],
		};
		// transform: "rotateZ(257deg)"
		myChart.off("click");
		myChart.on("click", (params) => {
			if(params?.dataType==="edge")return
			onClick(params, "GraphChart")
		});
		const resizeChart = () => {
			myChart.resize();
		};
		myChart.setOption(option);
		window.addEventListener("resize", resizeChart);
		if (resize || !resize) resizeChart();
		return () => window.removeEventListener("resize", resizeChart);
	}, [props, resize]);
	return <div ref={chartsBox} style={{ height: "100%" }}></div>;
}
