import { useEffect, useState, useContext } from "react";
import { Tag, Row, Col, Form, message, Select, Tooltip } from "antd";
import style from "./index.module.less";
import { InfoCircleOutlined, DownOutlined, CaretDownFilled, QuestionCircleFilled } from "@ant-design/icons";
import VolumeLinegram from "@/components/Charts/VolumeLinegram";
import Loading from "@/components/Loading";
import BasicKeyWords from "@/components/Charts/BasicKeyWords";
import moment from "moment";
import Nodata from "@/components/NodataSmall";
import png1 from "../../PNG/1.png";
import { WorkContext } from "../..";
import { selectEventMonitor, eventDevelopAnalyze } from "@/pages/Workbench/service";
import { UPDATE_ARTICLE_PARAMS } from "../../redux/instance";
import { GlobalContext } from "@/GlobalContext";
import Store from "../../redux";
import { useNavigate } from "react-router";

export default function App(props) {
	const { isShow, setIsShow, setFilterText, current, setEventMonitorList } = props;
	const [monitorList, setMonitorList] = useState<any>([]);
	const [monitorIdData, setMonitorIdData] = useState<any>();
	const [eventContextData, setEventContextData] = useState<any>();
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);
	const { dataList } = eventContextData || {};
	const [state] = Store();
	const navigate = useNavigate();
	const {
		state: { dict },
	} = useContext(GlobalContext);
	const {
		state: { params },
		dispatch,
	} = useContext(WorkContext);

	// 获取事件监测方案
	const getMonitorListHandler = async () => {
		const res = await selectEventMonitor();
		const { code, msg, data } = res.data;
		if (code === 200) {
			setMonitorList(data);
			setMonitorIdData(data?.[0]?.monitorInfoId);
			form.setFieldsValue({
				monitorInfoId: data?.[0]?.monitorInfoId,
			});
			setEventMonitorList(data);
			// dispatch({
			// 	type: UPDATE_ARTICLE_PARAMS,
			// 	payload: { ...state.params, monitorInfoId: data[0].monitorInfoId },
			// });
		} else {
			message.error(msg);
		}
	};
	// 获取事件脉络分析
	const eventDevelopAnalyzeHandler = async (id) => {
		setIsLoading(true);
		if (!id) return;
		const res = await eventDevelopAnalyze({ monitorInfoId: id });
		const { code, data, msg } = res.data;
		if (code === 200) {
			setEventContextData(data);
			setIsLoading(false);
		} else {
			setIsLoading(false);
			message.error(msg);
		}
	};
	// 监测方案改变
	const changeMonitor = (value) => {
		setMonitorIdData(value);
		form.setFieldsValue({
			monitorInfoId: value,
		});
		setIsShow(false);
		dispatch({
			type: UPDATE_ARTICLE_PARAMS,
			payload: { ...state.params, monitorInfoId: value },
		});
	};
	// 跳转事件
	const jumpCreateEvent = () => {
		navigate("/event/create");
	};
	// chartsHandler
	const chartsHandler = (data, key) => {
		if (key === "openUrl") return window.open(data?.data?.articleUrl);
		const newParams = {
			attitudeAnalysisMediaType: "",
			attitudeFeelType: "",
			keyWord: "",
			mediaClassId: "",
			mediaId: "",
			mediaName: "",
			// timeIntervalType: params.timeIntervalType,
			// monitorInfoId: params.monitorInfoId,
			monitorInfoId: "",
			timeIntervalType: "",
			analysisTime: "",
			monitorTimeList: [],
			pageNum: 1,
			pageSize: 20,
		};
		setEventMonitorList(monitorList);
		setIsShow(true);
		switch (key) {
			case "volume":
				setFilterText(data?.data?.date);
				const volumeTime = data?.data?.date;
				dispatch({
					type: UPDATE_ARTICLE_PARAMS,
					payload: { ...newParams, volumeTime, monitorInfoId: monitorIdData },
				});
				break;
			case "keyWords":
				setFilterText(data?.data?.[3]?.[0]);
				const keyWord = data?.data?.[3]?.[0];
				dispatch({
					type: UPDATE_ARTICLE_PARAMS,
					payload: { ...newParams, keyWord, monitorInfoId: monitorIdData },
				});
				break;

			default:
				break;
		}
	};
	const NoDataStyle = () => {
		if (monitorList?.length === 0) return { height: 140 };
		if (eventContextData?.periodDiagram?.articleTotal === 0) return { height: 180 };
	};

	useEffect(() => {
		getMonitorListHandler();
	}, []);
	useEffect(() => {
		eventDevelopAnalyzeHandler(monitorIdData);
	}, [monitorIdData]);
	return (
		<div className={style.content} style={NoDataStyle()}>
			<div className={style.content_header}>
				<div className={style.content_header_title}>
					<img src={png1} alt="" style={{ width: 20, height: 20, marginRight: 10 }} />
					<Tooltip placement="top" title="多维度事件分析简报洞察事件传播路径、脉络及影响力">
						<div className={style.title}>事件分析</div>
					</Tooltip>
				</div>
				{monitorList?.length !== 0 && (
					<Form form={form} style={{ display: "flex" }}>
						<Form.Item
							name="monitorInfoId"
							style={{ width: 160, height: 30, marginRight: 20 }}
							initialValue={monitorList?.[0]?.monitorInfoId}
						>
							<Select
								dropdownClassName={style.content_select}
								getPopupContainer={(triggerNode) => triggerNode.parentElement}
								className={style.select}
								options={monitorList}
								onChange={(value) => {
									changeMonitor(value);
								}}
								fieldNames={{
									label: "monitorName",
									value: "monitorInfoId",
								}}
							/>
						</Form.Item>
					</Form>
				)}
			</div>

			{monitorList?.length === 0 ? (
				<Nodata
					description={
						<span>
							您尚未创建事件监测方案，您可以通过
							<span
								onClick={jumpCreateEvent}
								style={{ color: "#DB5633", fontWeight: 700, cursor: "pointer" }}
							>
								{" "}
								新建事件{" "}
							</span>
							创建事件监测对象
						</span>
					}
				/>
			) : eventContextData?.periodDiagram?.articleTotal === 0 ? (
				<Nodata description="此事件暂无分析数据，您可以切换其他事件" />
			) : (
				<Row style={{ height: 600 }}>
					<Col span={10} className={style.content_left}>
						<div className={style.content_left_header}>
							<Tooltip placement="top" title="本图表展示事件整体发展脉络">
								<div className={style.two_title} style={{ marginRight: 10, width: 180 }}>
									事件发展脉络分析
								</div>
							</Tooltip>
							<div className={style.content_left_header_time}>
								<InfoCircleOutlined style={{ marginRight: 5 }} />
								更新时间：{moment(new Date()).format("yyyy-MM-DD")}
							</div>
						</div>

						{isLoading ? (
							<Loading />
						) : eventContextData?.dataList.length === 0 ? (
							<Nodata />
						) : (
							<div>
								<div className={style.content_left_title}>{eventContextData?.reportText}</div>
								<div className={style.content_left_box}>
									<div className={style.content_left_box_line}></div>
									<div className={style.content_left_box_quan}></div>
									{eventContextData?.dataList?.map((item, index) => {
										return (
											<div className={style.content_left_box_detail} key={index}>
												<div className={style.content_left_box_detail_quan}>
													{index === eventContextData?.dataList.length - 1 && (
														<div className={style.content_left_box_jian}>
															<CaretDownFilled />
														</div>
													)}
													{index === 0 && (
														<div className={style.content_left_box_detail_quan_small1}>
															最早
														</div>
													)}
													{index !== 0 && (
														<div className={style.content_left_box_detail_quan_small}>
															节点
														</div>
													)}
												</div>
												<div className={style.content_left_box_detail_card}>
													<div className={style.content_left_box_detail_card_time}>
														{item?.articleInfo?.pub_date_time}
														<span style={{ marginLeft: 10 }}>
															{item?.articleInfo?.content_length}字
														</span>
													</div>
													<div
														className={style.content_left_box_detail_card_title}
														onClick={() => {
															window.open(item?.articleInfo?.url);
														}}
													>
														{item?.articleInfo?.title}
													</div>
													<div className={style.content_left_box_detail_card_attitude}>
														{item?.articleInfo?.full_text_sentiment === "0" && (
															<span className={style.sentimentZx}>
																<Tag>中性</Tag>
															</span>
															// <span className="iconfont icon-zhongxing" style={{width:400,height:22}}></span>
														)}
														{item?.articleInfo?.full_text_sentiment === "1" && (
															<span className={style.sentimentZc}>
																<Tag>赞成</Tag>
															</span>

															// <span className="iconfont icon-zancheng"></span>
														)}
														{item?.articleInfo?.full_text_sentiment === "-1" && (
															<span className={style.sentimentP}>
																<Tag>批评</Tag>
															</span>
														)}
														{item?.articleInfo?.doc_type_id === "1" && (
															<Tag color="#6196E2">原创</Tag>
														)}
														{item?.articleInfo?.doc_type_id === "7" && <Tag>评论</Tag>}
														{item?.articleInfo?.doc_type_id === "2" && <Tag>转发</Tag>}
														{/* 媒体 作者 */}
														<span>
															<span>{item?.articleInfo?.media_name}</span>
															{item?.articleInfo?.media_name !==
																item?.articleInfo?.repost_source && (
																<span>
																	<span style={{ margin: 6 }}>|</span>
																	{item?.articleInfo?.repost_source}
																</span>
															)}
															{item?.articleInfo?.repost_source !==
																item?.articleInfo?.author_name &&
																item?.articleInfo?.author_name && (
																	<span>
																		<span style={{ margin: 6 }}>|</span>
																		{item?.articleInfo?.author_name}
																	</span>
																)}
														</span>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</Col>

					<Col span={14}>
						<div className={style.content_right_box}>
							{isLoading ? (
								<Loading />
							) : (
								<div>
									<Tooltip placement="top" title="本图表展示事件发展生命周期媒体传播声量趋势">
										<div className={style.two_title} style={{ width: 146 }}>
											媒体声量趋势
										</div>
									</Tooltip>

									<div className={style.content_right_top}>
										{eventContextData?.periodDiagram?.lineData === null ? (
											<Nodata />
										) : (
											<VolumeLinegram
												data={eventContextData?.periodDiagram?.lineData}
												xaxis={eventContextData?.periodDiagram?.dayList}
												datashiduan={eventContextData?.periodDiagram?.periodData}
												flagData={eventContextData?.periodDiagram?.flagData}
												yaxis={[]}
												onClick={chartsHandler}
											/>
										)}
									</div>
									<div className={style.content_right_bottom}>
										<Tooltip placement="top" title="本图表展示事件发展生命周期关键词演变过程">
											<div className={style.content_right_bottom_title} style={{ width: 156 }}>
												关键词演变过程
											</div>
										</Tooltip>

										{eventContextData?.periodKeywordList.length === 0 ? (
											<Nodata />
										) : (
											<div style={{ height: 230 }}>
												<BasicKeyWords
													data={eventContextData?.periodKeywordList}
													xaxis={eventContextData?.periodDiagram?.dayList}
													datashiduan={eventContextData?.periodDiagram?.periodData}
													onClick={chartsHandler}
												/>
											</div>
										)}
									</div>
								</div>
							)}
						</div>
					</Col>
				</Row>
			)}
		</div>
	);
}
